import {
  Input, Button, Form, Row, Col, Spin,
} from 'antd';

import React, {
  useCallback, useEffect, useState,
} from 'react';

import { useHistory } from 'react-router';
import axios from 'axios';
import { PanelShadow } from '../components/PanelShadow';
import { ResponseLoginType } from '../../../common/types';
import { API } from '../constsAPI';
import { useAlertMessage } from '../hooks/useAlertMessage';
import { TitleLoginStyle } from '../style_component/TitleLoginStyle';
import { Logo } from '../components/Logo';

export const ResetPasswordPage: React.FC = () => {
  const history = useHistory();
  const [msgError, setMsgError] = useState('');
  const [msgOk, setMsgOk] = useState('');
  const [keyConfirmPassword, setKeyConfirmPassword] = useState(false);
  const [spin, setSpin] = useState(false);

  const clearToken = useCallback(() => {
    localStorage.removeItem('IdToken');
    localStorage.removeItem('RefreshToken');
    localStorage.removeItem('AccessToken');
  }, []);

  useEffect(() => {
    clearToken();
  }, []);

  const onFinishResetPassword = useCallback(async (values: any) => {
    setMsgError('');
    setMsgOk('');

    setSpin(true);

    let dataRequest;
    let path;
    console.log('Success:', values);
    if (keyConfirmPassword) {
      dataRequest = {
        Username: values.Username,
        ConfirmationCode: values.ConfirmationCode,
        Password: values.NewPassword,
      };

      path = '/confirm_reset_password';
    } else {
      dataRequest = {
        Username: values.Username,
      };

      path = '/reset_password';
    }

    const result = await axios.post<ResponseLoginType>(`${API.URL_LOGIN}${path}`, JSON.stringify(dataRequest));

    const { data } = result;

    if (data.status === API.STATUS_200) {
      setSpin(false);

      if (keyConfirmPassword) history.push('/', 'reset_password');

      if (data.message) {
        setKeyConfirmPassword(true);
        setMsgOk(data.message);
      }
    } else {
      setSpin(false);
      setMsgError(data.message);
    }
  }, [keyConfirmPassword]);

  const onFinishFailed = useCallback((errorInfo: any) => {
    console.log('Failed:', errorInfo);
  }, []);

  const renderMessageError = useAlertMessage('error', msgError);
  const renderMessageOk = useAlertMessage('success', msgOk);

  return (
    <PanelShadow>
        <Logo margin={'0 0 30px 0'} type={'full'} />
        <Spin spinning={spin} tip={'please wait ...'}>
            <Form
                name="resetPassword"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                onFinish={onFinishResetPassword}
                onFinishFailed={onFinishFailed}
                autoComplete="nope"
            >
                {
                    keyConfirmPassword
                      ? <TitleLoginStyle level={5}>Please enter code and new password</TitleLoginStyle>
                      : <TitleLoginStyle level={5}>Please enter your email</TitleLoginStyle>
                }
                <Form.Item
                    label="E-mail"
                    name={'Username'}
                    rules={[
                      { required: true, message: 'Please input your E-mail!' },
                      {
                        type: 'email',
                        message: 'The input is not valid E-mail!',
                      },
                    ]}
                >
                    <Input />
                </Form.Item>
                {
                    keyConfirmPassword
                      ? <>
                            <Form.Item
                                label="Verification code"
                                name={'ConfirmationCode'}
                                rules={[
                                  { required: true, message: 'Please input a verification code!' },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="New Password"
                                name={'NewPassword'}
                                rules={[
                                  { required: true, message: 'Please input your a new Password!' },
                                ]}
                            >
                                <Input.Password />
                            </Form.Item>
                        </>
                      : null
                }
                {[
                  renderMessageError,
                  renderMessageOk,
                ]}
                <Row>
                    <Col span={14} style={{ padding: 3 }}>
                        <a onClick={() => { history.push('/login', '/reset_new_password'); }}>Sign in</a>
                    </Col>
                    <Col span={10} style={{ textAlign: 'right' }}>
                        <Button type="primary" htmlType="submit">
                            {
                                keyConfirmPassword
                                  ? 'Save password'
                                  : 'Reset password'
                            }
                        </Button>
                    </Col>
                </Row>
            </Form>
        </Spin>
    </PanelShadow>
  );
};

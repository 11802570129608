import * as React from 'react';
import styled from 'styled-components';
import { MenuItem, MenuSystem } from './MenuSystem';
import { HeaderPage } from './HeaderPage';
import { getMenu } from '../dataSubMenu';
import { SpaceWrapperStyle } from '../style_component/SpaceWrapper';

type SubPageTemplateProps = {
  subItemsMenu: MenuItem[];
  path: string;
  parentPath: string;
};

const DivWrapper = styled.div`
  margin: 0 0 0 2px;
`;

export const SubPageTemplate: React.FC<SubPageTemplateProps> = (props) => (
    <SpaceWrapperStyle direction="vertical">
      <HeaderPage />
      <MenuSystem item={getMenu()} {...props} />
      <DivWrapper>
        {props.children}
      </DivWrapper>
    </SpaceWrapperStyle>
);

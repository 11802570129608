import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import {
  Button, Col, Form, Row, Space, Spin, Typography,
  DatePicker, Tabs,
} from 'antd';
import { Translate } from 'i18n-next-tools';
import axios from 'axios';

import { useDispatch, useSelector } from 'react-redux';
import { FloorplanSvgEditor } from '../components/svg/FloorplanSvgEditor';
import {
  FloorplanType, FootprintDeviceType, LocationType, RouteScheduleFootprintType, RouteScheduleType, RouteType,
} from '../../../common/entityTypes';
import { API } from '../constsAPI';
import { AnswerVisitScheduleType } from '../../../common/types';
import { SimpleSelect } from '../components/SimpleSelect';
import { FootprintList } from '../components/FootprintList';
import { PdfDocument } from '../components/PdfDocument';
import { addSpinnerItem, removeSpinnerItem } from '../store/slices/spinnerSlice';
import { AppDispatch, RootState } from '../store';
// import { useDataCommon } from '../DataCommonProvider';

const { Title } = Typography;
const { TabPane } = Tabs;

export const DIR_NAME_UPLOAD = 'floorplan';

type FloorplanFootprintType = {
  [key: string]: {
    floorplan: FloorplanType[],
    footprint: FootprintDeviceType[]
  }
};

const getDataFootprint = async (location_id: string) => {
  if (!location_id) return [];

  const dataDB = (await axios.get<FootprintDeviceType[]>(`${API.URL_AURORA_DB}/footprint_list?location=${location_id}`)).data;

  return (dataDB).map((elem) => {
    const modifyElem = elem;
    modifyElem.key = elem.footprint_id;
    modifyElem.data_marker = elem.data_marker;
    return modifyElem;
  });
};

const getDataRoute = async () => (await axios.get<RouteType[]>(`${API.URL_AURORA_DB}/route_list`)).data;
const getFloorplan = async (location_id: string) => (await axios.get<FloorplanType[]>(`${API.URL_AURORA_DB}/floorplan_list?location=${location_id}`)).data;

export const SchedulePage: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [form] = Form.useForm();
  const [spin, setSpin] = useState(false);
  const [listLocation, setListLocation] = useState<LocationType[]>([]);
  const [listRoute, setListRoute] = useState<RouteType[]>([]);
  const [listRouteSchedule, setListRouteSchedule] = useState<RouteScheduleType[]>([]);
  const [currentRoute, setCurrentRoute] = useState<RouteType>();
  const [currentRouteSchedule, setCurrentRouteSchedule] = useState<RouteScheduleType>();
  const [currentDateSchedule, setCurrentDateSchedule] = useState<string>();
  const [listFloorplanFootprint, setListFloorplanFootprint] = useState<FloorplanFootprintType>({});
  const [listFootprint, setListFootprint] = useState<FootprintDeviceType[]>([]);
  //  const dataCommon = useDataCommon();
  const [listVisitSchedule, setListVisitSchedule] = useState<(AnswerVisitScheduleType & RouteScheduleFootprintType)[]>([]);
  const [changedData, setChangedData] = useState<boolean>(true);
  const [currentLocation, setCurrentLocation] = useState<LocationType>();
  const spinner = useSelector<RootState, boolean>((store) => store.spinner.spinner);
  const controlCheck1 = useRef<boolean>(false);

  useEffect(() => {
    (async () => {
      if (currentLocation && currentLocation.location_id && !listFloorplanFootprint[currentLocation.location_id]) {
        controlCheck1.current = true;
        dispatch(addSpinnerItem('schedule'));
        const floorplan = (await getFloorplan(currentLocation.location_id as any as string)).sort((elem1, elem2) => (elem1.name > elem2.name ? 1 : -1));

        const footprint = await getDataFootprint(currentLocation.location_id as any as string);

        setListFloorplanFootprint((prevListFloorplanFootprint) => {
          const newListFloorplanFootprint = prevListFloorplanFootprint;
          newListFloorplanFootprint[currentLocation.location_id!] = {
            floorplan,
            footprint,
          };
          return newListFloorplanFootprint;
        });

        const footprintList = listFootprint.concat(footprint);

        const sortListFootprint = footprintList.sort((elem1, elem2) => (elem1.partner_footprint_id! > elem2.partner_footprint_id! ? 1 : -1));
        setListFootprint(sortListFootprint);
        controlCheck1.current = false;
        dispatch(removeSpinnerItem('schedule'));
      }
    })();
  }, [currentLocation]);

  useEffect(() => {
    console.log('changed listLocation');
    setCurrentLocation(listLocation[0]);
  }, [listLocation]);

  useEffect(() => {
    (async () => {
      if (currentRoute && currentRouteSchedule) {
        dispatch(addSpinnerItem('schedule'));

        console.log('get schedule after route schedule changed');
        const visitScheduleData = (await axios.get<(AnswerVisitScheduleType & RouteScheduleFootprintType)[]>(`${API.URL_REST_API}/schedule/${currentRoute.route_name}/${currentRouteSchedule.schedule_datetime}`)).data;
        setListVisitSchedule(visitScheduleData.map((elem) => ({ ...elem, ...elem.raw_data_visit as AnswerVisitScheduleType })));

        dispatch(removeSpinnerItem('schedule'));
      } else {
        setListVisitSchedule([]);
      }
    })();
  }, [currentRouteSchedule]);

  const updateRouteSchedule = useCallback(async () => {
    if (currentRoute) {
      const newListRouteSchedule = (await axios.get<RouteScheduleType[]>(`${API.URL_AURORA_DB}/route_schedule_list?route=${currentRoute.route_id}`)).data;
      setListRouteSchedule(newListRouteSchedule);
      return newListRouteSchedule;
    }
    return [];
  }, [currentRoute]);

  useEffect(() => {
    if (!listRouteSchedule.length) {
      setCurrentRouteSchedule(undefined);
    }
  }, [listRouteSchedule]);

  useEffect(() => {
    (async () => {
      if (!currentRoute) {
        setListLocation([]);
        setListRouteSchedule([]);
        return;
      }

      dispatch(addSpinnerItem('schedule'));
      setListLocation((await axios.get<LocationType[]>(`${API.URL_AURORA_DB}/location_list?route=${currentRoute.route_id}`)).data);
      await updateRouteSchedule();

      if (!controlCheck1.current) {
        dispatch(removeSpinnerItem('schedule'));
      }
    })();
  }, [currentRoute]);

  useEffect(() => {
    (async () => {
      dispatch(addSpinnerItem('schedule'));
      setListRoute(await getDataRoute());
      dispatch(removeSpinnerItem('schedule'));
    })();
  }, []);

  const t = Translate('SchedulePage');

  const clickDeleteSchedule = useCallback(async () => {
    dispatch(addSpinnerItem('schedule'));
    await axios.delete<(AnswerVisitScheduleType & RouteScheduleFootprintType)[]>(`${API.URL_REST_API}/schedule/${currentRoute!.route_name}/${currentRouteSchedule!.schedule_datetime}`);
    await updateRouteSchedule();
    setCurrentRouteSchedule(undefined);
    dispatch(removeSpinnerItem('schedule'));
  }, [currentRoute, currentRouteSchedule]);

  const clickAddSchedule = useCallback(async () => {
    dispatch(addSpinnerItem('schedule'));
    if (currentDateSchedule && currentRoute) {
      console.log('get schedule after route click add');
      const visitScheduleData = (await axios.get<(AnswerVisitScheduleType & RouteScheduleFootprintType)[]>(`${API.URL_REST_API}/schedule/${currentRoute.route_name}/${currentDateSchedule}`)).data;
      const newListRouteSchedule = await updateRouteSchedule();

      setListVisitSchedule(visitScheduleData);
      setCurrentRouteSchedule(newListRouteSchedule.filter((elem) => elem.schedule_datetime === currentDateSchedule)[0]);
    } else {
      setListVisitSchedule([]);
    }
    dispatch(removeSpinnerItem('schedule'));
  }, [currentDateSchedule, currentRoute]);

  const changeDateVisitSchedule = useCallback(async (_, dateString: string) => {
    setCurrentDateSchedule(dateString);
  }, []);

  const onClickSaveRouteScheduleFootprint = useCallback(async () => {
    dispatch(addSpinnerItem('schedule'));
    if (currentRoute && currentRouteSchedule) {
      const visitScheduleData = (await axios.post<(AnswerVisitScheduleType & RouteScheduleFootprintType)[]>(`${API.URL_REST_API}/schedule/${currentRoute.route_name}/${currentRouteSchedule.schedule_datetime}`, listVisitSchedule)).data;
      console.log(visitScheduleData);
    }
    dispatch(removeSpinnerItem('schedule'));
  }, [listVisitSchedule, currentRoute, currentRouteSchedule]);

  const buildTabsLocation = useCallback((elem: LocationType, index: number) => <TabPane tab={`${elem.location_name}`}
                                                                                        key={index}>
    <Row gutter={16}>
      <Col className="gutter-row" span={4}>
        <FootprintList
          listFootprint={listFootprint}
          listVisitSchedule={listVisitSchedule}
          setListVisitSchedule={setListVisitSchedule}
          heightArea={'500px'}
          notSelectedFootprint={true}
          allowCheckedFootprint={true}
          onClickItem={() => {
            setChangedData(false);
          }}
        />
        <Row gutter={16} style={{ marginTop: '15px' }}>
          <Col className="gutter-row" span={24} style={{
            display: 'flex', flexDirection: 'column', justifyContent: 'space-between', gap: '10px',
          }}>
            <div>
              <Button
                type="primary"
                disabled={changedData}
                onClick={onClickSaveRouteScheduleFootprint}
                style={{ width: '100%' }}
              >
                Save
              </Button>
            </div>
            <PdfDocument setSpin={setSpin}
                          date={currentRouteSchedule ? currentRouteSchedule.schedule_datetime : ''}
                         listFloorplan={currentLocation?.location_id ? listFloorplanFootprint[currentLocation.location_id].floorplan : []}
                         listFootprint={listFootprint.filter((elem2) => listVisitSchedule.filter((subElem) => subElem.doService && subElem.visitLineId === elem2.partner_footprint_id).length)}/>
          </Col>
        </Row>
      </Col>
      <Col className="gutter-row" span={20}>
        {
          listFloorplanFootprint && listFloorplanFootprint[elem.location_id!]
            ? listFloorplanFootprint[elem.location_id!].floorplan
              .map((subElem: FloorplanType, indexFloorplan: number) => <div key={indexFloorplan}
                                                                            style={{
                                                                              display: 'block',
                                                                              border: '1px solid #d9d9d9',
                                                                            }}>
                <div style={{ backgroundColor: '#d9d9d9', padding: '2px 15px' }}>
                  <label style={{ fontWeight: 'bold' }}>{subElem.name}</label>
                </div>
                <FloorplanSvgEditor
                  linkUrl={`${process.env.REACT_APP_S3_STORAGE_URL}/${DIR_NAME_UPLOAD}/${subElem.picture_blob_name}`}
                  nameFloorplan={subElem.name}
                  listFootprint={listFloorplanFootprint[elem.location_id!].footprint.filter((fp) => fp.floorplan_id === subElem.floorplan_id)}
                  setKeyDisabledSave={() => {
                  }}
                  followChosenFootprint={false}
                  highlightFootprint={false}
                  isMoveMarkers={false}
                  listVisitSchedule={listVisitSchedule}
                /></div>)
            : 'Finding floorplan ...'
        }
      </Col>
    </Row>
  </TabPane>, [setSpin, listFootprint, listVisitSchedule, listFloorplanFootprint]);

  const onChangeTabLocation = (activeKey: string) => {
    setCurrentLocation(listLocation[Number(activeKey)]);
    console.log(listLocation[Number(activeKey)], currentLocation);
  };

  return (
    <>
      <Space direction={'horizontal'} align={'center'} style={{ justifyContent: 'space-between', width: '100%' }}>
        <Title level={3}>{t.k1('Schedule')}</Title>
      </Space>
      <Spin spinning={spin} tip={'please wait ...'}>
      <Spin spinning={spinner} tip={'please wait ...'}>
        <Form
          form={form}
          layout={'vertical'}
          name="addFloorplanItem"
          initialValues={{ floor_number: 1, compass: 0, scale: 1 }}
          autoComplete="nope"
        >
          <Row gutter={16}>
            <Col className="gutter-row" span={12}>
              <div>
              </div>
            </Col>
          </Row>
          <Row gutter={16}>
            <div className="schedule-select__wrapper">
              <div className="schedule-select__section">
                <div className="schedule-select">
                  <label>Route: </label>
                  <SimpleSelect
                    list={listRoute}
                    setCallback={setCurrentRoute}
                    filedId={'route_id'}
                    filedCaption={'route_name'}
                    placeholder={'Select a route'}
                    currentValue={currentRoute ? currentRoute.route_id as unknown as string : undefined}
                  />
                </div>
                <div className="schedule-select">
                  <label>Route Schedule: </label>
                  <SimpleSelect
                    list={listRouteSchedule}
                    setCallback={setCurrentRouteSchedule}
                    filedId={'route_schedule_id'}
                    filedCaption={'schedule_datetime'}
                    placeholder={'Select a route schedule'}
                    currentValue={currentRouteSchedule ? currentRouteSchedule.route_schedule_id as unknown as string : undefined}
                  />
                </div>
                <Button type="primary"
                        onClick={clickDeleteSchedule}
                        disabled={!currentRouteSchedule}
                >
                  Delete
                </Button>
              </div>
              <div className="schedule-select__section">
                <div className="schedule-select schedule-select--row">
                  <div className="schedule-select">
                    <label>Generate schedule: </label>
                    <DatePicker onChange={changeDateVisitSchedule}/>
                  </div>
                  <Button
                    type="primary"
                    onClick={clickAddSchedule}
                    disabled={!(currentDateSchedule && currentRoute)}
                  >
                    Go
                  </Button>
                </div>
              </div>
            </div>
          </Row>
          <Row gutter={16}>
            <Col className="gutter-row" span={24}>
              <div>
                <Tabs defaultActiveKey="1" onChange={onChangeTabLocation}>
                  {
                    listLocation.map(buildTabsLocation)
                  }
                </Tabs>
              </div>
            </Col>
          </Row>
        </Form>
      </Spin>
      </Spin>
    </>
  );
};

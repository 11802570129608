import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { ERequestRoute, ERequestUrl } from '../config';
import { RegionType } from '../../../../common/entityTypes';

export interface IRegionState {
  regionList: RegionType[]
  regionListByOperator: RegionType[]
}

const initialState: IRegionState = {
  regionList: [],
  regionListByOperator: [],
};

export const fetchRegions = createAsyncThunk('regionList/fetch', async () => {
  const response = await axios.get<RegionType[]>(`${ERequestUrl.BASE_CRUD}/${ERequestRoute.REGION}`);
  return response.data;
});

export const fetchRegionsByOperators = createAsyncThunk('regionListByOperators/fetch', async (operators: string) => {
  const response = await axios.get<RegionType[]>(`${ERequestUrl.BASE_CRUD}/${ERequestRoute.REGION}?${operators}`);
  return response.data;
});

export const regionSlice = createSlice({
  name: 'region',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchRegions.fulfilled, (state, action: PayloadAction<RegionType[]>) => {
      state.regionList = action.payload;
    });
    builder.addCase(fetchRegionsByOperators.fulfilled, (state, action: PayloadAction<RegionType[]>) => {
      state.regionListByOperator = action.payload;
    });
  },
});

// Action creators are generated for each case reducer function
// export const {} = counterSlice.actions;

export default regionSlice.reducer;

import {
  Button, DatePicker, Form, Modal, Spin,
} from 'antd';
import React from 'react';

export const ScheduleModal = (props: any) => {
  const [form] = Form.useForm();
  const {
    spinner,
    isRouteScheduleModalVisible,
    handleCancelRouteScheduleModal,
    handleCreateRouteSchedule,
    handleScheduleDateTime,
  } = props;

  return (
    <Modal
      title="Add schedule"
      visible={isRouteScheduleModalVisible}
      onCancel={handleCancelRouteScheduleModal}
      footer={[
        <Button key="cancel" onClick={handleCancelRouteScheduleModal}>
          Cancel
        </Button>,
        <Button form="addSchedule" key="submit" type="primary" htmlType="submit">
          Save
        </Button>,
      ]}>
      <Spin spinning={spinner} tip={'please wait ...'}>
        <Form
          form={form}
          name="addSchedule"
          layout="vertical"
          onFinish={handleCreateRouteSchedule}
          autoComplete="nope"
        >
          <Form.Item
            label="Select schedule datetime"
            name={'schedule_datetime'}
            rules={[
              { required: true, message: 'Please input route schedule datetime!' },
              {
                type: 'date',
                message: 'The input is not valid schedule datetime!',
              },
            ]}
            initialValue={form.getFieldValue('schedule_datetime')}
          >
            <DatePicker onChange={handleScheduleDateTime}/>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

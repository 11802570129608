export class API {
  public static STATUS_200 = 200;

  public static STATUS_302 = 302;

  public static STATUS_400 = 400;

  public static TEXT_STATUS_NAE = 'NotAuthorizedException';

  public static BASE_URL = process.env.REACT_APP_BASE_URL!;

  public static URL_LOGIN = process.env.REACT_APP_URL_LOGIN!;

  public static URL_AURORA_DB = process.env.REACT_APP_URL_AURORA_DB!;

  public static URL_USER = process.env.REACT_APP_URL_USER!;

  public static URL_REST_API = process.env.REACT_APP_URL_REST_API!;

  public static API_KEY = process.env.REACT_APP_API_KEY!;
}

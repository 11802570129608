import React, { useCallback, useEffect, useState } from 'react';
import {
  Space, Spin,
  Table, Typography,
} from 'antd';
import { Translate } from 'i18n-next-tools';
import { DateTime } from 'luxon';
import { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Signal } from '../components/Signal';
import { DEFAULT_VALUE, PAGINATION_TABLE } from '../consts';
import { TableSearch } from '../components/TableSearch';
import { AppDispatch, RootState } from '../store';
import { fetchLocationFootprint } from '../store/slices/locationSlice';
import { LocationFootprintDeviceType } from '../../../common/entityTypes';
import { handlePageSize } from '../common';
import { FiltersLocationListType } from '../../../common/types';
import { Battery } from '../components/Battery';

const { Title } = Typography;

const PERCENT_THRESHOLD_DEPTH_FOR_SERVICE = 80;
const BATTERY_THRESHOLD = 3.15;
const HOURS_CONTACT_THRESHOLD = 48;

const getColumns = (paramsColumn: { columnsSearch: { [key: string]: object | undefined } }): ColumnsType<LocationFootprintDeviceType> => [
  {
    title: 'Customer',
    dataIndex: 'customer_name',
    key: 'customer_name',
    sorter: (a: any, b: any) => (a.customer_name > b.customer_name ? 1 : -1),
    ...paramsColumn.columnsSearch.columnSearchCustomer,
  },
  {
    title: 'City',
    dataIndex: 'city',
    key: 'city',
    sorter: (a: any, b: any) => (a.city > b.city ? 1 : -1),
    ...paramsColumn.columnsSearch.columnSearchCity,
  },
  {
    title: 'State',
    dataIndex: 'state_province',
    key: 'state_province',
    sorter: (a: any, b: any) => (a.state_province > b.state_province ? 1 : -1),
    ...paramsColumn.columnsSearch.columnSearchState,
  },
  {
    title: 'Address',
    dataIndex: 'location_name',
    key: 'location_name',
    sorter: (a: any, b: any) => (a.location_name > b.location_name ? 1 : -1),
    render: (value: string, row: any) => `${value} ${row.address1 || ''} ${row.address2 || ''}`,
    ...paramsColumn.columnsSearch.columnSearchAddress,
  },
  {
    title: 'Footprint',
    dataIndex: 'partner_footprint_id',
    key: 'partner_footprint_id',
    sorter: (a: any, b: any) => (a.partner_footprint_id > b.partner_footprint_id ? 1 : -1),
    ...paramsColumn.columnsSearch.columnSearchPlace,
    render: (partner_footprint_id: number, row: LocationFootprintDeviceType) => {
      const params = [
        `footprint=${row.footprint_id}`,
        'rowlimit=100',
      ];
      const link = `/locations/detail?${params.join('&')}`;
      return <Link className="table__link" to={link}>{partner_footprint_id}</Link>;
    },
  },
  {
    title: 'Fill %',
    dataIndex: 'fill_percent',
    key: 'fill_percent',
    sorter: (a: any, b: any) => (+a.fill_percent > +b.fill_percent ? 1 : -1),
    render: (value: number) => ((value >= 0) ? `${Math.round(value)}%` : DEFAULT_VALUE),
  },
  {
    title: 'Days Since Service',
    dataIndex: 'latest_service_date',
    key: 'last_service_date_2',
    sorter: (a: any, b: any) => (
      Math.round(DateTime.now().diff(DateTime.fromISO(a.latest_service_date), 'days').days)
      > Math.round(DateTime.now().diff(DateTime.fromISO(b.latest_service_date), 'days').days) ? 1 : -1),
    render: (value: string) => (value ? `${Math.round(DateTime.now().diff(DateTime.fromISO(value), 'days').days)}` : DEFAULT_VALUE),
  },
  {
    title: 'Signal',
    dataIndex: 'signal_rsrp',
    key: 'signal_rsrp',
    render: (value: number) => (value ? <Signal rssi={value}/> : DEFAULT_VALUE),
  },
  {
    title: 'Battery',
    dataIndex: 'battery',
    key: 'battery',
    render: (value: number) => (value ? <Battery value={value}/> : DEFAULT_VALUE),
  },
  {
    title: 'Days since contact',
    dataIndex: 'hour_diff',
    key: 'hour_diff',
  },
  {
    title: 'Problem',
    dataIndex: 'hour_diff',
    key: 'hour_diff',
    render: (value: number, row) => {
      const problems = [];
      if (row.fill_percent > PERCENT_THRESHOLD_DEPTH_FOR_SERVICE) {
        problems.push('critical fill percent');
      }
      if (row.battery < BATTERY_THRESHOLD) {
        problems.push('battery low');
      }
      if (row.hour_diff > HOURS_CONTACT_THRESHOLD) {
        problems.push(`out of touch for ${row.hour_diff}`);
      }
      return problems.map((problem) => <><span>{problem}</span><br></br></>);
    },
  },
];

type AlertPageProps = {
  filter?: FiltersLocationListType
};

const getFilters = (filter: FiltersLocationListType | undefined) => {
  if (!filter) {
    return '';
  }
  return Object.keys(filter).map((key) => `${key}=${(filter as any)[key]}`).join('&');
};

export const AlertPage: React.FC<AlertPageProps> = (props) => {
  const { filter } = props;

  const [paginationTable, setPaginationTable] = useState<TablePaginationConfig>({
    ...PAGINATION_TABLE,
    pageSize: handlePageSize({ place: 'locations_footprints' }),
  });
  const [columnSearchFootprint, setColumnSearchFootprint] = useState<object>();
  const [columnSearchCustomer, setColumnSearchCustomer] = useState<object>();
  const [columnSearchAddress, setColumnSearchAddress] = useState<object>();
  const [columnSearchCity, setColumnSearchCity] = useState<object>();
  const [columnSearchState, setColumnSearchState] = useState<object>();

  // const history = useHistory();

  const columns: ColumnsType<LocationFootprintDeviceType> = getColumns({
    columnsSearch: {
      columnSearchPlace: columnSearchFootprint,
      columnSearchCustomer,
      columnSearchAddress,
      columnSearchCity,
      columnSearchState,
    },
  });

  const dispatch = useDispatch<AppDispatch>();
  const locationFootprintList = useSelector<RootState, LocationFootprintDeviceType[]>((store) => store.location.locationFootprintList);
  const locationLoader = useSelector<RootState, boolean>((store) => store.location.locationLoader);

  useEffect(() => {
    console.log('filter', filter);
    (async () => {
      await dispatch(fetchLocationFootprint(getFilters(filter)));
    })();
  }, [filter]);

  const handleTableChange = useCallback((pagination) => {
    handlePageSize({ place: 'locations_footprints', pageSize: pagination.pageSize });
    setPaginationTable(pagination);
  }, []);

  const getColumnSearchProps = useCallback((callback: (dataIndex: any) => {}) => {
    setColumnSearchFootprint(callback('partner_footprint_id'));
    setColumnSearchCustomer(callback('customer_name'));
    setColumnSearchAddress(callback('location_name'));
    setColumnSearchCity(callback('city'));
    setColumnSearchState(callback('state_province'));
  }, []);

  const t = Translate('LocationListPage');

  const getTitle = useCallback(() => {
    let text = 'COMBINED';

    if (!filter?.need_service || !filter?.battery || !filter?.no_contact) {
      if (filter?.need_service) {
        text = 'NEED SERVICE';
      }
      if (filter?.battery) {
        text = 'BATTERY';
      }
      if (filter?.no_contact) {
        text = 'NO CONTACT';
      }
    }
    return `${t.k1('Alerts')} - ${text}`;
  }, [filter]);

  return (
    <>
      <TableSearch columnSearchProps={getColumnSearchProps}/>
      <Space direction={'horizontal'} align={'center'} style={{ justifyContent: 'space-between', width: '100%' }}>
        <Title level={3}>{getTitle()}</Title>
      </Space>
      <Spin spinning={locationLoader} tip={'please wait ...'}>
        <Table
          columns={columns}
          className={'click_row'}
          dataSource={locationFootprintList}
          pagination={paginationTable}
          onChange={handleTableChange}
        />
      </Spin>
    </>
  );
};

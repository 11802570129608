import { Spin } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { OperatorType, RegionType } from '../../../../../common/entityTypes';

export const columns = (operatorList: OperatorType[]): ColumnsType<RegionType> => [
  {
    title: 'Region name',
    dataIndex: 'region_name',
    key: 'region_name',
    sorter: (a: any, b: any) => a.region_name.localeCompare(b.region_name),
  },
  {
    title: 'Operator id',
    dataIndex: 'operator_id',
    key: 'operator_id',
    render: (value: number) => {
      if (operatorList.length) {
        return operatorList.find((operator) => operator.operator_id === value)!.operator_name;
      }

      return <Spin />;
    },
  },
];

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { ERequestRoute, ERequestUrl } from '../config';
import { FootprintType } from '../../../../common/entityTypes';

export interface IFootprintState{
  footprintList: FootprintType[];
}

const initialState: IFootprintState = {
  footprintList: [],
};

export const fetchFootprintList = createAsyncThunk('footprintList/fetch', async (operatorId: number) => {
  const response = await axios.get(`${ERequestUrl.BASE}/${ERequestRoute.FOOTPRINT_LIST_BY_OPERATOR}/${operatorId}`);
  return response.data;
});

export const footprintSlice = createSlice({
  name: 'footprint',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchFootprintList.fulfilled, (state, action: PayloadAction<FootprintType[]>) => {
      state.footprintList = action.payload.map((footprint) => ({ ...footprint, key: footprint.footprint_id }));
    });
  },
});

// Action creators are generated for each case reducer function
// export const {} = counterSlice.actions;

export default footprintSlice.reducer;

import { Space, Table, Typography } from 'antd';
import React, {
  FC, useCallback, useEffect, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TablePaginationConfig } from 'antd/es/table';
import { Translate } from 'i18n-next-tools';
import { columns } from '../config';
import { AppDispatch, RootState } from '../../../store';
import { OperatorType, RegionType } from '../../../../../common/entityTypes';
import { PAGINATION_TABLE } from '../../../consts';
import { fetchRegions } from '../../../store/slices/regionSlice';
import { fetchOperators } from '../../../store/slices/operatorSlice';

const { Title } = Typography;
const t = Translate('RegionsPage');

export const RegionsPage: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const regionList = useSelector<RootState, RegionType[]>((store) => store.region.regionList);
  const operatorList = useSelector<RootState, OperatorType[]>((store) => store.operator.operatorList);
  const [paginationTable, setPaginationTable] = useState<TablePaginationConfig>(PAGINATION_TABLE);
  const handleTableChange = useCallback((pagination) => {
    setPaginationTable(pagination);
  }, []);

  useEffect(() => {
    if (!regionList.length) dispatch(fetchRegions());
    if (!operatorList.length) dispatch(fetchOperators());
  }, []);

  return (
      <>
        <Space direction={'horizontal'} align={'center'} style={{ justifyContent: 'space-between', width: '100%' }}>
          <Title level={3}>{t.k1('Regions')}</Title>
        </Space>
        <Table
            columns={columns(operatorList)}
            className={'click_row'}
            dataSource={regionList}
            pagination={paginationTable}
            onChange={handleTableChange}
        />
      </>
  );
};

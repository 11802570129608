import {
  Input, Button, Form, Row, Col, Spin,
} from 'antd';

import React, {
  useCallback, useEffect, useState,
} from 'react';
import axios from 'axios';

import { useHistory } from 'react-router';
import { AuthenticationResultType } from '@aws-sdk/client-cognito-identity-provider';
import { API } from '../constsAPI';
import { PanelShadow } from '../components/PanelShadow';
import { useDataCommon } from '../DataCommonProvider';
import { ResponseLoginType } from '../../../common/types';
import { useAlertMessage } from '../hooks/useAlertMessage';
import { TitleLoginStyle } from '../style_component/TitleLoginStyle';
import { Logo } from '../components/Logo';

export const SetNewPasswordPage: React.FC = () => {
  const dataCommon = useDataCommon();
  const [msgError, setMsgError] = useState('');
  const [spin, setSpin] = useState(false);
  const history = useHistory();

  const clearToken = useCallback(() => {
    localStorage.removeItem('IdToken');
    localStorage.removeItem('RefreshToken');
    localStorage.removeItem('AccessToken');
  }, []);

  useEffect(() => {
    clearToken();
  }, []);

  const setToken = useCallback((authResult: AuthenticationResultType) => {
    localStorage.setItem('IdToken', authResult.IdToken || '');
    localStorage.setItem('RefreshToken', authResult.RefreshToken || '');
    localStorage.setItem('AccessToken', authResult.AccessToken || '');
  }, []);

  const onFinishSetNewPassword = useCallback(async (values: any) => {
    setSpin(true);

    const resultChangePassword = await axios.post<ResponseLoginType>(`${API.URL_LOGIN}/set_new_password`, JSON.stringify({
      username: dataCommon.username,
      newPassword: values.password,
      session: localStorage.getItem('session'),
    }));

    const { authResult } = resultChangePassword.data!;

    if (resultChangePassword.data.status === API.STATUS_200) {
      localStorage.removeItem('session');
      setToken(authResult!);
      history.push('/login', '/set_new_password');
    } else {
      setSpin(false);
      setMsgError(resultChangePassword.data.message);
    }
  }, []);

  const onFinishFailed = useCallback((errorInfo: any) => {
    console.log('Failed:', errorInfo);
  }, []);

  const renderMessageError = useAlertMessage('error', msgError);

  return (
    <PanelShadow>
        <Logo margin={'0 0 30px 0'} type={'full'} />
        <Spin spinning={spin} tip={'please wait ...'}>
            <Form
                name="setNewPassword"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                onFinish={onFinishSetNewPassword}
                onFinishFailed={onFinishFailed}
                autoComplete="nope"
            >
                <TitleLoginStyle level={5}>Please enter a new password</TitleLoginStyle>

                <Form.Item
                    label="New password"
                    name={'password'}
                    rules={[
                      { required: true, message: 'Please input your password!' },
                    ]}
                >
                    <Input.Password />
                </Form.Item>
                {
                    renderMessageError
                }
                <Row>
                    <Col span={14} style={{ padding: 3 }}>
                    </Col>
                    <Col span={10} style={{ textAlign: 'right' }}>
                        <Button type="primary" htmlType="submit">
                            Save password
                        </Button>
                    </Col>
                </Row>
            </Form>
        </Spin>
    </PanelShadow>
  );
};

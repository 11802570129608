export const paths = {
  SHIP_DEVICE: '/ship_device',
  MOVE_DEVICE: '/move_device',
  LOCATIONS: '/locations',
  ALERTS: '/alerts',
  LOCATION_DETAIL: '/locations/detail',
  CUSTOMERS: '/customers',
  CUSTOMER_DETAIL: '/customer/detail',
  CUSTOMER_ADD: '/customer/add',
  REGIONS: '/regions',
  WAREHOUSE: '/warehouse',
  DEVICES: '/devices',
  ROUTE_SCHEDULE: '/route_schedule',
  DEVICE_DETAIL: '/device/detail',
  TECH: '/tech',
  REPORTS_RAW: '/reports_raw',
  REPORTS: '/reports',
  FLOORPLAN: '/floorplan',
  SCHEDULE: '/schedule',
  SETUP: '/setup',
  USERS: '/users',
  LOGIN: '/login',
  SET_NEW_PASSWORD: '/set_new_password',
  RESET_PASSWORD: '/reset_password',
  CHANGE_PASSWORD: '/change_password',
  NOT_FOUND: '/404',
  MAIN: '/',
  COMBINE: '/combined',
  NEED_SERVICE: '/need_service',
  BATTERY: '/battery',
  NO_CONTACT: '/no_contact',
};

export const roles = {
  ADMIN: 'Admin',
  OPERATOR: 'Operator',
  CUSTOMER: 'Customer',
  DRIVER: 'Driver',
};

export const ROLE_ADMIN = 'Admin';
export const ROLE_OPERATOR = 'Operator';
export const ROLE_CUSTOMER = 'Customer';
export const ROLE_DRIVER = 'Driver';
export const PERMISSION_MANAGE_USER = 'Manage users';

export const permissionsList = [
  PERMISSION_MANAGE_USER,
];

export const publicPages = {
  login: paths.LOGIN,
  set_new_password: paths.SET_NEW_PASSWORD,
  reset_password: paths.RESET_PASSWORD,
  change_password: paths.CHANGE_PASSWORD,
  main: paths.MAIN,
  not_found: paths.NOT_FOUND,
};

export const operatorRoleAccessPages = {
  locations: paths.LOCATIONS,
  location_detail: paths.LOCATION_DETAIL,
  devices: paths.DEVICES,
  device_detail: paths.DEVICE_DETAIL,
};

export const manageUserPermissionAccessPages = {
  users: paths.USERS,
};

export const customerRoleAccessPages = {
  locations: paths.LOCATIONS,
  location_detail: paths.LOCATION_DETAIL,
};

import { useDispatch, useSelector } from 'react-redux';
import { CheckCircleFilled } from '@ant-design/icons';
import { NotificationPlacement } from 'antd/es/notification';
import React, {
  ReactNode, useCallback, useEffect, useState,
} from 'react';
import {
  Button, notification, Space, Spin, Table,
} from 'antd';
import { Translate } from 'i18n-next-tools';
import Title from 'antd/es/typography/Title';
import { TablePaginationConfig } from 'antd/es/table';
import { useJsApiLoader } from '@react-google-maps/api';
import {
  addLocation,
  fetchLocations, LocationModalTypeOption,
  setInitialLocation,
  setLocationModalType,
  updateLocation,
} from '../../../store/slices/locationSlice';
import { AppDispatch, RootState } from '../../../store';
import {
  CustomerType, CycleType, LocationType, RegionType, ScheduleType, WarehouseType,
} from '../../../../../common/entityTypes';
import { PAGINATION_TABLE } from '../../../consts';
import { getColumns } from '../config/tableConfig';
import { LocationFormModal } from '../components/LocationFormModal';
import { fetchRegions } from '../../../store/slices/regionSlice';
import { fetchCustomersByRegions } from '../../../store/slices/customerSlice';
import { fetchSchedule } from '../../../store/slices/scheduleSlice';
import { fetchCycle } from '../../../store/slices/cycleSlice';
import { fetchWarehouses } from '../../../store/slices/warehouseSlice';
import { customersQuery } from '../../../common';
import { addSpinnerItem, removeSpinnerItem } from '../../../store/slices/spinnerSlice';

export const LocationPage = () => {
  const t = Translate('LocationsPage');
  const dispatch = useDispatch<AppDispatch>();
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: 'AIzaSyBbpAbovI6ok3Ort4b897S8rbkykCUUQxE', // ,
  });
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const initialLocation = useSelector<RootState, LocationType>((store) => store.location.initialLocation);
  const locationList = useSelector<RootState, LocationType[]>((store) => store.location.locationList);
  const locationModalType = useSelector<RootState, string>((store) => store.location.locationModalType);
  const regionList = useSelector<RootState, RegionType[]>((store) => store.region.regionList);
  const customerList = useSelector<RootState, CustomerType[]>((store) => store.customer.customerList);
  const scheduleList = useSelector<RootState, ScheduleType[]>((store) => store.schedule.scheduleList);
  const cycleList = useSelector<RootState, CycleType[]>((store) => store.cycle.cycleList);
  const spinner = useSelector<RootState, boolean>((store) => store.spinner.spinner);
  const warehouseList = useSelector<RootState, WarehouseType[]>((store) => store.warehouse.warehouseList);
  const [paginationTable, setPaginationTable] = useState<TablePaginationConfig>(PAGINATION_TABLE);
  const handleTableChange = useCallback((pagination) => {
    setPaginationTable(pagination);
  }, []);
  const [api, contextHolder] = notification.useNotification();
  const openNotification = (placement: NotificationPlacement, message: string, desc: string, icon: ReactNode) => {
    api.info({
      message,
      description: desc,
      placement,
      icon,
    });
  };

  const getPosition = async (address: string) => {
    console.log('get position');
    let position = {
      lat: 0,
      lng: 0,
    };

    if (isLoaded) {
      const geocoder = await new google.maps.Geocoder();
      await geocoder.geocode({ address }, (results, status) => {
        if (status === 'OK' && results) {
          console.log('position results', results);
          position = {
            lat: +results[0]?.geometry.location.lat(),
            lng: +results[0]?.geometry.location.lng(),
          };
        }
      });
    }

    if (loadError) {
      console.log(loadError);
    }

    return position;
  };

  useEffect(() => {
    (async () => {
      dispatch(addSpinnerItem('location_page'));
      if (!locationList.length) await dispatch(fetchLocations());
      if (!warehouseList.length) await dispatch(fetchWarehouses());
      if (!regionList.length) await dispatch(fetchRegions());
      if (!scheduleList.length) await dispatch(fetchSchedule());
      if (!cycleList.length) await dispatch(fetchCycle());
      dispatch(removeSpinnerItem('location_page'));
    })();
  }, []);

  useEffect(() => {
    if (regionList.length && !customerList.length) {
      (async () => {
        dispatch(addSpinnerItem('location_page'));
        await dispatch(fetchCustomersByRegions(customersQuery(regionList).join('&')));
        dispatch(removeSpinnerItem('location_page'));
      })();
    }
  }, [regionList]);

  const toggleNewLocationModal = () => {
    setIsModalVisible(true);
    dispatch(setInitialLocation(initialLocation));
    dispatch(setLocationModalType(LocationModalTypeOption.ADD_NEW));
  };

  const onFinish = async (values: LocationType) => {
    dispatch(addSpinnerItem('location_page'));
    if (locationModalType === LocationModalTypeOption.ADD_NEW) {
      if (values.country && values.city && values.address1 && values.zip) {
        const position = await getPosition(`${values.address1}, ${values.city}, ${values.state_province} ${values.zip}, ${values.country}`);
        values = { ...values, longitude: position.lng, latitude: position.lat };
      }
      await dispatch(addLocation(values));
    } else {
      if (!initialLocation.longitude || !initialLocation.latitude || values.address1 !== initialLocation.address1) {
        const position = await getPosition(`${values.address1}, ${values.city}, ${values.state_province} ${values.zip}, ${values.country}`);
        values = { ...values, longitude: position.lng, latitude: position.lat };
      }
      await dispatch(updateLocation({ ...values, location_id: initialLocation.location_id }));
    }
    const successMsg = 'Location saved successfully';
    const successDesc = '';
    const successIcon = <CheckCircleFilled style={{ color: 'green' }}/>;
    // const errorMsg = 'Error saving location';
    // const errorDesc = 'The field was specified with an error';
    // const errorIcon = <CloseCircleFilled style={{ color: 'red' }}/>;
    setIsModalVisible(false);
    openNotification('topRight', successMsg, successDesc, successIcon);
    dispatch(removeSpinnerItem('location_page'));
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };

  const toggleEditLocationModal = (raw: LocationType) => {
    dispatch(setInitialLocation(raw));
    dispatch(setLocationModalType(LocationModalTypeOption.EDIT));
    setIsModalVisible(true);
  };

  return (
    <>
      {contextHolder}
        <Spin spinning={spinner} tip={'please wait ...'}>
          <Space
            direction={'horizontal'}
            align={'center'}
            style={{ justifyContent: 'space-between', width: '100%' }}
          >
            <Title level={3}>{t.k1('Locations')}</Title>
          </Space>
          <Button
            style={{ marginBottom: 20 }}
            type="primary"
            onClick={toggleNewLocationModal}
          >
            {t.k2('Add location')}
          </Button>
          <LocationFormModal
            title={locationModalType}
            data={initialLocation}
            closeModal={closeModal}
            isModalVisible={isModalVisible} onFinish={onFinish}
            regionList={regionList}
            customerList={customerList}
            scheduleList={scheduleList}
            cycleList={cycleList}
            warehouseList={warehouseList}
          />
          <Table
            columns={
              getColumns(
                toggleEditLocationModal,
                regionList,
                customerList,
                scheduleList,
                cycleList,
                warehouseList,
              )
            }
            scroll={{ x: 2000 }}
            className={'click_row'}
            dataSource={locationList}
            pagination={paginationTable}
            onChange={handleTableChange}
          />
        </Spin>
    </>
  );
};

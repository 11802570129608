import BatteryGauge from 'react-battery-gauge';
import { Tooltip } from 'antd';
import * as React from 'react';

export const Battery = ({ value = 0 }) => {
  const battery = value / 2;
  let percentage = 0;

  if (battery >= 1.7) percentage = 100;
  if (battery >= 1.6 && battery < 1.7) percentage = 75;
  if (battery >= 1.5 && battery < 1.6) percentage = 50;
  if (battery < 1.5) percentage = 25;

  return (
    <Tooltip title={value}>
      <BatteryGauge
        size={35}
        aspectRatio={0.40}
        maxValue={100}
        value={percentage}
        customization={{
          readingText: {
            lightContrastColor: 'transparent',
            darkContrastColor: 'transparent',
            lowBatteryColor: 'transparent',
            fontFamily: 'Helvetica',
            fontSize: 1,
            showPercentage: true,
          },
          batteryMeter: {
            fill: 'green',
            lowBatteryValue: 26,
            lowBatteryFill: 'red',
            noOfCells: 4, // more than 1, will create cell battery
          },
        }}
      />
    </Tooltip>
  );
};

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { ERequestRoute, ERequestUrl } from '../config';
import { ShipDetailType, ShipType } from '../../../../common/entityTypes';

export interface IShipState{
  shipList: ShipType[];
  shipDetailList: ShipDetailType[];
}

const initialState: IShipState = {
  shipList: [],
  shipDetailList: [],
};

export const fetchShips = createAsyncThunk('shipList/fetch', async () => {
  const response = await axios.get<ShipType[]>(`${ERequestUrl.BASE_CRUD}/${ERequestRoute.SHIP}`);
  return response.data;
});

export const fetchShipDetail = createAsyncThunk('shipDetailList/fetch', async () => {
  const response = await axios.get<ShipDetailType[]>(`${ERequestUrl.BASE_CRUD}/${ERequestRoute.SHIP_DETAIL}?eager=true`);
  return response.data;
});

export const shipSlice = createSlice({
  name: 'ship',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchShips.fulfilled, (state, action: PayloadAction<ShipType[]>) => {
      state.shipList = action.payload.map((ship) => ({ ...ship, key: ship.ship_id }));
    });
    builder.addCase(fetchShipDetail.fulfilled, (state, action: PayloadAction<ShipDetailType[]>) => {
      state.shipDetailList = action.payload;
    });
  },
});

// Action creators are generated for each case reducer function
// export const {} = counterSlice.actions;

export default shipSlice.reducer;

import {
  Space,
} from 'antd';
import * as React from 'react';
import { UserDropdownMenu } from './UserDropdownMenu';
import { Logo } from './Logo';
import { LanguageDropdownMenu } from './LanguageDropdownMenu';
import { SelectOperator } from './SelectOperator';

export const HeaderPage: React.FC = () => <Space direction={'horizontal'} align={'center'} style={{ justifyContent: 'space-between', width: '100%' }}>
        <Logo type={'full'} />
        <div>
            <SelectOperator />
            <LanguageDropdownMenu />
            <UserDropdownMenu />
        </div>
    </Space>;

import React, { FC, useCallback, useEffect } from 'react';
import { useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCustomer } from '../../../store/slices/customerSlice';
import { AppDispatch, RootState } from '../../../store';
import { CustomerType } from '../../../../../common/entityTypes';

export const CustomerPage: FC = () => {
  const useQuery = useCallback(() => new URLSearchParams(useLocation().search), []);
  const query = useQuery();
  const dispatch = useDispatch<AppDispatch>();
  const customer = useSelector<RootState, CustomerType>((store) => store.customer.customer);

  useEffect(() => {
    dispatch(fetchCustomer(query.get('customer') || ''));
  }, []);

  return (
    <>
      <p>Name - {customer.customer_name}</p>
      <p>Address 1 {customer.address1}</p>
      <p>Address 2 {customer.address2}</p>
      <p>City {customer.city}</p>
      <p>State Province {customer.state_province}</p>
      <p>Country {customer.country}</p>
      <p>Zip {customer.zip}</p>
    </>
  );
};

import { ColumnsType } from 'antd/es/table';
import React from 'react';
import { TableRowSelection } from 'antd/es/table/interface';
import {
  CustomerType,
  DeviceType,
  FootprintType,
  LocationType,
  WarehouseHistoryType,
  WarehouseType,
} from '../../../../../common/entityTypes';

export const getDeviceColumns = (warehouseList: WarehouseType[]): ColumnsType<DeviceType> => ([
  {
    title: 'Number',
    dataIndex: 'device_number',
  },
  {
    title: 'Footprint',
    dataIndex: 'footprints',
    render: (footprints) => {
      if (footprints?.length) {
        return footprints[0].container_footprint_name;
      }

      return '';
    },
  },
  {
    title: 'Warehouse',
    dataIndex: 'warehouses',
    render: (warehouses) => {
      const lastWarehouse = warehouses.find((warehouse: WarehouseHistoryType) => !warehouse.effective_to_datetime);

      if (lastWarehouse) {
        return warehouseList.find((warehouse) => warehouse.warehouse_id === lastWarehouse.warehouse_id)?.warehouse_name;
      }

      return '';
    },
  },
]);

export const getFootprintColumns = (
  deviceListByOperator: DeviceType[],
  locationList: LocationType[],
  customerList: CustomerType[],
): ColumnsType<FootprintType> => ([
  {
    title: 'Footprint name',
    dataIndex: 'partner_footprint_id',
  }, {
    title: 'Container footprint name',
    dataIndex: 'container_footprint_name',
  },
  {
    title: 'Device number',
    dataIndex: 'latest_device_id',
    render: (value) => {
      const deviceByFootprint = deviceListByOperator.find((device) => device.device_id === value);

      if (deviceByFootprint) return deviceByFootprint.device_number;

      return '';
    },
  },
  {
    title: 'Customer',
    dataIndex: 'customer_name',
    render: (value, raw) => {
      if (customerList.length && locationList.length) {
        const footprintLocation = locationList.find((location) => location.location_id === raw.location_id);
        if (footprintLocation) {
          const footprintCustomer = customerList.find((customer) => customer.customer_id === footprintLocation.customer_id);

          if (footprintCustomer) return footprintCustomer?.customer_name;
        }
      }

      return '';
    },
  },
  {
    title: 'Location',
    dataIndex: 'location_id',
    render: (value) => {
      const footprintLocation = locationList.find((location) => location.location_id === value);

      if (footprintLocation) return `${footprintLocation.address1} ${footprintLocation.state_province} ${footprintLocation.zip} ${footprintLocation.city}`;

      return '';
    },
  },
]);

export const deviceRowSelection = (selecteRowDeviceKeys: React.Key[], onSelectDeviceChange: (newSelectedRowKeys: React.Key[]) => void): TableRowSelection<DeviceType> => ({
  type: 'radio',
  selectedRowKeys: selecteRowDeviceKeys,
  onChange: onSelectDeviceChange,
});

export const footprintrRowSelection = (selectedRowFootprintKeys: React.Key[], onSelectFootprintChange: (newSelectedRowKeys: React.Key[]) => void): TableRowSelection<FootprintType> => ({
  type: 'radio',
  selectedRowKeys: selectedRowFootprintKeys,
  onChange: onSelectFootprintChange,
});

import * as React from 'react';
import {
  Document, Image, Page, StyleSheet, Text, usePDF, View,
} from '@react-pdf/renderer';
import {
  Button, Form, Input, message,
} from 'antd';
import {
  useCallback, useState, Dispatch, SetStateAction,
  useEffect, useRef,
} from 'react';
import * as htmlToImage from 'html-to-image';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { API } from '../constsAPI';
import { FloorplanType, FootprintDeviceType } from '../../../common/entityTypes';
import { uploadFileApi } from '../uploadFile';

type DataForPdfType = {
  linkSvg: string,
  nameFloorplan: string
};

type PdfDocumentProps = {
  setSpin: Dispatch<SetStateAction<any | undefined>>,
  listFootprint: FootprintDeviceType[],
  date: string,
  listFloorplan: FloorplanType[],
};

const styles = StyleSheet.create({
  page: {
    backgroundColor: 'white',
    color: 'black',
  },
  section: {
    margin: 10,
    padding: 10,
  },
  viewer: {
    width: window.innerWidth - 22 * 2, // the pdf viewer will take up all of the width and height
    height: window.innerHeight,
  },
  image: {
    width: '100%',
  },
});

const documentPdf = (listDataForPdf: DataForPdfType[]) => <Document>
  {/* render a single page */}
  {
    listDataForPdf.map((dataForPdf, subIndex) => <Page size="A4" orientation="landscape" style={styles.page}
                                                       key={subIndex}>
      <View style={styles.section}>
        <Text style={{ padding: 30, textAlign: 'center' }}>{dataForPdf.nameFloorplan}</Text>
        <Image key={subIndex}
               style={styles.image}
               src={dataForPdf.linkSvg}
        />
      </View>
    </Page>)
  }
</Document>;

export const PdfDocument: React.FC<PdfDocumentProps> = (props) => {
  const {
    date, listFootprint, setSpin, listFloorplan,
  } = props;

  console.log('listFootprint', listFootprint);
  const [listDataForPdf, setListDataForPdf] = useState<DataForPdfType[]>([]);
  const [doc, setDoc] = useState<React.ReactElement>(documentPdf(listDataForPdf));
  const [instance, updateInstance] = usePDF({ document: doc });
  const [clickSend, setClickSend] = useState<boolean>(false);
  const [clickDownoad, setClickDownload] = useState<boolean>(false);
  const [chanchedSvg, setChanchedSvg] = useState<boolean>(true);
  const [finishProcess, setFinishProcess] = useState<boolean>(false);
  const [keyS3, setKeyS3] = useState<string>();
  const refIinputEmail = useRef<Input>(null);

  const senEmail = useCallback(async (email: string): Promise<boolean> => {
    try {
      const result = (await axios.post(`${API.URL_AURORA_DB}/send_email`, JSON.stringify({
        from: process.env.REACT_APP_EMAIL_FROM,
        to: email,
        footprints: listFootprint,
        floorplans: listFloorplan,
        date,
        keyS3,
      }), {
        headers: { 'content-type': 'application/json' },
      }));
      console.log(result);
      return true;
    } catch (e) {
      console.log(e);
    }

    return false;
  }, [keyS3]);

  useEffect(() => {
    (async () => {
      if (finishProcess) {
        setFinishProcess(false);
        if (clickSend) {
          console.log('INSTANCE-SEND>>>>>>>>>>>>>>', instance);

          const email = refIinputEmail.current!.input.value;
          // plessels@cantaloupe.com
          if (await senEmail(email)) {
            message.success('Email successfully sent!');
          } else {
            message.error(`Email cannot be sending for "${email}"!`);
          }

          setClickSend(false);
        }

        if (clickDownoad) {
          console.log('INSTANCE-DOWNLOAD>>>>>>>>>>>>>>', instance);
          setClickDownload(false);
          const link = document.createElement('a');
          link.setAttribute('href', instance.url!);
          link.setAttribute('download', 'download');
          link.click();
        }
        setSpin(false);
      }
    })();
  }, [finishProcess]);

  useEffect(() => {
    (async () => {
      if (!instance.loading) {
        if (instance.blob) {
          const newFileName = `${uuidv4()}.pdf`;
          const dirName = 'email_pdf';
          const linkSave = `${process.env.REACT_APP_S3_STORAGE_URL}/${dirName}/${newFileName}`;

          if (await uploadFileApi(instance.blob, linkSave)) {
            setKeyS3(`${dirName}/${newFileName}`);
          }
        }
        setFinishProcess(true);
      }
    })();
  }, [instance]);

  useEffect(() => {
    if (listDataForPdf.length) {
      updateInstance();
    }
  }, [doc]);

  useEffect(() => {
    if (chanchedSvg) {
      setDoc(documentPdf(listDataForPdf));
    } else {
      setFinishProcess(true);
      setSpin(false);
    }
  }, [listDataForPdf]);

  const convertSvgToImage = useCallback(async () => {
    setSpin(true);
    const arraySvg = document.getElementsByClassName('svg_floorplan');
    if (!arraySvg.length) return;

    const newListDataForPdf: DataForPdfType[] = [];

    for (const svg of arraySvg) {
      const dataForPdf: DataForPdfType = {
        // eslint-disable-next-line no-await-in-loop
        linkSvg: await htmlToImage.toJpeg(svg as any, { quality: 0.5, backgroundColor: 'white' }),
        nameFloorplan: (svg.attributes as any).values.value,
      };
      newListDataForPdf.push(dataForPdf);
    }

    setChanchedSvg((listDataForPdf.length !== newListDataForPdf.length) || Boolean(listDataForPdf.filter((elem, index) => newListDataForPdf[index].linkSvg !== elem.linkSvg).length));
    setListDataForPdf(newListDataForPdf);
  }, [listDataForPdf]);

  useEffect(() => {
    if (clickSend || clickDownoad) convertSvgToImage();
  }, [clickSend, clickDownoad]);

  const onClickDownload = useCallback(async () => {
    setClickDownload(true);
  }, []);

  const onClickSendEmail = useCallback(async () => {
    setClickSend(true);
  }, []);

  return <>
    <div>
      <Button type="primary"
              onClick={onClickDownload}
              style={{ width: '100%' }}
      >Download</Button>
    </div>
    <div style={{ display: 'flex' }}>
      <Form.Item
          label=""
          name={'email'}
          rules={[
            {
              type: 'email',
              message: 'Email is not valid!',
            },
          ]}
          style={{ width: '70%' }}
      >
        <Input ref={refIinputEmail} autoComplete="nope" placeholder={'Email'} />
      </Form.Item>
      <Form.Item
          style={{ width: '30%' }}
      >
        <Button name={'btn_send'} type="primary"
                onClick={onClickSendEmail}
                style={{ width: '100%' }}
        >Send</Button>
      </Form.Item>
    </div>
  </>;
};

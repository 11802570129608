import React, { useContext, useState } from 'react';
import { OperatorType } from '../../common/entityTypes';
import { TransferType } from '../../common/typesEditor';

type DataCommon = {
  username: string,
  language: string,
  setLanguage: any,
  operator: OperatorType
  setOperator: any,
  listOperator: OperatorType[],
  listTransferOperator: TransferType[],
};

const dataStorage: DataCommon = {
  username: '',
  language: 'e',
  setLanguage: () => {},
  operator: {} as any,
  setOperator: () => {},
  listOperator: [],
  listTransferOperator: [],
};

const DataCommonContext = React.createContext(dataStorage);

export const useDataCommon = () => useContext(DataCommonContext);

export const DataCommonProvider: React.FC = ({ children }) => {
  const [dataCommon] = useState(dataStorage);

  return (
    <DataCommonContext.Provider value={dataCommon}>
        {children}
    </DataCommonContext.Provider>
  );
};

import {
  Input, Button, Form, Row, Col, Spin,
} from 'antd';

import React, { useCallback, useState } from 'react';
import axios from 'axios';

import { useHistory } from 'react-router';
import { ChangePasswordCommandInput } from '@aws-sdk/client-cognito-identity-provider';
import { API } from '../constsAPI';
import { ResponseLoginType } from '../../../common/types';
import { PanelShadow } from '../components/PanelShadow';
import { useAlertMessage } from '../hooks/useAlertMessage';
import { TitleLoginStyle } from '../style_component/TitleLoginStyle';

type ChangePasswordType = {
  oldPassword: string,
  newPassword: string,
  newSecondPassword: string,
};

export const ChangePasswordPage: React.FC = () => {
  const history = useHistory();
  const [msgError, setMsgError] = useState('');
  const [msgOk, setMsgOk] = useState('');
  const [spin, setSpin] = useState(false);

  const onFinishChangePassword = useCallback(async (values: ChangePasswordType) => {
    setMsgError('');
    setSpin(true);

    const AccessToken = localStorage.getItem('AccessToken') || '';

    const request: ChangePasswordCommandInput = {
      AccessToken,
      PreviousPassword: values.oldPassword,
      ProposedPassword: values.newPassword,
    };

    const resultChangePassword = await axios.post<ResponseLoginType>(`${API.URL_LOGIN}/change_password`, JSON.stringify(request));

    setSpin(false);
    if (resultChangePassword.data.status === API.STATUS_200) {
      setMsgOk(resultChangePassword.data.message);
    } else {
      setSpin(false);
      setMsgError(resultChangePassword.data.message);
    }
  }, []);

  const onFinishFailed = useCallback((errorInfo: any) => {
    console.log('Failed:', errorInfo);
  }, []);

  const renderMessageError = useAlertMessage('error', msgError);
  const renderMessageOk = useAlertMessage('success', msgOk);

  return (
    <>
        <PanelShadow width={'520px'}>
            <Spin spinning={spin} tip={'please wait ...'}>
                <TitleLoginStyle level={5} style={{ textAlign: 'center' }}>Please entry data for changing Password</TitleLoginStyle>
                <Form
                name="changePassword"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                onFinish={onFinishChangePassword}
                onFinishFailed={onFinishFailed}
                autoComplete="nope"
                >
                    <Form.Item
                        label="Old password"
                        name={'oldPassword'}
                        rules={[
                          { required: true, message: 'Please input your password!' },
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>
                    <Form.Item
                        label="New password"
                        name={'newPassword'}
                        rules={[
                          { required: true, message: 'Please input your password!' },
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>
                    <Form.Item
                        label="Repeat New password"
                        name={'newSecondPassword'}
                        rules={[
                          { required: true, message: 'Please input your password!' },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (!value || getFieldValue('newPassword') === value) {
                                return Promise.resolve();
                              }
                              return Promise.reject(new Error('The two passwords that you entered do not match!'));
                            },
                          }),
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>
                    {[
                      renderMessageError,
                      renderMessageOk,
                    ]}
                    <Row>
                        <Col span={12} style={{ padding: 3 }}>
                            <Button type="default" onClick={() => history.goBack()}>
                                Return back
                            </Button>
                        </Col>
                        <Col span={12} style={{ textAlign: 'right' }}>
                            <Button type="primary" htmlType="submit">
                                Change password
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        </PanelShadow>
    </>
  );
};

import styled from 'styled-components';

type PanelShadowType = {
  width?: string;
};

export const PanelShadow = styled.div<PanelShadowType>`
  position: absolute;
  top: calc(50% - 300px / 2);
  left: 50%;
  margin: -160px 0 0 calc(${(props) => props.width || '400px'} / 2 * -1);
  width: ${(props) => props.width || '400px'};
  padding: 36px 15px;
  box-shadow: 0 0 10px rgba(0,0,0,.08);
`;

import React, { useCallback, useEffect, useState } from 'react';
import {
  Select, Space, Spin, Table, Typography,
} from 'antd';
import axios from 'axios';
import { Translate } from 'i18n-next-tools';
import { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { DateTime } from 'luxon';
import ReactJson from 'react-json-view';
import { API } from '../constsAPI';
import { StorageDataEventType } from '../../../common/entityTypes';
import { DEFAULT_NOT_VALUE, DEFAULT_VALUE, PAGINATION_TABLE } from '../consts';
import { DataShadowUpdateType, ShadowDeviceType } from '../../../common/types';
import { handlePageSize } from '../common';

const { Title } = Typography;

const listRowsLimitReports = [100, 200, 500, 1000];

const getColumns: ColumnsType<StorageDataEventType> = [
  {
    title: 'Status record',
    dataIndex: 'type',
    key: 'type',
  },
  {
    title: 'Date record report',
    dataIndex: 'datetime',
    key: 'datetime',
    sorter: (a: any, b: any) => {
      const time1 = a.datetime ? DateTime.fromISO(a.datetime).toMillis() : 0;
      const time2 = b.datetime ? DateTime.fromISO(b.datetime).toMillis() : 0;
      return (time1 > time2 ? 1 : -1);
    },
    render: (value: string) => (value ? DateTime.fromISO(value).toFormat('y-LL-dd hh:mm:ss a') : DEFAULT_VALUE),
  },
  {
    title: 'IMEI',
    dataIndex: 'detail',
    key: 'imei',
    render: (value: DataShadowUpdateType | ShadowDeviceType) => {
      console.log('===>', value);
      if (!value) {
        return DEFAULT_VALUE;
      }

      if ((value as ShadowDeviceType).state) {
        return (value as ShadowDeviceType).state.reported.device ? (value as ShadowDeviceType).state.reported.device?.imei : DEFAULT_NOT_VALUE;
      } if ((value as DataShadowUpdateType).device) {
        return (value as DataShadowUpdateType).device ? (value as DataShadowUpdateType).device?.imei : DEFAULT_NOT_VALUE;
      }

      return DEFAULT_NOT_VALUE;
    },
  },
  {
    title: 'ICCID',
    dataIndex: 'detail',
    key: 'iccid',
    render: (value: DataShadowUpdateType | ShadowDeviceType) => {
      if (!value) {
        return DEFAULT_VALUE;
      }

      if ((value as ShadowDeviceType).state) {
        return (value as ShadowDeviceType).state.reported.dev ? (value as ShadowDeviceType).state.reported.dev.v.iccid : DEFAULT_NOT_VALUE;
      } if ((value as DataShadowUpdateType).dev) {
        return (value as DataShadowUpdateType).dev ? (value as DataShadowUpdateType).dev?.v.iccid : DEFAULT_NOT_VALUE;
      }

      return DEFAULT_NOT_VALUE;
    },
  },
  {
    title: 'Raw data from device',
    dataIndex: 'detail',
    key: 'detail',
    width: 700,
    render: (value: DataShadowUpdateType) => <ReactJson
      collapsed={1}
      indentWidth={2}
      src={value}
    /> || DEFAULT_VALUE,
  },
];

export const ReportRawListPage: React.FC = () => {
  const [loadingTable] = useState<boolean>(false);
  const [paginationTable, setPaginationTable] = useState<TablePaginationConfig>({
    ...PAGINATION_TABLE,
    pageSize: handlePageSize({ place: 'reports_raw' }),
  });
  const [currentRowLimitReports, setCurrentRowLimitReports] = useState<number>(listRowsLimitReports[0]);

  const columns: ColumnsType<StorageDataEventType> = getColumns;

  const [spin, setSpin] = useState(false);
  const [dataTable, setDataTable] = useState<(StorageDataEventType)[]>([]);

  const getDataDB = useCallback(async () => {
    const dataDB = (await axios.get<(StorageDataEventType & { key: number | undefined })[]>(`${API.URL_AURORA_DB}/report_raw_list?rowlimit=${currentRowLimitReports}`)).data;
    setDataTable((dataDB).map((elem) => {
      const modifyElem = elem;
      modifyElem.key = elem.storage_data_event_id;
      return modifyElem;
    }));
    setSpin(false);
  }, [currentRowLimitReports]);

  useEffect(() => {
    (async () => {
      setSpin(true);
      await getDataDB();
    })();
  }, [currentRowLimitReports]);

  const handleTableChange = useCallback((pagination) => {
    handlePageSize({ place: 'reports_raw', pageSize: pagination.pageSize });
    setPaginationTable(pagination);
  }, []);

  const changeCurrentCounterReports = useCallback((value: string) => {
    setCurrentRowLimitReports(Number(value));
  }, []);

  const t = Translate('DeviceRawListPage');

  return (
    <>
      <Space direction={'horizontal'} align={'center'} style={{ justifyContent: 'space-between', width: '100%' }}>
        <Title level={3}>{t.k1('Reports Raw List')}</Title>
        <Select
          defaultValue={`${currentRowLimitReports} reports`}
          style={{ width: 120 }}
          onChange={changeCurrentCounterReports}
          options={listRowsLimitReports.map((count) => ({
            value: count,
            label: `${count} reports`,
          }))}
        />
      </Space>
      <Spin spinning={spin} tip={'please wait ...'}>
        <Table<StorageDataEventType>
          columns={columns}
          className={'click_row'}
          dataSource={dataTable}
          pagination={paginationTable}
          onChange={handleTableChange}
          loading={loadingTable}
        />
      </Spin>
    </>
  );
};

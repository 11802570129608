import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { ERequestRoute, ERequestUrl } from '../config';
import { OperatorType } from '../../../../common/entityTypes';

export interface IOperatorState{
  operatorList: OperatorType[],
  currentOperator: OperatorType | null
  operator: OperatorType[]
}

const initialState: IOperatorState = {
  operatorList: [],
  currentOperator: null,
  operator: [],
};

export const fetchOperators = createAsyncThunk('operatorList/fetch', async () => {
  const response = await axios.get<OperatorType[]>(`${ERequestUrl.BASE_CRUD}/${ERequestRoute.OPERATOR}`);
  return response.data;
});

export const fetchOperatorById = createAsyncThunk('operator/fetch', async ({ operator_id }: { operator_id: number }) => {
  const response = await axios.get<OperatorType[]>(`${ERequestUrl.BASE_CRUD}/${ERequestRoute.OPERATOR}?operator_id=${operator_id}`);
  return response.data;
});

export const operatorSlice = createSlice({
  name: 'operator',
  initialState,
  reducers: {
    setSliceOperator: (state, action: PayloadAction<OperatorType>) => {
      state.currentOperator = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchOperators.fulfilled, (state, action: PayloadAction<OperatorType[]>) => {
      state.operatorList = action.payload;
    });
    builder.addCase(fetchOperatorById.fulfilled, (state, action: PayloadAction<OperatorType[]>) => {
      state.operator = action.payload;
    });
  },
});

// Action creators are generated for each case reducer function
export const { setSliceOperator } = operatorSlice.actions;

export default operatorSlice.reducer;

import { Translate } from 'i18n-next-tools';
import { MenuSubItem } from './components/MenuSystem';
// eslint-disable-next-line import/no-cycle
import {
  decodeToken, paths,
  PERMISSION_MANAGE_USER,
  roles,
} from './common';

const t = Translate('dataSubMenu');

type ItemDataSubMenu = {
  title: string,
  path: string
};

export const getCashItems = (): ItemDataSubMenu[] => [
  {
    title: t.k1('cash ticket list'),
    path: '/ticket_list',
  },
  {
    title: t.k2('cash ticket details'),
    path: '/ticket_details',
  },
  {
    title: t.k3('cash history'),
    path: '/history',
  },
  {
    title: t.k4('cash input'),
    path: '/input',
  },
  {
    title: t.k5('cash import'),
    path: '/import',
  },
  {
    title: t.k6('bill & coin counter'),
    path: '/bill_coin_counter',
  },
];

export const getSetupItems = (): ItemDataSubMenu[] => [
  {
    title: t.k30('Customers'),
    path: paths.CUSTOMERS,
  },
  {
    title: t.k33('Locations'),
    path: paths.LOCATIONS,
  },
  {
    title: t.k36('Floorplan'),
    path: paths.FLOORPLAN,
  },
  {
    title: t.k41('Warehouses'),
    path: paths.WAREHOUSE,
  },
  {
    title: t.k32('Regions'),
    path: paths.REGIONS,
  },
  {
    title: t.k29('Users'),
    path: paths.USERS,
  },
  {
    title: t.k40('Move Device'),
    path: paths.MOVE_DEVICE,
  },
  {
    title: t.k39('Ship Device'),
    path: paths.SHIP_DEVICE,
  },
];

export const getAlertItems = (): ItemDataSubMenu[] => [
  {
    title: t.k44('combined'),
    path: paths.COMBINE,
  },
  {
    title: t.k45('need service'),
    path: paths.NEED_SERVICE,
  },
  {
    title: t.k46('battery'),
    path: paths.BATTERY,
  },
  {
    title: t.k47('no contact'),
    path: paths.NO_CONTACT,
  },

  // {
  //   title: t.k14('dispatch'),
  //   path: '/dispatch',
  // },
  // {
  //   title: t.k15('new ticket'),
  //   path: '/new_ticket',
  // },
  // {
  //   title: t.k17('dex'),
  //   path: '/dex',
  // },
  // {
  //   title: t.k18('inventory'),
  //   path: '/inventory',
  // },
  // {
  //   title: t.k19('sales'),
  //   path: '/sales',
  // },
  // {
  //   title: t.k20('bill & coin'),
  //   path: '/bill_coin',
  // },
  // {
  //   title: t.k21('coil'),
  //   path: '/coil',
  // },
  // {
  //   title: t.k22('signal'),
  //   path: '/signal',
  // },
  // {
  //   title: t.k23('cashless'),
  //   path: '/cashless',
  // },
  // {
  //   title: t.k24('pricing'),
  //   path: '/pricing',
  // },
];

export const getTechItems = (): ItemDataSubMenu[] => [
  {
    title: t.k37('Reports'),
    path: paths.REPORTS,
  },
  {
    title: t.k38('Reports (RAW)'),
    path: paths.REPORTS_RAW,
  },
];

export const getScheduleItems = (): ItemDataSubMenu[] => [
  {
    title: t.k42('Route Schedule'),
    path: paths.ROUTE_SCHEDULE,
  },
  {
    title: t.k48('Single Schedule'),
    path: paths.SCHEDULE,
  },
];

export const getMenu = (): MenuSubItem[] => {
  const token = localStorage.getItem('IdToken') || '';
  const decodedToken = decodeToken(token);
  console.log(decodedToken);

  const customerMenu: MenuSubItem[] = [
    {
      title: t.k33('Locations'),
      items: [],
      path: paths.LOCATIONS,
    },
  ];

  const operatorMenu: MenuSubItem[] = [
    {
      title: t.k43('Alerts'),
      items: getAlertItems(),
      path: paths.ALERTS,
    },
    ...customerMenu,
    {
      title: t.k28('Devices'),
      items: [],
      path: paths.DEVICES,
    },
  ];

  const menu: MenuSubItem[] = [
    ...operatorMenu,
    {
      title: t.k35('Schedule'),
      items: getScheduleItems(),
      path: paths.SCHEDULE,
    },
    {
      title: t.k49('Tech'),
      items: getTechItems(),
      path: paths.TECH,
    },
    {
      title: t.k31('Setup'),
      items: getSetupItems(),
      path: paths.SETUP,
    },
  ];

  const driverMenu: MenuSubItem[] = [];

  const menusByRole = {
    [roles.ADMIN]: menu,
    [roles.OPERATOR]: operatorMenu,
    [roles.CUSTOMER]: customerMenu,
    [roles.DRIVER]: driverMenu,
  };

  if (!decodedToken) {
    return [];
  }

  const menuDefault = menusByRole[decodedToken['custom:Role']] || [];
  if (decodedToken['custom:Permissions']?.split(',').includes(PERMISSION_MANAGE_USER)) {
    menuDefault.push({
      title: t.k29('Users'),
      items: [],
      path: paths.USERS,
    });
  }

  return menuDefault;
};

import React, {
  useState, useRef, useEffect, MutableRefObject, useCallback,
} from 'react';
import { DataMarkerType, FootprintDeviceType, FootprintType } from '../../../../common/entityTypes';
import { PositionType } from '../../../../common/typesEditor';

type MarkerFootprintProps = {
  etalonScale: MutableRefObject<null>,
  footprint: FootprintDeviceType,
  defaultPosition: PositionType,
  selected?: boolean,
  highlight?: boolean,
  showForVisitSchedule: boolean,
  isMoveMarkers: boolean,
};

const colorView = '#084ad5';
const colorHide = 'white';
const colorSelected = '#2fd508';
const colorVisit = 'red';

const DEFAULT_LABEL_OFFSET = 30;
const fontSize = 30;

export const MarkerFootprintSvg: React.FC<MarkerFootprintProps> = (props) => {
  const getPosition = (footprint: FootprintType) => ({
    x: footprint.pixel_x || props.defaultPosition.x,
    y: footprint.pixel_y || props.defaultPosition.y,
  });

  const getOffsetLabel = (footprint: FootprintType) => {
    const offset = (footprint.data_marker && (footprint.data_marker as DataMarkerType).offset)
      ? (footprint.data_marker as DataMarkerType).offset!
      : { x: DEFAULT_LABEL_OFFSET, y: -DEFAULT_LABEL_OFFSET };

    return offset;
  };

  const [position, setPosition] = useState<PositionType>(getPosition(props.footprint));
  const [offsetLabel, setOffsetLabel] = useState<PositionType>(getOffsetLabel(props.footprint));
  const [widthText, setWidthText] = useState(1);

  const getScale = useCallback(() => {
    const newScale = props.etalonScale.current ? (props.etalonScale.current as any).getClientRects()[0].width / 2000 : 1;
    return newScale;
  }, []);

  const textRef = useRef(null);

  const handleMouseMove = useRef((e: any) => {
    const { footprint } = props;

    if (!footprint.data_marker) {
      footprint.data_marker = {};
    }

    const dataMarker = footprint.data_marker as DataMarkerType;

    if (e.ctrlKey) {
      setOffsetLabel((oldOffset: PositionType) => {
        const xDiff = oldOffset.coords ? oldOffset.coords.x - e.pageX : 0;
        const yDiff = oldOffset.coords ? oldOffset.coords.y - e.pageY : 0;
        const newX = oldOffset.x - xDiff / getScale();
        const newY = oldOffset.y - yDiff / getScale();

        if (!dataMarker.offset) {
          dataMarker.offset = { x: newX, y: newY };
        } else {
          dataMarker.offset!.x = newX;
          dataMarker.offset!.y = newY;
        }

        return {
          x: newX,
          y: newY,
          coords: {
            x: e.pageX,
            y: e.pageY,
          },
        };
      });
    } else {
      setPosition((oldPosition: PositionType) => {
        const xDiff = oldPosition.coords ? oldPosition.coords.x - e.pageX : 0;
        const yDiff = oldPosition.coords ? oldPosition.coords.y - e.pageY : 0;
        const newX = oldPosition.x - xDiff / getScale();
        const newY = oldPosition.y - yDiff / getScale();

        if (!dataMarker.position) {
          dataMarker.position = { x: newX, y: newY };
        } else {
          dataMarker.position!.x = newX;
          dataMarker.position!.y = newY;
        }

        footprint.pixel_x = newX;
        footprint.pixel_y = newY;

        return {
          x: newX,
          y: newY,
          coords: {
            x: e.pageX,
            y: e.pageY,
          },
        };
      });
    }
  });

  useEffect(() => {
    if (textRef.current) {
      setTimeout(() => {
        const clientTextRect = (textRef.current as any).getClientRects()[0];
        const newWidth = clientTextRect.width * (1 / getScale()) + 6 * 2;
        setWidthText(newWidth as any);
      }, 0);
    }
  }, []);

  const handleMouseDown = (e: any) => {
    const { pageX, pageY } = e;

    if (e.ctrlKey) {
      setOffsetLabel((_offsetLabel: any) => ({
        ..._offsetLabel,
        coords: {
          x: pageX,
          y: pageY,
        },
      }));
    } else {
      setPosition((_position: any) => ({
        ..._position,
        coords: {
          x: pageX,
          y: pageY,
        },
      }));
    }
    document.addEventListener('mousemove', handleMouseMove.current);
    e.stopPropagation();
  };

  const handleMouseUp = () => {
    document.removeEventListener('mousemove', handleMouseMove.current);
    setPosition((position1: any) => ({ ...position1, coords: {} }));
  };

  const getColor = (hideBorder?: boolean) => {
    const hideColor = props.showForVisitSchedule;

    if (hideColor !== undefined && !hideColor && hideBorder) {
      return colorHide;
    }

    const newColorView = !hideColor ? colorView : colorVisit;

    return props.selected && props.highlight ? colorSelected : newColorView;
  };

  const functionHandelMouseUp = props.isMoveMarkers ? handleMouseUp : undefined;
  const functionHandelMouseDown = props.isMoveMarkers ? handleMouseDown : undefined;
  const styleCursorPointer = props.isMoveMarkers ? { cursor: 'pointer' } : undefined;

  return (
      <g x={position.x} y={position.y}
         onMouseUp={functionHandelMouseUp}
      >
        <line x1={position.x} y1={position.y}
              x2={+position.x + offsetLabel.x} y2={+position.y + offsetLabel.y}
              stroke={getColor()} strokeWidth={2} />
        <line x1={position.x} y1={position.y}
              x2={+position.x + offsetLabel.x} y2={+position.y + offsetLabel.y}
              stroke={'white'} opacity={0.01} strokeWidth={10}
              style={styleCursorPointer}
              onMouseDown={functionHandelMouseDown}
              onMouseUp={functionHandelMouseUp}
        />

        <rect
              x={+position.x + offsetLabel.x - 2}
              y={+position.y + offsetLabel.y - fontSize}
              width={widthText! + 2}
              height={fontSize + 2}
              stroke={getColor(true)}
              fill={'white'} strokeWidth={4}
        />

        <text ref={textRef}
              x={+position.x + offsetLabel.x + 5}
              y={+position.y + offsetLabel.y - 3}
              fill={getColor()}
              fontWeight={'bold'}
              fontSize={fontSize}
              style={styleCursorPointer}
              onMouseDown={functionHandelMouseDown}
              onMouseUp={functionHandelMouseUp}
        >{props.footprint.partner_footprint_id}</text>
        <circle
            cx={position.x}
            cy={position.y}
            r={20}
            fill="white"
            opacity={0.01}
            onMouseUp={functionHandelMouseUp}
        />
        <circle
            cx={position.x}
            cy={position.y}
            r={5}
            fill={getColor()}
            stroke={getColor()}
            strokeWidth="1"
            style={styleCursorPointer}
            onMouseDown={functionHandelMouseDown}
            onMouseUp={functionHandelMouseUp}
        />
      </g>
  );
};

const setValue = (key: string, value: string) => {
  localStorage.setItem(key, value);
};

const getValue = (key: string) => localStorage.getItem(key) || 50;

export const handlePageSize = (prop: { place: string, pageSize?: number }) => {
  const { place, pageSize } = prop;

  if (!pageSize) return +getValue(place);

  setValue(place, pageSize.toString());
  return +getValue(place);
};

import React, { useCallback, useEffect, useState } from 'react';
import {
  Button, Form, Input, Modal, Select, Spin, Table,
} from 'antd';
import { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { useDispatch, useSelector } from 'react-redux';
import { useJsApiLoader } from '@react-google-maps/api';
import Title from 'antd/es/typography/Title';
import { AppDispatch, RootState } from '../../../store';
import { addWarehouse, fetchWarehouses } from '../../../store/slices/warehouseSlice';
import { OperatorType, WarehouseType } from '../../../../../common/entityTypes';
import { fetchOperators } from '../../../store/slices/operatorSlice';
import { PAGINATION_TABLE } from '../../../consts';
import { handlePageSize } from '../../../common';
import { addSpinnerItem, removeSpinnerItem } from '../../../store/slices/spinnerSlice';

const { Option } = Select;

export const Warehouse = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch<AppDispatch>();
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: 'AIzaSyBbpAbovI6ok3Ort4b897S8rbkykCUUQxE', // ,
  });
  const [paginationTable, setPaginationTable] = useState<TablePaginationConfig>({
    ...PAGINATION_TABLE,
    pageSize: handlePageSize({ place: 'devices' }),
  });
  const [initialWarehouse] = useState<WarehouseType>({
    warehouse_id: null!,
    operator_id: null!,
    warehouse_name: '',
    address: '',
    city: '',
    state_province: '',
    zip: '',
    country: '',
    longitude: null!,
    latitude: null!,
  });
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const warehouseList = useSelector<RootState, WarehouseType[]>((store) => store.warehouse.warehouseList);
  const operatorList = useSelector<RootState, OperatorType[]>((store) => store.operator.operatorList);
  const spinner = useSelector<RootState, boolean>((store) => store.spinner.spinner);

  const getPosition = async (address: string) => {
    console.log('get position');
    let position = {
      lat: 0,
      lng: 0,
    };

    if (isLoaded) {
      const geocoder = await new google.maps.Geocoder();
      await geocoder.geocode({ address }, (results, status) => {
        if (status === 'OK' && results) {
          console.log('position results', results);
          position = {
            lat: +results[0]?.geometry.location.lat(),
            lng: +results[0]?.geometry.location.lng(),
          };
        }
      });
    }

    if (loadError) {
      console.log(loadError);
    }

    return position;
  };

  useEffect(() => {
    (async () => {
      dispatch(addSpinnerItem('warehouse'));
      if (!operatorList.length) await dispatch(fetchOperators());
      if (!warehouseList.length) await dispatch(fetchWarehouses());
      dispatch(removeSpinnerItem('warehouse'));
    })();
  }, []);

  const handleTableChange = useCallback((pagination) => {
    setPaginationTable(pagination);
    handlePageSize({ place: 'devices', pageSize: pagination.pageSize });
  }, []);

  const columns: ColumnsType<WarehouseType> = [
    {
      title: 'Warehouse name',
      dataIndex: 'warehouse_name',
    },
    {
      title: 'Operator name',
      dataIndex: 'operator_id',
      render: (value) => {
        if (operatorList.length) {
          return operatorList.find((operator) => operator.operator_id === value)?.operator_name!;
        }
        return <Spin/>;
      },
    },
    {
      title: 'City',
      dataIndex: 'city',
    },
    {
      title: 'Address',
      dataIndex: 'address',
    },
    {
      title: 'State',
      dataIndex: 'state_province',
    },
    {
      title: 'Zip',
      dataIndex: 'zip',
    },
  ];

  const handleAddNew = () => {
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };

  const onFinish = async (values: WarehouseType) => {
    dispatch(addSpinnerItem('warehouse'));
    if (values.country && values.city && values.address && values.zip) {
      const position = await getPosition(`${values.address}, ${values.city}, ${values.state_province} ${values.zip}, ${values.country}`);
      values = { ...values, longitude: position.lng, latitude: position.lat };
    }
    await dispatch(addWarehouse(values));
    setIsModalVisible(false);
    dispatch(removeSpinnerItem('warehouse'));
  };

  return (
    <>
      <Modal
        title="Add new warehouse" visible={isModalVisible}
        onCancel={closeModal}
        footer={[
          <Button key="cancel" onClick={closeModal}>
            Cancel
          </Button>,
          <Button form="addLocation" key="submit" type="primary" htmlType="submit">
            Save
          </Button>,
        ]}>
        <Form
          form={form}
          name="addLocation"
          layout="vertical"
          onFinish={onFinish}
          autoComplete="nope"
        >
          <Form.Item
            label="Operator"
            name={'operator_id'}
            rules={[
              { required: true, message: 'Please select operator!' },
            ]}
            initialValue={initialWarehouse.operator_id}
          >
            <Select
              allowClear={true}
              placeholder={'Select operator'}
              value={initialWarehouse.operator_id}
            >
              {operatorList.map((operator) => (
                <Option
                  key={operator.operator_id}
                  value={operator.operator_id!}
                >
                  {operator.operator_name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Name"
            name={'warehouse_name'}
            rules={[
              { required: true, message: 'Please input warehouse name!' },
            ]}
            initialValue={initialWarehouse.warehouse_name}
          >
            <Input/>
          </Form.Item>
          <Form.Item
            label="Address"
            name={'address'}
            initialValue={initialWarehouse.address}
          >
            <Input/>
          </Form.Item>
          <Form.Item
            label="City"
            name={'city'}
            initialValue={initialWarehouse.city}
          >
            <Input/>
          </Form.Item>
          <Form.Item
            label="State / Province"
            name={'state_province'}
            initialValue={initialWarehouse.state_province}
          >
            <Input/>
          </Form.Item>
          <Form.Item
            label="Zip"
            name={'zip'}
            initialValue={initialWarehouse.zip}
          >
            <Input/>
          </Form.Item>
          <Form.Item
            label="Country"
            name={'country'}
            initialValue={initialWarehouse.country}
          >
            <Input/>
          </Form.Item>
        </Form>
      </Modal>
      <Title level={3}>Warehouses</Title>
      <Button onClick={handleAddNew}>Add warehouse</Button>
      <Spin spinning={spinner} tip={'please wait ...'}>
        <Table
          className={'click_row'}
          columns={columns}
          dataSource={warehouseList}
          pagination={paginationTable}
          onChange={handleTableChange}
        />
      </Spin>
    </>
  );
};

import React, {
  ChangeEvent, useCallback, useEffect, useState,
} from 'react';
import { TablePaginationConfig } from 'antd/es/table';
import { useDispatch, useSelector } from 'react-redux';
import { CloseOutlined } from '@ant-design/icons';
import {
  Button, DatePicker,
  Input, InputNumber, Select, Space, Spin, Table, Typography,
} from 'antd';
import { Moment } from 'moment';
import { Translate } from 'i18n-next-tools';
import { AppDispatch, RootState } from '../../../store';
import {
  DeviceType, OperatorType, ShipDetailType, ShipType, WarehouseType,
} from '../../../../../common/entityTypes';
import { fetchOperators } from '../../../store/slices/operatorSlice';
import {
  clearDeviceIssueMessage,
  fetchDeviceListByOperatorWithFootprints,
  moveDevicesBetweenOperators,
} from '../../../store/slices/deviceSlice';
import { columns, SHIP_DEVICE_MESSAGE, shipColumns } from '../config';
import { fetchWarehouses } from '../../../store/slices/warehouseSlice';
import { dateForDB, handlePageSize } from '../../../common';
import { fetchShipDetail, fetchShips } from '../../../store/slices/shipSlice';
import { addSpinnerItem, removeSpinnerItem } from '../../../store/slices/spinnerSlice';
import { PAGINATION_TABLE } from '../../../consts';

const { Option } = Select;
const { Text, Title } = Typography;
const t = Translate('ShipDevicesPage');

export const ShipDevicePage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [filteredTextArea, setFilteredTextArea] = useState<string>('');
  const [issueSign, setIssueSign] = useState<string>('');
  const [comment, setComment] = useState<string>('');
  const [deviceIssue, setDeviceIssue] = useState<string>('');
  const [operatorIssue, setOperatorIssue] = useState<string>('');
  const [warehouseIssue, setWarehouseIssue] = useState<string>('');
  const [operatorFrom, setOperatorFrom] = useState<number | undefined>(undefined);
  const [operatorTo, setOperatorTo] = useState<number | undefined>(undefined);
  const [selectedWarehouseTo, setSelectedWarehouseTo] = useState<number | undefined>(undefined);
  const [selectedWarehouseFrom, setSelectedWarehouseFrom] = useState<number | undefined>(undefined);
  const [brackets, setBrackets] = useState<{ bracketId: number, bracketCode: string, bracketQuantity: number }[]>([]);
  const [effectiveDate, setEffectiveDate] = useState<string>(dateForDB(new Date()));
  const [filteredDeviceList, setFilteredDeviceList] = useState<DeviceType[]>([]);
  const operatorList = useSelector<RootState, OperatorType[]>((store) => store.operator.operatorList);
  const deviceList = useSelector<RootState, DeviceType[]>((store) => store.device.deviceList);
  const shipDeviceResponse = useSelector<RootState, {
    message: string,
    error: boolean
  }>((store) => store.device.deviceIssueMessage);
  const warehouseList = useSelector<RootState, WarehouseType[]>((store) => store.warehouse.warehouseList);
  const shipList = useSelector<RootState, ShipType[]>((store) => store.ship.shipList);
  const shipDetailList = useSelector<RootState, ShipDetailType[]>((store) => store.ship.shipDetailList);
  const spinner = useSelector<RootState, boolean>((store) => store.spinner.spinner);

  const [paginationShipTable, setPaginationShipTable] = useState<TablePaginationConfig>({
    ...PAGINATION_TABLE,
    pageSize: handlePageSize({ place: 'ships' }),
  });

  const handleShipTableChange = useCallback((pagination) => {
    setPaginationShipTable(pagination);
    handlePageSize({ place: 'ships', pageSize: pagination.pageSize });
  }, []);

  useEffect(() => {
    (async () => {
      dispatch(addSpinnerItem('ship_device'));
      await dispatch(fetchOperators());
      await dispatch(fetchWarehouses());
      await dispatch(fetchShips());
      await dispatch(fetchShipDetail());
      dispatch(removeSpinnerItem('ship_device'));
    })();
  }, []);

  useEffect(() => {
    if (deviceIssue !== SHIP_DEVICE_MESSAGE.NO_ISSUES) setIssueSign(deviceIssue);
    if (operatorIssue !== SHIP_DEVICE_MESSAGE.NO_ISSUES) setIssueSign(operatorIssue);
    if (warehouseIssue !== SHIP_DEVICE_MESSAGE.NO_ISSUES) setIssueSign(warehouseIssue);
    if (deviceIssue === SHIP_DEVICE_MESSAGE.NO_ISSUES
      && operatorIssue === SHIP_DEVICE_MESSAGE.NO_ISSUES
      && warehouseIssue === SHIP_DEVICE_MESSAGE.NO_ISSUES
    ) {
      setIssueSign(SHIP_DEVICE_MESSAGE.NO_ISSUES);
    }
  }, [deviceIssue, operatorIssue, warehouseIssue]);

  useEffect(() => {
    if (typeof operatorFrom === 'number') {
      setOperatorIssue(SHIP_DEVICE_MESSAGE.NO_ISSUES);
      (async () => {
        dispatch(addSpinnerItem('ship_device'));
        await dispatch(fetchDeviceListByOperatorWithFootprints(operatorFrom));
        dispatch(removeSpinnerItem('ship_device'));
      })();
    }
  }, [operatorFrom]);

  useEffect(() => {
    if (typeof operatorTo === 'number') {
      setOperatorIssue(SHIP_DEVICE_MESSAGE.NO_ISSUES);
    }
  }, [operatorTo]);

  useEffect(() => {
    if (brackets.length) {
      const noIssue = brackets.every((bracket) => bracket.bracketQuantity > 0);

      if (!issueSign && noIssue) setIssueSign(SHIP_DEVICE_MESSAGE.NO_ISSUES);
    }
  }, [brackets]);

  const getFilteredWarehouseListByOperator = (operatorId: number) => warehouseList.filter((warehouse) => warehouse.operator_id === operatorId);

  const handleSelectOperatorFrom = (value: number) => {
    setFilteredTextArea('');
    setOperatorFrom(value);
    const filteredWarehouses = getFilteredWarehouseListByOperator(value);
    if (filteredWarehouses.length === 1) {
      setSelectedWarehouseFrom(filteredWarehouses[0].warehouse_id);
      setWarehouseIssue(SHIP_DEVICE_MESSAGE.NO_ISSUES);
    } else {
      setWarehouseIssue(SHIP_DEVICE_MESSAGE.SELECT_WAREHOUSE);
      setSelectedWarehouseFrom(undefined);
    }
    dispatch(clearDeviceIssueMessage());
  };

  const handleSelectOperatorTo = (value: number) => {
    setOperatorTo(value);
    const filteredWarehouses = getFilteredWarehouseListByOperator(value);
    if (filteredWarehouses.length === 1) {
      setSelectedWarehouseTo(filteredWarehouses[0].warehouse_id);
      setWarehouseIssue(SHIP_DEVICE_MESSAGE.NO_ISSUES);
    } else {
      setWarehouseIssue(SHIP_DEVICE_MESSAGE.SELECT_WAREHOUSE);
      setSelectedWarehouseTo(undefined);
    }
    dispatch(clearDeviceIssueMessage());
  };

  const handleSelectWarehouseTo = (value: number) => {
    setSelectedWarehouseTo(value);
    if (value) {
      setWarehouseIssue(SHIP_DEVICE_MESSAGE.NO_ISSUES);
    } else {
      setWarehouseIssue(SHIP_DEVICE_MESSAGE.SELECT_WAREHOUSE);
    }
  };

  const handleSelectWarehouseFrom = (value: number) => {
    setSelectedWarehouseFrom(value);
    if (value) {
      setWarehouseIssue(SHIP_DEVICE_MESSAGE.NO_ISSUES);
    } else {
      setWarehouseIssue(SHIP_DEVICE_MESSAGE.SELECT_WAREHOUSE);
    }
  };

  const handleOnClearSelectOperator = () => {
    // setOperatorIssue(SHIP_DEVICE_MESSAGE.SELECT_DIFFERENT_OPERATOR);
  };

  const handleOnClearSelectWarehouseTo = () => {
    setWarehouseIssue(SHIP_DEVICE_MESSAGE.SELECT_WAREHOUSE);
  };

  const handleOnClearSelectWarehouseFrom = () => {
    setWarehouseIssue(SHIP_DEVICE_MESSAGE.SELECT_WAREHOUSE);
  };

  const handleFilter = (event: ChangeEvent<HTMLTextAreaElement>) => {
    dispatch(clearDeviceIssueMessage());
    setFilteredTextArea(event.target.value);
    const splitValue = event.target.value.split('\n');
    const filtered: DeviceType[] = [];
    for (let i = 0; i < splitValue.length; i++) {
      if (!splitValue[i]) return;
      const foundDevice = deviceList.find((device) => device.device_number.toLowerCase() === splitValue[i].toLowerCase());
      if (!foundDevice) {
        filtered.push({
          device_number: splitValue[i],
          issue: SHIP_DEVICE_MESSAGE.IS_NOT_FROM_OPERATOR,
          isIssue: true,
          device_serial_number: '',
          operator_id: 0,
          is_dummy_test_device: false,
        });
        setDeviceIssue(SHIP_DEVICE_MESSAGE.RESOLVE_ISSUES_BEFORE_MOVE);
      } else if (foundDevice.footprints?.length) {
        filtered.push({
          ...foundDevice,
          issue: SHIP_DEVICE_MESSAGE.IS_ASSIGNED,
          isIssue: true,
        });
        setDeviceIssue(SHIP_DEVICE_MESSAGE.RESOLVE_ISSUES_BEFORE_MOVE);
      } else if (foundDevice.warehouses?.length
        && foundDevice.warehouses[foundDevice.warehouses.length - 1].warehouse_id !== selectedWarehouseFrom
      ) {
        filtered.push({
          ...foundDevice,
          issue: SHIP_DEVICE_MESSAGE.WRONG_WAREHOUSES,
          isIssue: true,
        });
        setDeviceIssue(SHIP_DEVICE_MESSAGE.RESOLVE_ISSUES_BEFORE_MOVE);
      } else {
        filtered.push(foundDevice);
        setDeviceIssue(SHIP_DEVICE_MESSAGE.NO_ISSUES);
      }
    }
    if (!operatorTo) setOperatorIssue(SHIP_DEVICE_MESSAGE.SELECT_OPERATOR_TO);
    if (!selectedWarehouseTo) setWarehouseIssue(SHIP_DEVICE_MESSAGE.SELECT_WAREHOUSE);
    setFilteredDeviceList(filtered);
  };

  const handleMove = () => {
    (async () => {
      dispatch(addSpinnerItem('ship_device'));
      await dispatch(moveDevicesBetweenOperators({
        devicesId: filteredDeviceList.map((device) => device.device_id!),
        brackets,
        operatorFrom: operatorFrom!,
        operatorTo: operatorTo!,
        warehouseId: selectedWarehouseTo!,
        effectiveDate: dateForDB(effectiveDate as any as Date),
        comment,
      }));
      setFilteredTextArea('');
      if (operatorFrom) {
        await dispatch(fetchDeviceListByOperatorWithFootprints(operatorFrom));
      }
      dispatch(removeSpinnerItem('ship_device'));
    })();
  };

  const onChangeEffectiveDate = (_: Moment | null, dateString: string) => {
    setEffectiveDate(dateString);
  };

  const handleComment = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setComment(event.target.value);
  };

  const handleBracketsQuantity = (value: number, bracketId: number) => {
    setBrackets((prevState) => prevState.map((item) => {
      if (item.bracketId === bracketId) {
        return {
          ...item,
          bracketQuantity: value,
        };
      }

      return item;
    }));
  };

  const handleBracketsCode = (value: string, bracketId: number) => {
    if (value) {
      setBrackets((prevState) => prevState.map((item) => {
        if (item.bracketId === bracketId) {
          return {
            ...item,
            bracketCode: value,
          };
        }

        return item;
      }));
    }
  };

  const handleAddBrackets = () => {
    setBrackets((prevState) => [...prevState, {
      bracketId: new Date().getTime(),
      bracketCode: 'BIN-MOUNT',
      bracketQuantity: 0,
    }]);
  };

  const handleClearBrackets = () => {
    setBrackets([]);
    setIssueSign('');
  };

  const handleDeleteBracket = (bracketId: number) => {
    setBrackets((prevState) => prevState.filter((item) => {
      if (item.bracketId !== bracketId) return item;

      return false;
    }));
  };

  return (
      <>
      <Space direction={'horizontal'} align={'center'} style={{ justifyContent: 'space-between', width: '100%' }}>
        <Title level={3}>{t.k1('Ship Devices')}</Title>
      </Space>
    <Spin spinning={spinner} tip={'please wait ...'}>
      <div className="ship-device">
        <div className="ship-device__part">
          <Text>Select operator from</Text>
          <Select
            className="ship-device__input"
            onChange={handleSelectOperatorFrom}
            allowClear={true}
            placeholder={'Select operator from'}
            value={operatorFrom}
            onClear={handleOnClearSelectOperator}
          >
            {operatorList.map((operator) => (
              <Option
                key={operator.operator_id}
                value={operator.operator_id!}
              >
                {operator.operator_name}
              </Option>
            ))}
          </Select>
          <Text>Select warehouse from</Text>
          <Select
            className="ship-device__input"
            onChange={handleSelectWarehouseFrom}
            allowClear
            placeholder={'Select warehouse from'}
            value={selectedWarehouseFrom}
            onClear={handleOnClearSelectWarehouseFrom}
          >
            {warehouseList
              .filter((warehouse) => warehouse.operator_id === operatorFrom)
              .map((warehouse) => (
                <Option
                  key={warehouse.warehouse_id}
                  value={warehouse.warehouse_id!}
                >
                  {warehouse.warehouse_name}
                </Option>
              ))}
          </Select>
          <Text style={{ fontSize: 12 }}>Past device number from a new line</Text>
          <Input.TextArea
            onChange={handleFilter}
            className="ship-device__input"
            placeholder="Filter by device number"
            disabled={!deviceList.length || !selectedWarehouseFrom}
            value={filteredTextArea}
            autoSize={{ minRows: 3 }}
          />
          <div className="ship-device__brackets">
            <Button onClick={handleAddBrackets}>Add Brackets</Button>
            <Button onClick={handleClearBrackets}>Clear</Button>
          </div>
          <div>
            {!!brackets.length && brackets.map((bracket) => (
              <div key={bracket.bracketId} className="ship-device__brackets">
                <Select
                  style={{ width: 200 }}
                  onChange={(event) => handleBracketsCode(event, bracket.bracketId)}
                  placeholder={'Brackets Code'}
                  value={bracket.bracketCode}
                  options={[
                    {
                      value: 'BIN-MOUNT',
                      label: 'BIN-MOUNT',
                    },
                    {
                      value: 'CONSOLE-MOUNT',
                      label: 'CONSOLE-MOUNT',
                    },
                  ]}
                />
                <InputNumber
                  value={bracket.bracketQuantity}
                  min={1}
                  placeholder={'Quantity'}
                  onChange={(event) => handleBracketsQuantity(event, bracket.bracketId)}
                />
                <Button onClick={() => handleDeleteBracket(bracket.bracketId)}>
                  <CloseOutlined style={{ color: '#D4732A' }}/>
                </Button>
              </div>
            ))}
          </div>
        </div>
        <div className="ship-device__part ship-device__action">
          <Button disabled={issueSign !== SHIP_DEVICE_MESSAGE.NO_ISSUES} onClick={handleMove}>Ship</Button>
          <Text type={issueSign === SHIP_DEVICE_MESSAGE.NO_ISSUES ? 'success' : 'warning'}>{issueSign}</Text>
        </div>
        <div className="ship-device__part">
          <Text>Select operator to</Text>
          <Select
            className="ship-device__input"
            onChange={handleSelectOperatorTo}
            allowClear={true}
            placeholder={'Select operator to'}
            value={operatorTo}
            onClear={handleOnClearSelectOperator}
          >
            {operatorList.map((operator) => (
              <Option
                key={operator.operator_id}
                value={operator.operator_id!}
              >
                {operator.operator_name}
              </Option>
            ))}
          </Select>
          <Text>Select warehouse to</Text>
          <Select
            className="ship-device__input"
            onChange={handleSelectWarehouseTo}
            allowClear
            placeholder={'Select warehouse to'}
            value={selectedWarehouseTo}
            onClear={handleOnClearSelectWarehouseTo}
          >
            {warehouseList
              .filter((warehouse) => warehouse.operator_id === operatorTo)
              .map((warehouse) => (
                <Option
                  key={warehouse.warehouse_id}
                  value={warehouse.warehouse_id!}
                >
                  {warehouse.warehouse_name}
                </Option>
              ))}
          </Select>
          <Text>Select effective date</Text>
          <DatePicker
            showTime={{ format: 'HH:mm' }}
            format="YYYY-MM-DD HH:mm"
            className="move-device__input"
            onChange={onChangeEffectiveDate}
          />
          <Text>Comment</Text>
          <Input.TextArea
            onChange={handleComment}
            className="ship-device__input"
            placeholder="Comment"
            value={comment}
            autoSize={{ minRows: 2 }}
          />
          {
            shipDeviceResponse.message
            && <Text type={shipDeviceResponse.error ? 'danger' : 'success'}>
              <p>{shipDeviceResponse.message}</p>
              {!shipDeviceResponse.error && filteredDeviceList.map((device) => (
                <span> {device.device_number} </span>
              ))}
            </Text>
          }
        </div>
      </div>
      <Text>Devices with issue</Text>
      <Table
        locale={{
          emptyText: '---',
        }}
        className={'click_row'}
        columns={columns}
        dataSource={filteredDeviceList}
        pagination={{ pageSize: 100 }}
      />
      <Text>Ships</Text>
      <Table
        columns={shipColumns(operatorList, warehouseList)}
        pagination={paginationShipTable}
        onChange={handleShipTableChange}
        expandable={{
          expandedRowRender: (record) => (<>
            <div className="ship-device__sub-row ship-device__sub-row-header">
              <p>Type</p>
              <p>Detail</p>
            </div>
            {shipDetailList.filter((shipDetail) => shipDetail.ship_id === record.ship_id).map((shipDetail) => (
              <div key={shipDetail.ship_detail_id} className="ship-device__sub-row">
                <p>
                  {shipDetail.device_id && <b>Device</b>}
                  {shipDetail.bracket_quantity && shipDetail.bracket_code && <b>Bracket</b>}
                </p>
                <p>
                  {shipDetail.device_id && <><b>Number: </b> {shipDetail.device_id.device_number}</>}
                  {shipDetail.bracket_quantity && shipDetail.bracket_code && <>
                    <b>Code: </b> {shipDetail.bracket_code}, <b>Quantity: </b> {shipDetail.bracket_quantity}</>}
                </p>
              </div>
            ))}
          </>),
          rowExpandable: (record) => !!record.effective_datetime,
        }}
        dataSource={shipList}
      />
    </Spin>
      </>
  );
};

import * as React from 'react';
import {
  Select,
} from 'antd';
import {
  useCallback, useState, useEffect, useRef,
} from 'react';
import axios from 'axios';
import { useDataCommon } from '../DataCommonProvider';
import { DropDownType } from '../../../common/types';
import { OperatorType } from '../../../common/entityTypes';
import { API } from '../constsAPI';
import { TransferType } from '../../../common/typesEditor';

const { Option } = Select;

export const SelectOperator: React.FC = () => {
  const dataCommon = useDataCommon();

  const [filterOperatorName, setFilterOperatorName] = useState<DropDownType[]>([]);
  const [listOperators, setListOperators] = useState<OperatorType[]>([]);
  const [operatorName, setOperatorName] = useState(localStorage.getItem('operator_name') as any || undefined);
  const counter = useRef(0);

  const getDataDB = useCallback(async () => {
    const dataDB = (await axios.get<(OperatorType)[]>(`${API.URL_AURORA_DB}/operator_list`)).data;
    const newFilterOperatorName = [];
    const newListOperators: OperatorType[] = [];
    const newListTransferOperators: TransferType[] = [];
    for (const data of dataDB) {
      newFilterOperatorName.push({ text: data.operator_name, value: data.operator_name });
      newListOperators.push({
        operator_code: data.operator_code,
        operator_id: data.operator_id,
        operator_name: data.operator_name,
        timezone: 'America/New_York',
      });
      newListTransferOperators.push({
        key: `${data.operator_id}`,
        title: data.operator_code,
        description: data.operator_name,
        disabled: false,
      });
    }
    setListOperators(newListOperators);
    dataCommon.listOperator = newListOperators;
    dataCommon.listTransferOperator = newListTransferOperators;
    setFilterOperatorName(newFilterOperatorName);
    counter.current += 1;
  }, []);

  useEffect(() => {
    (async () => {
      await getDataDB();
    })();
  }, []);

  const handleChange = useCallback((value: string) => {
    const newOperator = listOperators.filter((elem) => elem.operator_name === value)[0];
    setOperatorName(value);
    dataCommon.setOperator(newOperator);
  }, [listOperators]);

  useEffect(() => {
    if (listOperators.length === 1) {
      handleChange(listOperators[0].operator_name);
    }
  }, [listOperators]);

  useEffect(() => {
    if (operatorName && counter.current === 1) {
      window.location.reload();
      counter.current = 0;
    }
  }, [operatorName, counter]);

  return (
    <Select
      style={{ width: 220, margin: '0 20px', display: listOperators.length === 1 ? 'none' : 'auto' }}
      placeholder="select operator"
      onChange={handleChange}
      optionLabelProp="label"
      allowClear={true}
      value={operatorName || undefined}
    >
      {filterOperatorName
        .sort((a, b) => a.text.localeCompare(b.text))
        .map((elem) => <Option key={elem.value} value={elem.value}>{elem.text}</Option>)}
    </Select>
  );
};

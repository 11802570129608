import {
  Input, Button, Form, Row, Col, Spin, Checkbox,
} from 'antd';
import React, {
  useCallback, useEffect, useState,
} from 'react';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import axios from 'axios';
import { useHistory } from 'react-router';
import { AuthenticationResultType } from '@aws-sdk/client-cognito-identity-provider';
import { API } from '../constsAPI';
import { PanelShadow } from '../components/PanelShadow';
import { useDataCommon } from '../DataCommonProvider';
import { ResponseLoginType } from '../../../common/types';
import { useAlertMessage } from '../hooks/useAlertMessage';
import { Logo } from '../components/Logo';

export const LoginPage: React.FC = () => {
  const dataCommon = useDataCommon();
  const [msg, setMsg] = useState('');
  const [spin, setSpin] = useState(false);
  const [rememberMe, setRememberMe] = useState(true);
  const history = useHistory();

  const clearToken = useCallback(() => {
    localStorage.removeItem('IdToken');
    localStorage.removeItem('RefreshToken');
    localStorage.removeItem('AccessToken');
  }, []);

  useEffect(() => {
    clearToken();
  }, []);

  const setToken = useCallback((authResult: AuthenticationResultType) => {
    localStorage.setItem('IdToken', authResult.IdToken || '');
    localStorage.setItem('AccessToken', authResult.AccessToken || '');
    console.log('rememver', rememberMe);
    if (rememberMe) localStorage.setItem('RefreshToken', authResult.RefreshToken || '');
  }, [rememberMe]);

  const onFinishLogin = useCallback(async (values: any) => {
    setMsg('');
    setSpin(true);
    console.log('Success:', values);
    const resultLogin = await axios.post<ResponseLoginType>(`${API.URL_LOGIN}/login`, JSON.stringify({
      username: values.username, password: values.password,
    }));

    const { data } = resultLogin;

    if (data.status === API.STATUS_200) {
      localStorage.setItem('username', values.username);
      dataCommon.username = values.username;

      setSpin(false);
      if (data.session) {
        history.push('/set_new_password', '/');
        localStorage.setItem('session', data.session);
      } else if (data.authResult) {
        setToken(data.authResult);
        history.push('/');
      }
    } else {
      setSpin(false);
      setMsg(data.message);
    }
  }, [rememberMe]);

  const onFinishFailed = useCallback((errorInfo: any) => {
    console.log('Failed:', errorInfo);
  }, []);

  const renderMessageError = useAlertMessage('error', msg);

  const handleRememberMe = (event: CheckboxChangeEvent) => {
    console.log('event.target.checked', event.target.checked);
    setRememberMe(event.target.checked);
  };

  return (
    <PanelShadow>
      <Logo margin={'0 0 30px 0'} type={'full'}/>
      <Spin spinning={spin} tip={'please wait ...'}>
        <Form
          name="login"
          labelCol={{ span: 6 }}
          //                initialValues={{ username: name, password: pass }}
          wrapperCol={{ span: 18 }}
          onFinish={onFinishLogin}
          onFinishFailed={onFinishFailed}
          autoComplete="nope"
        >
          <Form.Item
            label="User Name"
            name={'username'}
            rules={[
              { required: true, message: 'Please input your username!' },
              {
                type: 'email',
                message: 'The input is not valid E-mail!',
              },
            ]}
          >
            <Input/>
          </Form.Item>

          <Form.Item
            label="Password"
            name={'password'}
            rules={[
              { required: true, message: 'Please input your password!' },
            ]}
          >
            <Input.Password/>
          </Form.Item>
          {
            renderMessageError
          }
          <Row>
            <Col span={18} style={{ padding: 3 }}>
              <Checkbox defaultChecked={rememberMe} onChange={handleRememberMe}>Remember me</Checkbox>
            </Col>
          </Row>
          <Row>
            <Col span={18} style={{ padding: 3 }}>
              <a onClick={() => {
                history.push('/reset_password', '/login');
              }}>Forgot your password?</a>
            </Col>
            <Col span={6} style={{ textAlign: 'right' }}>
              <Button type="primary" htmlType="submit">
                Sign In
              </Button>
            </Col>
          </Row>
        </Form>
      </Spin>
    </PanelShadow>
  );
};

import * as React from 'react';
import styled from 'styled-components';

export const EmptyLine: React.FC = () => {
  const Hr = styled.hr`
  margin: 0;
  background-color: var(--primary-color);
  border: none;
  padding: 3px 0;
`;
  return <Hr />;
};

export class SIGNAL {
  public static SIGNAL_0 = 0;

  public static SIGNAL_80 = 80;

  public static SIGNAL_90 = 90;

  public static SIGNAL_100 = 100;

  public static ICON_POOR = 'poor';

  public static ICON_ZERO = 'zero';

  public static ICON_EXCELLENT = 'excellent';

  public static ICON_GOOD = 'good';

  public static ICON_FAIR = 'fair';
}

import axios from 'axios';

export const uploadFileApi = async (file: any, linkSave: string) => {
  try {
    const xhr = new XMLHttpRequest();
    xhr.open('PUT', linkSave, true);
    xhr.setRequestHeader('Content-Type', file.type);
    xhr.onload = function () {
      if (xhr.status === 200) {
        console.log('File uploaded successfully');
      } else {
        console.log('File upload failed');
      }
    };
    await xhr.send(new Blob([file], { type: file.type }));
    await new Promise((resolve) => setTimeout(resolve, 2000));
    console.log('finished save file');
    return true;
  } catch (exception) {
    console.log(exception);
    /* handle the exception */
  }
  return false;
};

export const deleteFile = async (link: string) => {
  try {
    await axios.delete(link);
    return true;
  } catch (exception) {
    console.log(exception);
    /* handle the exception */
  }
  return false;
};

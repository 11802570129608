import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { ERequestRoute, ERequestUrl } from '../config';
import { ScheduleType } from '../../../../common/entityTypes';

export interface IScheduleState {
  scheduleList: ScheduleType[]
}

const initialState: IScheduleState = {
  scheduleList: [],
};

export const fetchSchedule = createAsyncThunk('scheduleList/fetch', async () => {
  const response = await axios.get<ScheduleType[]>(`${ERequestUrl.BASE_CRUD}/${ERequestRoute.SCHEDULE}`);
  return response.data;
});

export const scheduleSlice = createSlice({
  name: 'schedule',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchSchedule.fulfilled, (state, action: PayloadAction<ScheduleType[]>) => {
      state.scheduleList = action.payload;
    });
  },
});

// Action creators are generated for each case reducer function
// export const {} = counterSlice.actions;

export default scheduleSlice.reducer;

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import detector from 'i18next-browser-languagedetector';
import * as tresources from './i18n/resources.json';

i18n.use(initReactI18next).use(detector).init({
  lng: 'en',
  fallbackLng: 'en',
  debug: true,
  // eslint-disable-next-line global-require
  resources: (tresources as any).default,
});

export default i18n;

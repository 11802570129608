import * as React from 'react';
import { Select } from 'antd';
import {
  SetStateAction, Dispatch, useState, useCallback,
} from 'react';

const { Option } = Select;

type LocationSelectProps = {
  list: any,
  setCallback: Dispatch<SetStateAction<any | undefined>>,
  filedId: string,
  filedCaption: string[] | string,
  templateCaption?: string,
  placeholder: string,
  disabled?: boolean
  mode?: 'multiple' | 'tags',
  width?: string,
  currentValue?: string,
  resetFlag?: boolean,
};

export const SimpleSelect: React.FC<LocationSelectProps> = (props) => {
  const [currentValue, setCurrentValue] = useState<string | undefined>();
  const [autocompleteDisabled, setAutocompleteDisabled] = useState<boolean>(false);
  const {
    mode, list, filedId, setCallback, disabled, filedCaption, width, placeholder,
  } = props;

  if (props.currentValue !== currentValue) {
    setCurrentValue(props.currentValue);
  }

  const searchItemInList = (value: string) => {
    if (!value) return undefined;

    const arrayValue = `${value}`.split(',');
    const item = list.filter((elem: any) => arrayValue.includes(`${elem[filedId]}`));
    if (item.length) return mode === 'multiple' ? item : item[0];

    return undefined;
  };

  const onChange = (value: string) => {
    console.log(`selected ${value}`);
    const newValue = searchItemInList(value) || (mode === 'multiple' ? [] : '');
    setCallback(newValue);
    setCurrentValue(value);
  };

  const getCaption = useCallback((elem: any) => {
    if (props.templateCaption) {
      let string = props.templateCaption;
      for (const field of filedCaption) {
        string = string.replace('#', elem[field]);
      }
      return string;
    }
    return Array.isArray(filedCaption) ? elem[filedCaption[0]] : elem[filedCaption];
  }, []);

  const onFocus = () => {
    if (!autocompleteDisabled) {
      let i;
      const el = document.getElementsByClassName(
        'ant-select-selection-search-input',
      );
      for (i = 0; i < el.length; i++) {
        el[i].setAttribute(
          'autocomplete',
          'nope',
        );
      }
      setAutocompleteDisabled(true);
    }
  };

  return <Select
      mode={mode}
      showSearch
      allowClear={true}
      style={{ width }}
      placeholder={placeholder}
      optionFilterProp="children"
      value={searchItemInList(currentValue!) ? currentValue : undefined}
      disabled={disabled}
      onChange={onChange}
      onFocus={onFocus}
      filterOption={(input, option) => (option ? option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false)
      }
    >
      {
        list.map((elem: any) => <Option key={elem[filedId]} value={elem[filedId]}>{getCaption(elem)}</Option>)
      }
    </Select>;
};

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { ERequestRoute, ERequestUrl } from '../config';
import { DriverHistoryType } from '../../../../common/types';

export interface IDeriverState {
  driverHistory: DriverHistoryType[]
}

const initialState: IDeriverState = {
  driverHistory: [],
};

export const fetchDriverHistoryByScheduleId = createAsyncThunk('driverHistoryByScheduleId/fetch', async (route_schedule_id: number) => {
  const response = await axios.get<DriverHistoryType[]>(`${ERequestUrl.BASE_CRUD}/${ERequestRoute.DRIVER_HISTORY}?route_schedule_id=${route_schedule_id}`);
  return response.data;
});

export const driverSlice = createSlice({
  name: 'driver',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchDriverHistoryByScheduleId.fulfilled, (state, action: PayloadAction<DriverHistoryType[]>) => {
      state.driverHistory = action.payload;
    });
  },
});

// Action creators are generated for each case reducer function
// export const {} = counterSlice.actions;

export default driverSlice.reducer;

import * as React from 'react';
import { HeaderPage } from './HeaderPage';
import { SpaceWrapperStyle } from '../style_component/SpaceWrapper';

type PageTemplateProps = {
  onClickCallback?: any,
};

export const PageTemplate: React.FC<PageTemplateProps> = (props) => (
  <SpaceWrapperStyle direction="vertical" onClick={props.onClickCallback} padding="10px">
    <HeaderPage />
    {props.children}
  </SpaceWrapperStyle>
);

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { ERequestRoute, ERequestUrl } from '../config';
import { LocationFootprintDeviceType, LocationType } from '../../../../common/entityTypes';

const initialLocation: LocationType = {
  location_id: 0,
  customer_id: null!,
  region_id: null!,
  partner_location_id: '',
  location_name: '',
  warehouse_id: null!,
  address1: '',
  address2: '',
  city: '',
  state_province: '',
  zip: '',
  country: '',
  schedule_id: null!,
  cycle_id: null!,
  timezone: '',
  days_open: '',
  exception_period: '',
};

export const LocationModalTypeOption = {
  ADD_NEW: 'Add new location',
  EDIT: 'Edit location',
};

export interface ILocationState{
  initialLocation: LocationType,
  locationFootprintList: LocationFootprintDeviceType[],
  locationList: LocationType[],
  locationLoader: boolean,
  locationModalType: string
}

const initialState: ILocationState = {
  initialLocation,
  locationFootprintList: [],
  locationLoader: false,
  locationList: [],
  locationModalType: LocationModalTypeOption.ADD_NEW,
};

export const fetchLocationFootprint = createAsyncThunk('locationFootprintList/fetch', async (filters?: string) => {
  const response = await axios.get<LocationFootprintDeviceType[]>(`${ERequestUrl.AURORA}/${ERequestRoute.LOCATION_FOOTPRINT_LIST}?${filters}`);
  return response.data.map((row, index) => ({ ...row, key: index }));
});

export const fetchLocations = createAsyncThunk('locationList/fetch', async () => {
  const response = await axios.get<LocationType[]>(`${ERequestUrl.BASE_CRUD}/${ERequestRoute.LOCATION}`);
  return response.data;
});

export const fetchLocationsById = createAsyncThunk('locationListById/fetch', async (locationIds: string) => {
  const response = await axios.get<LocationType[]>(`${ERequestUrl.BASE_CRUD}/${ERequestRoute.LOCATION}?${locationIds}`);
  return response.data;
});

export const addLocation = createAsyncThunk('location/add', async (payload: LocationType) => {
  const response = await axios.post<LocationType[]>(`${ERequestUrl.BASE_CRUD}/${ERequestRoute.LOCATION}`, JSON.stringify(payload));
  return response.data;
});

export const updateLocation = createAsyncThunk('location/update', async (payload: LocationType) => {
  const response = await axios.post<LocationType[]>(`${ERequestUrl.BASE_CRUD}/${ERequestRoute.LOCATION}/${payload.location_id}`, JSON.stringify(payload));
  return response.data;
});

export const locationSlice = createSlice({
  name: 'location',
  initialState,
  reducers: {
    setLocationLoader: (state, action: PayloadAction<boolean>) => {
      state.locationLoader = action.payload;
    },
    setInitialLocation: (state, action: PayloadAction<LocationType>) => {
      state.initialLocation = action.payload;
    },
    setLocationModalType: (state, action: PayloadAction<string>) => {
      state.locationModalType = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchLocationFootprint.pending, (state) => {
      state.locationLoader = true;
    });
    builder.addCase(fetchLocationFootprint.fulfilled, (state, action: PayloadAction<LocationFootprintDeviceType[]>) => {
      state.locationFootprintList = action.payload;
      state.locationLoader = false;
    });
    builder.addCase(fetchLocations.fulfilled, (state, action: PayloadAction<LocationType[]>) => {
      state.locationList = action.payload;
    });
    builder.addCase(fetchLocationsById.fulfilled, (state, action: PayloadAction<LocationType[]>) => {
      state.locationList = action.payload;
    });
  },
});

// Action creators are generated for each case reducer function
// export const {} = counterSlice.actions;

export const {
  setLocationLoader,
  setInitialLocation,
  setLocationModalType,
} = locationSlice.actions;

export default locationSlice.reducer;

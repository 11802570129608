import jwt from 'jsonwebtoken';
import { DateTime } from 'luxon';
import axios from 'axios';
import { ResponseLoginType, UserTokenType } from '../../../common/types';
import { API } from '../constsAPI';

const setToLS = (name: string, data: string) => {
  localStorage.setItem(name, data || '');
};

const removeFromLS = (keys: string[]) => {
  keys.forEach((key) => {
    localStorage.removeItem(key);
  });
};

export const clearTokens = () => {
  removeFromLS(
    [
      'IdToken',
      'RefreshToken',
      'AccessToken',
      'username',
      'operator_id',
      'operator_name',
      'operator_code',
    ],
  );
  return false;
};

const refreshToken = async () => {
  const subRefreshToken = localStorage.getItem('RefreshToken');

  if (!subRefreshToken) clearTokens();

  const payload = { refreshToken: subRefreshToken };
  const resultLogin = await axios.post<ResponseLoginType>(`${API.URL_LOGIN}/refresh_token`, JSON.stringify(payload));
  const { data } = resultLogin;

  if (data.response.AuthenticationResult) {
    setToLS('IdToken', data.response.AuthenticationResult.IdToken);
    setToLS('AccessToken', data.response.AuthenticationResult.AccessToken);

    return true;
  }

  return clearTokens();
};

export const decodeToken = (token: string) => jwt.decode(token) as UserTokenType;

export const isTokenValid = async () => {
  let token: string = '';

  if (typeof window !== 'undefined') {
    token = localStorage.getItem('IdToken') || '';
  } else return clearTokens();

  const decodedToken = decodeToken(token);

  if (!decodedToken) return clearTokens();

  if (decodedToken.exp - DateTime.now().toSeconds() < 100) {
    return refreshToken();
  }
  return true;
};

export const dateNowForDB = () => new Date(Date.now()).toISOString();
export const dateForDB = (date: Date) => new Date(date).toISOString();

import React, {
  useCallback, useEffect, useState,
} from 'react';
import {
  Space, Spin,
  Table, Typography,
} from 'antd';
import axios from 'axios';
import { Translate } from 'i18n-next-tools';
import { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { DateTime } from 'luxon';
import { Link } from 'react-router-dom';
import { API } from '../constsAPI';
import {
  DeviceReportType, DeviceType, FootprintType, OperatorType,
} from '../../../common/entityTypes';
import { DropDownType } from '../../../common/types';
import { DEFAULT_VALUE, PAGINATION_TABLE } from '../consts';
import { TableSearch } from '../components/TableSearch';
import { useDataCommon } from '../DataCommonProvider';
import { Signal } from '../components/Signal';
import { INCHES } from '../common/dimentions';
import { Battery } from '../components/Battery';
import { handlePageSize } from '../common';

const { Title } = Typography;
const filterOperatorName: DropDownType[] = [];

export const DeviceListPage: React.FC = () => {
  const dataCommon = useDataCommon();
  const t = Translate('DeviceListPage');
  const [columnSearchDeviceNumber, setColumnSearchDeviceNumber] = useState<object>();
  const [columnSearchDeviceSerialNumber, setColumnSearchDeviceSerialNumber] = useState<object>();
  const [loadingTable] = useState<boolean>(false);
  const [paginationTable, setPaginationTable] = useState<TablePaginationConfig>({
    ...PAGINATION_TABLE,
    pageSize: handlePageSize({ place: 'devices' }),
  });

  const columnOperatorName = {
    title: 'Operator Name',
    dataIndex: 'operator_name',
    key: 'operator_name',
    filters: filterOperatorName,
    onFilter: (value: any, record: any) => record.operator_name.indexOf(value) === 0,
  };

  const columns: ColumnsType<DeviceType & OperatorType & FootprintType & DeviceReportType> = [
    {
      title: 'Device number',
      dataIndex: 'device_number',
      key: 'device_number',
      sorter: (a: any, b: any) => (a.device_number > b.device_number ? 1 : -1),
      ...columnSearchDeviceNumber,
      render: (device_number: number, raw) => {
        const params = [
          `device=${raw.device_id}`,
          'rowlimit=1000',
        ];
        const link = `/device/detail?${params.join('&')}`;
        return <Link className="table__link" to={link}>{device_number}</Link>;
      },
    },
    columnOperatorName,
    {
      title: 'Last Report',
      dataIndex: 'report_datetime',
      key: 'report_datetime',
      defaultSortOrder: 'descend',
      sorter: (a: any, b: any) => {
        const time1 = a.report_datetime ? DateTime.fromISO(a.report_datetime).toMillis() : 0;
        const time2 = b.report_datetime ? DateTime.fromISO(b.report_datetime).toMillis() : 0;
        return (time1 > time2 ? 1 : -1);
      },
      render: (value: string) => (value ? DateTime.fromISO(value).toUTC().toFormat('y-LL-dd hh:mm:ss a') : DEFAULT_VALUE),
    },
    {
      title: 'Ver',
      dataIndex: 'appv',
      key: 'appv',
      render: (value: string) => (value || DEFAULT_VALUE),
    },
    {
      title: 'Warehouse',
      dataIndex: 'warehouse_name',
      key: 'warehouse_name',
      render: (value: string) => (value || DEFAULT_VALUE),
    },
    {
      title: 'Location Name',
      dataIndex: 'container_footprint_name',
      key: 'container_footprint_name',
      render: (value: string) => (value || DEFAULT_VALUE),
    },
    {
      title: 'Depth',
      dataIndex: 'depth',
      key: 'depth',
      sorter: (a: any, b: any) => (a.depth > b.depth ? 1 : -1),
      render: (value: number) => (value >= 0 ? `${Math.round((value! / INCHES))}` : DEFAULT_VALUE),
    },
    {
      title: 'Signal',
      dataIndex: 'signal_rsrp',
      key: 'signal_rsrp',
      width: 70,
      render: (value: number) => (value ? <Signal rssi={value}/> : DEFAULT_VALUE),
    },
    {
      title: 'Battery',
      dataIndex: 'battery',
      key: 'battery',
      sorter: (a: any, b: any) => (a.battery > b.battery ? 1 : -1),
      render: (value: number) => (value ? <Battery value={value}/> : DEFAULT_VALUE),
    },
    {
      title: 'Device serial number',
      dataIndex: 'device_serial_number',
      key: 'device_serial_number',
      ...columnSearchDeviceSerialNumber,
    },
  ];

  if (dataCommon.operator) {
    (columnOperatorName as any).filteredValue = [dataCommon.operator.operator_name] || null;
  }

  const [spin, setSpin] = useState(false);
  const [dataTable, setDataTable] = useState<(DeviceType & OperatorType & FootprintType & DeviceReportType)[]>([]);

  const getDataDB = useCallback(async () => {
    const ids: number[] = [];

    const dataDB = (await axios.get<(DeviceType & OperatorType & FootprintType & DeviceReportType)[]>(`${API.URL_AURORA_DB}/device_list`)).data;
    setDataTable((dataDB).map((elem) => {
      if (!filterOperatorName.filter((name) => name.value === elem.operator_name).length) {
        filterOperatorName.push({ text: elem.operator_name!, value: elem.operator_name! });
      }

      const modifyElem = elem;
      modifyElem.key = elem.device_id;
      return modifyElem;
    }).filter((elem) => {
      if (!ids.includes(elem.device_id)) {
        ids.push(elem.device_id);
        return true;
      }
      return false;
    }));

    console.log('xxxxxxxxxxxxxxx', filterOperatorName);
    setSpin(false);
  }, []);

  useEffect(() => {
    setSpin(true);
    getDataDB();
  }, []);

  const handleTableChange = useCallback((pagination) => {
    setPaginationTable(pagination);
    handlePageSize({ place: 'devices', pageSize: pagination.pageSize });
  }, []);

  const getColumnSearchProps = useCallback((callback: (dataIndex: any) => {}) => {
    setColumnSearchDeviceNumber(callback('device_number'));
    setColumnSearchDeviceSerialNumber(callback('device_serial_number'));
  }, []);

  return (
    <>
      <TableSearch columnSearchProps={getColumnSearchProps}/>
      <Space direction={'horizontal'} align={'center'} style={{ justifyContent: 'space-between', width: '100%' }}>
        <Title level={3}>{t.k1('Devices')}</Title>
      </Space>
      <Spin spinning={spin} tip={'please wait ...'}>
        <Table<DeviceType & OperatorType & FootprintType & DeviceReportType>
          columns={columns}
          className={'click_row'}
          dataSource={dataTable}
          pagination={paginationTable}
          onChange={handleTableChange}
          loading={loadingTable}
        />
      </Spin>
    </>
  );
};

import { ColumnsType } from 'antd/es/table';
import { CustomerType } from '../../../../../common/entityTypes';

export const columns: ColumnsType<CustomerType> = [
  {
    title: 'Name',
    dataIndex: 'customer_name',
  },
  {
    title: 'Address 1',
    dataIndex: 'address1',
  },
  {
    title: 'Address 2',
    dataIndex: 'address2',
  },
  {
    title: 'City',
    dataIndex: 'city',
    filters: [
      {
        text: 'Dnipro',
        value: 'Dnipro',
      },
      {
        text: 'New York',
        value: 'New York',
      },
    ],
  },
  {
    title: 'State Province',
    dataIndex: 'state_province',
  },
  {
    title: 'Zip',
    dataIndex: 'zip',
  },
  {
    title: 'Country',
    dataIndex: 'country',
  },
];

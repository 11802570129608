import * as React from 'react';
import {
  Dropdown, Menu,
} from 'antd';
import {
  DownOutlined, LogoutOutlined, SafetyCertificateOutlined,
  UserSwitchOutlined,
} from '@ant-design/icons/lib';
import { useCallback } from 'react';
import { useHistory } from 'react-router';
import { AvatarUser } from './AvatarUser';
import { clearTokens } from '../common';

type AvatarUserProps = {
  showLogin?: boolean
};

export const UserDropdownMenu: React.FC<AvatarUserProps> = () => {
  const history = useHistory();

  const onClickLogout = useCallback(() => {
    clearTokens();
    history.push('/login');
  }, []);

  const menu = useCallback(() => (
      <Menu>
        <Menu.Item key="1" icon={<SafetyCertificateOutlined />}>
          <span onClick={() => history.push('/change_password')}>
            change password
          </span>
        </Menu.Item>
        <Menu.Item key="2" icon={<UserSwitchOutlined />} disabled>
          <a rel="noopener noreferrer" href="https://www.antgroup.com">
            profile
          </a>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key="3" danger icon={<LogoutOutlined />} onClick={onClickLogout} >
          logout
        </Menu.Item>
      </Menu>
  ), []);

  return (
      <Dropdown overlay={menu} trigger={['click']}>
        <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
          <AvatarUser showLogin={true} /> <DownOutlined />
        </a>
      </Dropdown>
  );
};

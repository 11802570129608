import {
  Button, Checkbox, Form, Input, Modal, Select, Typography,
} from 'antd';
import React, { useEffect, useState } from 'react';
import {
  CustomerType, CycleType, LocationType, RegionType, ScheduleType, WarehouseType,
} from '../../../../../common/entityTypes';
import { LocationExceptionPeriod } from './LocationExceptionPeriod';

type LocationFormModalType = {
  isModalVisible: boolean,
  closeModal: () => void,
  onFinish: (values: LocationType) => void,
  data: LocationType,
  title: string,
  regionList: RegionType[]
  customerList: CustomerType[],
  scheduleList: ScheduleType[]
  cycleList: CycleType[],
  warehouseList: WarehouseType[],
};

const { Option } = Select;
const { Text } = Typography;

export interface IExceptionPeriod {
  exceptionPeriodId: number,
  exceptionPeriod: any,
  exceptionPeriodForecast: number,
  exceptionPeriodNotes: string,
}

export interface IExceptionPeriodOptions {
  label: string,
  value: number
}

const optionsOpenDays = [
  { label: 'Sun', value: 'Sun' },
  { label: 'Mon', value: 'Mon' },
  { label: 'Tue', value: 'Tue' },
  { label: 'Wed', value: 'Wed' },
  { label: 'Thu', value: 'Thu' },
  { label: 'Fri', value: 'Fri' },
  { label: 'Sat', value: 'Sat' },
];

const setUpExceptionsPeriodOptions = () => {
  const percents = [0, 10, 25, 50, 75, 125, 150];
  return percents.map((percent) => ({
    label: percent === 0 ? 'Location closed, scheduling disabled' : `${percent}% normal activity`,
    value: percent,
  }));
};

const exceptionsPeriodOptions: IExceptionPeriodOptions[] = setUpExceptionsPeriodOptions();

const defaultOpenDaysOptions = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'];

export const LocationFormModal = (props: LocationFormModalType) => {
  const {
    isModalVisible,
    closeModal,
    onFinish,
    data,
    title,
    regionList,
    customerList,
    scheduleList,
    cycleList,
    warehouseList,
  } = props;
  const [form] = Form.useForm();
  const [selectedRegion, setSelectedRegion] = useState(data.region_id);
  const [exceptionPeriod, setExceptionPeriod] = useState();
  const [exceptionPeriodForecast, setExceptionPeriodForecast] = useState();
  const [exceptionPeriodNotes, setExceptionPeriodNotes] = useState();
  const [listExceptionPeriod, setListExceptionPeriod] = useState<IExceptionPeriod[] | any>([]);

  useEffect(() => {
    const daysOpen = data.days_open ? JSON.parse(data.days_open) : [];
    const exceptionPeriodFromData = data.exception_period ? JSON.parse(data.exception_period) : [];

    if (exceptionPeriodFromData.length) setListExceptionPeriod(exceptionPeriodFromData);

    form.setFieldsValue({
      ...data,
      days_open: daysOpen,
      exception_period: exceptionPeriodFromData,
    });
    setSelectedRegion(data.region_id);
  }, [data]);

  const handleRegionChange = (value: number) => {
    setSelectedRegion(value);
    form.setFieldsValue({ customer_id: null });
    form.setFieldsValue({ region_id: value });
  };

  const handleCustomerChange = (value: number) => {
    form.setFieldsValue({ customer_id: value });
  };

  const handleScheduleChange = (value: number) => {
    form.setFieldsValue({ schedule_id: value });
  };

  const handleCycleChange = (value: number) => {
    form.setFieldsValue({ cycle_id: value });
  };

  const handleWarehouseChange = (value: number) => {
    form.setFieldsValue({ warehouse_id: value });
  };

  const handleAddExceptionPeriod = () => {
    const payload: IExceptionPeriod[] = [...listExceptionPeriod, {
      exceptionPeriodId: Number(new Date().getTime().toString().split('')
        .splice(9)
        .join('')),
      exceptionPeriod,
      exceptionPeriodForecast,
      exceptionPeriodNotes,
    }];

    setListExceptionPeriod(payload);
  };

  const handleExceptionPeriodNotes = (event: any) => {
    console.log('value', event.target.value);
    setExceptionPeriodNotes(event.target.value);
  };

  const handleExceptionPeriodForecast = (value: any) => {
    console.log('value', value);
    setExceptionPeriodForecast(value);
  };

  const handleExceptionPeriod = (value: any) => {
    console.log('value', value);
    setExceptionPeriod(value);
  };

  const handleOnFinish = (values: any) => {
    onFinish({
      ...values,
      days_open: JSON.stringify(values.days_open),
      exception_period: JSON.stringify(listExceptionPeriod),
    });
  };

  return (
    <Modal
      title={title} visible={isModalVisible}
      onCancel={closeModal}
      footer={[
        <Button key="cancel" onClick={closeModal}>
          Cancel
        </Button>,
        <Button form="addLocation" key="submit" type="primary" htmlType="submit">
          Save
        </Button>,
      ]}>
      <Form
        form={form}
        name="addLocation"
        layout="vertical"
        onFinish={handleOnFinish}
        autoComplete="nope"
      >
        <Form.Item
          label="Region"
          name={'region_id'}
          rules={[
            { required: true, message: 'Please select region!' },
          ]}
          initialValue={data.region_id}
        >
          <Select
            onChange={handleRegionChange}
            allowClear={true}
            placeholder={'Select region'}
            value={data.region_id}
          >
            {regionList.map((region) => (
              <Option
                key={region.region_id}
                value={region.region_id!}
              >
                {region.region_name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Warehouse"
          name={'warehouse_id'}
          rules={[
            { required: true, message: 'Please select warehouse!' },
          ]}
        >
          <Select
            onChange={handleWarehouseChange}
            allowClear={true}
            placeholder={'Select warehouse'}
            value={data.warehouse_id}
          >
            {warehouseList
              .filter((warehouse) => warehouse.operator_id === regionList
                .find((region) => region.region_id === selectedRegion)?.operator_id!)
              .map((warehouse) => (
                <Option
                  key={warehouse.warehouse_id}
                  value={warehouse.warehouse_id!}
                >
                  {warehouse.warehouse_name}
                </Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Customer"
          name={'customer_id'}
          rules={[
            { required: true, message: 'Please select customer!' },
          ]}
        >
          <Select
            onChange={handleCustomerChange}
            allowClear={true}
            placeholder={'Select customer'}
            value={data.customer_id}
          >
            {customerList.filter((customer) => customer.region_id === selectedRegion).map((customer) => (
              <Option
                key={customer.customer_id}
                value={customer.customer_id!}
              >
                {customer.customer_name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Schedule"
          name={'schedule_id'}
          rules={[
            { required: true, message: 'Please select schedule!' },
          ]}
        >
          <Select
            onChange={handleScheduleChange}
            allowClear={true}
            placeholder={'Select schedule'}
            value={data.schedule_id}
          >
            {scheduleList.map((schedule) => (
              <Option
                key={schedule.schedule_id}
                value={schedule.schedule_id!}
              >
                {schedule.schedule_name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Cycle"
          name={'cycle_id'}
          rules={[
            { required: true, message: 'Please select cycle!' },
          ]}
        >
          <Select
            onChange={handleCycleChange}
            allowClear={true}
            placeholder={'Select cycle'}
            value={data.cycle_id}
          >
            {cycleList.map((cycle) => (
              <Option
                key={cycle.cycle_id}
                value={cycle.cycle_id!}
              >
                {cycle.cycle_name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Days open"
          name={'days_open'}
          style={{ margin: 0 }}
          rules={[
            { required: true, message: 'Please select days open!' },
          ]}
          initialValue={form.getFieldValue('days_open') || defaultOpenDaysOptions}
        >
          <Checkbox.Group options={optionsOpenDays}/>
        </Form.Item>
        <div style={{ marginBottom: 24 }}>
          <Text style={{ fontSize: 12 }}>
            * Required to prevent scheduling when location is closed. Also required to ensure we can
            schedule correctly when there is no device reporting.
          </Text>
        </div>
        <Form.Item
          name={'exception_period'}
        >
          <LocationExceptionPeriod
            handleExceptionPeriod={handleExceptionPeriod}
            handleExceptionPeriodForecast={handleExceptionPeriodForecast}
            exceptionsPeriodOptions={exceptionsPeriodOptions}
            handleExceptionPeriodNotes={handleExceptionPeriodNotes}
            listExceptionPeriod={listExceptionPeriod}
            handleAddExceptionPeriod={handleAddExceptionPeriod}
          />
        </Form.Item>
        <Form.Item
          label="Name"
          name={'location_name'}
          rules={[
            { required: true, message: 'Please input location name!' },
          ]}
          initialValue={data.location_name}
        >
          <Input/>
        </Form.Item>
        <Form.Item
          label="Country"
          name={'country'}
          initialValue={data.country}
        >
          <Input/>
        </Form.Item>
        <Form.Item
          label="City"
          name={'city'}
          initialValue={data.city}
        >
          <Input/>
        </Form.Item>
        <Form.Item
          label="State Province"
          name={'state_province'}
          initialValue={data.state_province}
        >
          <Input/>
        </Form.Item>
        <Form.Item
          label="Zip"
          name={'zip'}
          initialValue={data.zip}
        >
          <Input/>
        </Form.Item>
        <Form.Item
          label="Address 1"
          name={'address1'}
          initialValue={data.address1}
        >
          <Input/>
        </Form.Item>
        <Form.Item
          label="Address 2"
          name={'address2'}
          initialValue={data.address2}
        >
          <Input/>
        </Form.Item>
        <Form.Item
          label="Partner location id"
          name={'partner_location_id'}
          initialValue={data.partner_location_id}
        >
          <Input/>
        </Form.Item>
      </Form>
    </Modal>
  );
};

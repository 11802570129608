import * as React from 'react';
import { UploadOutlined } from '@ant-design/icons/lib';
import {
  Button, message, Upload,
} from 'antd';
import {
  Dispatch, SetStateAction, useEffect, useState,
} from 'react';
import { UploadProps } from 'antd/lib/upload';
import { UploadFile } from 'antd/lib/upload/interface';
import { v4 as uuidv4 } from 'uuid';
import { uploadFileApi } from '../uploadFile';
import { ResponseUploadFileType } from '../../../common/types';

const allowFormat = ['image/png', 'image/jpeg'];

type UploadFilesProps = {
  dirName: string;
  setDataFile: Dispatch<SetStateAction<ResponseUploadFileType | undefined>>;
  setSpin?: Dispatch<SetStateAction<boolean>>;
};

export const FileUpload: React.FC<UploadFilesProps> = (props) => {
  const [fileList, setFileList] = useState([]);

  const { setSpin, dirName, setDataFile } = props;

  const handleUpload = async () => {
    const formData = new FormData();
    fileList.forEach((file: any) => {
      console.log(file);
      formData.append('files[]', file);
    });

    console.log(formData);
    if (setSpin) setSpin(true);

    const newFileName = `${uuidv4()}.${(fileList[0] as any).name.split('.')[1]}`;
    const linkSave = `${process.env.REACT_APP_S3_STORAGE_URL}/${dirName}/${newFileName}`;
    if (await uploadFileApi(fileList[0], linkSave)) {
      console.log('result uploadFileForm');
      setDataFile({
        key: `${dirName}/${newFileName}`,
        location: linkSave,
      });
    }

    if (setSpin) setSpin(false);
    setFileList([]);
  };

  useEffect(() => {
    if (fileList.length) {
      setTimeout(async () => {
        await handleUpload();
      }, 0);
    }
  }, [fileList]);

  const propsUpload: UploadProps = {
    onRemove: (file: UploadFile<any>) => {
      const index = fileList.indexOf(file as never);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file: UploadFile<any>) => {
      if (!allowFormat.includes(file.type!)) {
        message.error(`Format file ${file.name} is not correct. Only png or jpeg files.`);
      }

      setFileList([file as never]);

      return false;
    },
    fileList,
  };

  return <>
    <Upload {...propsUpload} maxCount={1} listType="text" multiple={false}>
      <Button icon={<UploadOutlined />}>Select File</Button>
    </Upload>
  </>;
};

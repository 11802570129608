import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import {
  Space, Spin,
  Table, Typography,
  Button, Modal, Form, Input, Transfer, Col, Row, Radio, RadioChangeEvent, message, Collapse, Select,
} from 'antd';
import axios from 'axios';
import { Translate } from 'i18n-next-tools';
import { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { useDispatch, useSelector } from 'react-redux';
import { UserType } from '@aws-sdk/client-cognito-identity-provider';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons/lib';
import { API } from '../constsAPI';
import { PAGINATION_TABLE } from '../consts';
import { TableSearch } from '../components/TableSearch';
import { useAlertMessage } from '../hooks/useAlertMessage';
import { useDataCommon } from '../DataCommonProvider';
import { DirectionType } from '../../../common/typesEditor';
import { fetchRegions, fetchRegionsByOperators } from '../store/slices/regionSlice';
import { AppDispatch, RootState } from '../store';
import { CustomerType, RegionType, RouteType } from '../../../common/entityTypes';
import { fetchCustomersByRegions } from '../store/slices/customerSlice';
import {
  customersQuery, decodeToken, permissionsList, regionsQuery, ROLE_ADMIN, ROLE_OPERATOR, roles,
} from '../common';
import { fetchRoutes } from '../store/slices/routeSlice';
import { ResponseLoginType } from '../../../common/types';

const { Title } = Typography;
const { Panel } = Collapse;

type UserTableType = {
  email: string,
  name: string,
  family_name: string,
  is_verified: boolean,
  operator: string, // { [key: number]: OperatorType }
  customer: string, // { [key: number]: OperatorType }
  role: string, // { [key: number]: OperatorType }
  permissions: string, // { [key: number]: OperatorType }
  status: string, // { [key: number]: OperatorType }
  route: string, // { [key: number]: OperatorType }
};

type UserFromType = {
  email: string,
  admin: boolean,
  operator_id: string[],
};

const modalTitleSign = {
  ADD: 'Add new user',
  EDIT: 'Edit user',
};

const listStyleTransfer = {
  width: 'calc(50% - 30px)',
  minWidth: 'calc(50% - 30px)',
  height: 250,
};

const token = localStorage.getItem('IdToken') || '';
const decodedToken = decodeToken(token);

export const UsersPage: React.FC = () => {
  const [form] = Form.useForm();
  const dataCommon = useDataCommon();
  const t = Translate('UsersPage');
  const dispatch = useDispatch<AppDispatch>();

  const [role, setRole] = useState<string | undefined>(decodedToken['custom:Role']);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [msgError, setMsgError] = useState('');
  const [msgOk, setMsgOk] = useState('');
  const [modalTitle, setModalTitle] = useState<string>(modalTitleSign.ADD);
  const [currentListTransferOperator, setCurrentListTransferOperator] = useState<string[]>([]);
  const [selectedTransferOperator, setSelectedTransferOperator] = useState<string[]>([]);

  const [currentListTransferCustomer, setCurrentListTransferCustomer] = useState<string[]>([]);
  const [selectedTransferCustomer, setSelectedTransferCustomer] = useState<string[]>([]);

  const [currentListTransferRoute, setCurrentListTransferRoute] = useState<string[]>([]);
  const [selectedTransferRoute, setSelectedTransferRoute] = useState<string[]>([]);

  const [paginationTable, setPaginationTable] = useState<TablePaginationConfig>(PAGINATION_TABLE);
  const [loadingTable] = useState<boolean>(false);

  const [spin, setSpin] = useState(false);
  const [dataTable, setDataTable] = useState<UserTableType[]>([]);
  const [currentUser, setCurrentUser] = useState<UserTableType>();
  const [operatorDisable, setOperatorDisable] = useState(true);
  const [customerDisable, setCustomerDisable] = useState(true);
  const [routeDisable, setRouteDisable] = useState(true);

  const customerListByRegions = useSelector<RootState, CustomerType[]>((store) => store.customer.customerList);
  const regionListByOperator = useSelector<RootState, RegionType[]>((store) => store.region.regionListByOperator);
  const regionList = useSelector<RootState, RegionType[]>((store) => store.region.regionList);
  const routeList = useSelector<RootState, RouteType[]>((store) => store.route.routeList);
  const staticRouteList = useRef<RouteType[]>([]);

  useEffect(() => {
    if (routeList.length > staticRouteList.current.length) {
      staticRouteList.current = [...routeList];
    }
  }, [routeList]);

  useEffect(() => {
    if (role === roles.ADMIN) {
      setOperatorDisable(true);
      setCustomerDisable(true);
      setRouteDisable(true);
      setCurrentListTransferCustomer([]);
      setSelectedTransferCustomer([]);
      setCurrentListTransferOperator([]);
      setSelectedTransferOperator([]);
      setCurrentListTransferRoute([]);
      setSelectedTransferRoute([]);
    }

    if (role === roles.OPERATOR) {
      setOperatorDisable(false);
      setCustomerDisable(true);
      setRouteDisable(true);
      setCurrentListTransferCustomer([]);
      setSelectedTransferCustomer([]);
      setCurrentListTransferRoute([]);
      setSelectedTransferRoute([]);
    }

    if (role === roles.CUSTOMER) {
      setOperatorDisable(false);
      setCustomerDisable(false);
      setRouteDisable(true);
      setCurrentListTransferRoute([]);
      setSelectedTransferRoute([]);
    }

    if (role === roles.DRIVER) {
      setOperatorDisable(false);
      setCustomerDisable(true);
      setRouteDisable(false);
      setCurrentListTransferCustomer([]);
      setSelectedTransferCustomer([]);
    }
  }, [role]);

  useEffect(() => {
    if (!regionList.length) dispatch(fetchRegions());
    if (!routeList.length) dispatch(fetchRoutes());
  }, []);

  useEffect(() => {
    if (regionList.length) {
      dispatch(fetchCustomersByRegions(customersQuery(regionList).join('&')));
    }
  }, [regionList]);

  useEffect(() => {
    (async () => {
      if (currentListTransferOperator.length) {
        setSpin(true);
        await dispatch(fetchRegionsByOperators(regionsQuery(currentListTransferOperator).join('&')));
        await dispatch(fetchRoutes(regionsQuery(currentListTransferOperator).join('&')));
        setSpin(false);
      }
    })();
  }, [currentListTransferOperator]);

  const showModal = useCallback(() => {
    setMsgOk('');
    setMsgError('');
    setSpin(false);
    setIsModalVisible(true);
  }, []);

  const getValueFromAttribute = useCallback((userData: UserType, attributeName: string) => {
    const attribute = userData.Attributes!.filter((item) => item.Name === attributeName);
    return attribute[0]?.Value || '';
  }, []);

  const getDataDB = useCallback(async () => {
    // await axios.post<UserType[]>(`${API.URL_USER}/get`, {
    //   token: localStorage.getItem('AccessToken'),
    // });
    const rawListUsers = (await axios.get<UserType[]>(`${API.URL_USER}`)).data;

    const newDataTable = (rawListUsers).map((elem) => ({
      key: elem.Username,
      name: getValueFromAttribute(elem, 'name'),
      family_name: getValueFromAttribute(elem, 'family_name'),
      email: getValueFromAttribute(elem, 'email'),
      is_verified: getValueFromAttribute(elem, 'email_verified') as any as boolean,
      operator: getValueFromAttribute(elem, 'custom:Operators'),
      customer: getValueFromAttribute(elem, 'custom:Customers'),
      role: getValueFromAttribute(elem, 'custom:Role'),
      permissions: getValueFromAttribute(elem, 'custom:Permissions'),
      status: getValueFromAttribute(elem, 'custom:Status'),
      route: getValueFromAttribute(elem, 'custom:Routes'),
    })) as UserTableType[];

    setDataTable(newDataTable);
    setSpin(false);
  }, []);

  const handleDeleteUser = async (email: string) => {
    setSpin(true);
    try {
      await axios.post<UserType>(`${API.URL_USER}/delete`, { email });
      await getDataDB();
    } catch (error) {
      console.log(error);
    }
    setSpin(false);
  };

  const columns: ColumnsType<UserTableType> = [
    {
      title: 'Email address',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'First Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Last Name',
      dataIndex: 'family_name',
      key: 'family_name',
    },
    {
      title: 'Email verified',
      dataIndex: 'is_verified',
      key: 'is_verified',
      sorter: (a: any, b: any) => (a.is_verified > b.is_verified ? 1 : -1),
      render: (value: string) => (value === 'true'
        ? <CheckCircleOutlined style={{ fontSize: 20, color: '#87d068', fontWeight: 'bold' }}/>
        : <CloseCircleOutlined style={{ fontSize: 20, color: '#f50', fontWeight: 'bold' }}/>),
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
    },
    {
      title: 'Operator',
      dataIndex: 'operator',
      key: 'operator',
      render: (value: string) => (
        value === '*' ? value
          : value.split(',').map((elemOperatorId) => dataCommon.listOperator.filter((elem) => elem.operator_id === +elemOperatorId as any as number)[0]?.operator_code).join(', ')
      ),
    },
    {
      title: 'Customer',
      dataIndex: 'customer',
      key: 'customer',
      render: (value: string) => (
        value === '*' ? value
          : value.split(',').map((elemCustomerId) => customerListByRegions.filter((elem) => elem.customer_id === +elemCustomerId as any as number)[0]?.customer_name).join(', ')
      ),
    },
    {
      title: 'Route',
      dataIndex: 'route',
      key: 'route',
      render: (value: string) => {
        console.log(value, staticRouteList.current);
        return (
          value === '*' ? value
            : value.split(',').map((elemRouteId) => staticRouteList.current.filter((elem) => elem.route_id === +elemRouteId as any as number)[0]?.route_name).join(', ')
        );
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (value: string) => (value === 'DELETED' ? 'DELETED' : 'ACTIVE'),
    },
    {
      title: 'Action',
      dataIndex: 'key',
      key: 'key',
      render: (value, row) => <>
        <Button
          className="user-action-btn user-action-btn--edit"
          onClick={() => {
            showModal();
            setModalTitle(modalTitleSign.EDIT);
          }}
        >
          Edit
        </Button>
        <Button
          className="user-action-btn user-action-btn--delete"
          onClick={() => handleDeleteUser(row.email)}
        >
          Delete
        </Button>
      </>,
    },
  ];

  useEffect(() => {
    (async () => {
      setSpin(true);
      await getDataDB();
    })();
  }, []);

  const handleTableChange = useCallback((pagination, filters, sorter) => {
    console.log(pagination, filters, sorter);
    setPaginationTable(pagination);
  }, []);

  const getColumnSearchProps = useCallback((callback: (dataIndex: any) => {}) => {
    console.log(callback);
  }, []);

  const handleCancel = useCallback(() => {
    setIsModalVisible(false);
    setCurrentUser(undefined);
  }, []);

  const onFinish = useCallback(async (values: UserFromType) => {
    setMsgOk('');
    setMsgError('');

    const dataPost = {
      ...values,
      admin: role === roles.ADMIN,
      operator_id: currentListTransferOperator,
      customer_id: currentListTransferCustomer,
      route_id: currentListTransferRoute,
    };

    console.log('>>>>>>>>..', dataPost, role);
    setSpin(true);
    let result = null;
    try {
      if (modalTitle === modalTitleSign.ADD) {
        result = (await axios.post<ResponseLoginType>(`${API.URL_USER}/add`, dataPost)).data;
      }

      if (modalTitle === modalTitleSign.EDIT) {
        result = (await axios.post<ResponseLoginType>(`${API.URL_USER}/update`, dataPost)).data;
      }

      if (result) {
        switch (result.status) {
          case 200:
            message.success('Saved!');
            await getDataDB();
            setSpin(false);
            handleCancel();
            break;
          case 400:
            setMsgError(result.message);
            break;
          default:
            break;
        }
      }
    } catch (e) {
      message.error('Something wrong!');
    }

    console.log(result);
  }, [modalTitle, currentListTransferOperator, currentListTransferCustomer, currentListTransferRoute]);

  const onFinishFailed = useCallback((errorInfo: any) => {
    console.log('Failed:', errorInfo);
  }, []);

  const renderMessageError = useAlertMessage('error', msgError);
  const renderMessageOk = useAlertMessage('success', msgOk);

  const handleChangeTransfer = (nextTargetKeys: string[], direction: DirectionType, moveKeys: string[]) => {
    console.log(nextTargetKeys, direction, moveKeys);
    setCurrentListTransferOperator(nextTargetKeys);
  };

  const handleChangeTransferCustomer = (nextTargetKeys: string[], direction: DirectionType, moveKeys: string[]) => {
    console.log(nextTargetKeys, direction, moveKeys);
    setCurrentListTransferCustomer(nextTargetKeys);
  };

  const handleSelectChangeTransfer = (sourceSelectedKeys: string[], targetSelectedKeys: any[]) => {
    console.log([...sourceSelectedKeys, ...targetSelectedKeys]);
    setSelectedTransferOperator([...sourceSelectedKeys, ...targetSelectedKeys]);
  };

  const handleSelectChangeTransferCustomer = (sourceSelectedKeys: string[], targetSelectedKeys: any[]) => {
    console.log([...sourceSelectedKeys, ...targetSelectedKeys]);
    setSelectedTransferCustomer([...sourceSelectedKeys, ...targetSelectedKeys]);
  };

  const handleChangeTransferRoute = (nextTargetKeys: string[], direction: DirectionType, moveKeys: string[]) => {
    console.log(nextTargetKeys, direction, moveKeys);
    setCurrentListTransferRoute(nextTargetKeys);
  };

  const handleSelectChangeRoute = (sourceSelectedKeys: string[], targetSelectedKeys: any[]) => {
    console.log([...sourceSelectedKeys, ...targetSelectedKeys]);
    setSelectedTransferRoute([...sourceSelectedKeys, ...targetSelectedKeys]);
  };

  const handleRole = (e: RadioChangeEvent) => {
    setRole(e.target.value);
  };

  const splitDataRecord = (condition: any[], data: string) => {
    const splitData = data !== '' ? data.split(',') : [];
    return data !== '*' && condition.length ? splitData : [];
  };

  const getRadioButtonAdmin = () => {
    if (decodedToken['custom:Role'] === ROLE_ADMIN) {
      return <Radio.Button key={roles.ADMIN} value={roles.ADMIN}>{roles.ADMIN}</Radio.Button>;
    }

    return null;
  };

  const getRadioButtonOperator = () => {
    if (decodedToken['custom:Role'] === ROLE_ADMIN || decodedToken['custom:Role'] === ROLE_OPERATOR) {
      return <Radio.Button key={roles.OPERATOR} value={roles.OPERATOR}>{roles.OPERATOR}</Radio.Button>;
    }

    return null;
  };

  return (
    <>
      <TableSearch columnSearchProps={getColumnSearchProps}/>
      <Space direction={'horizontal'} align={'center'} style={{ justifyContent: 'space-between', width: '100%' }}>
        <Title level={3}>{t.k1('Users List')}</Title>
        <Button type="primary" onClick={() => {
          form.setFieldsValue({
            email: '',
            name: '',
            family_name: '',
            role,
            permissions: [],
            admin: false,
            operator_id: '',
            customer_id: '',
            route_id: '',
          });

          showModal();
          setModalTitle(modalTitleSign.ADD);
        }}>{t.k2('Add')}</Button>
        <Modal
          title={modalTitle}
          visible={isModalVisible}
          onCancel={handleCancel}
          width={'60%'}
          footer={[
            <Button key="cancel" onClick={handleCancel}>
              Cancel
            </Button>,
            <Button form="addUser" key="submit" type="primary" htmlType="submit">
              Save
            </Button>,
          ]}>
          <Spin spinning={spin} tip={'please wait ...'}>
            {[
              renderMessageError,
              renderMessageOk,
            ]}

            <Form
              form={form}
              name="addUser"
              layout="vertical"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="nope"
            >
              <Row>
                <Col span={24}>
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item
                          label="Email"
                          name={'email'}
                          rules={[
                            { required: true, message: 'Please input your email!' },
                            {
                              type: 'email',
                              message: 'The input is not valid E-mail!',
                            },
                          ]}
                          initialValue={currentUser ? currentUser.email : undefined}
                      >
                        <Input/>
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                          label="First Name"
                          name={'name'}
                          rules={[
                            { required: true, message: 'Please input your first name!' },
                            {
                              type: 'string',
                            },
                          ]}
                          initialValue={currentUser ? currentUser.name : undefined}
                      >
                        <Input/>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={12} style={{ paddingTop: '30px' }}>
                      <Form.Item
                          name={'role'}
                      >
                        <Radio.Group onChange={handleRole} value={role} buttonStyle="solid" style={{ width: '100%' }}>
                          {
                            [
                              getRadioButtonAdmin(),
                              getRadioButtonOperator(),
                            ]
                          }
                          <Radio.Button value={roles.CUSTOMER}>{roles.CUSTOMER}</Radio.Button>
                          <Radio.Button value={roles.DRIVER}>{roles.DRIVER}</Radio.Button>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                          label="Last Name"
                          name={'family_name'}
                          rules={[
                            { required: true, message: 'Please input your last name!' },
                            {
                              type: 'string',
                            },
                          ]}
                          initialValue={currentUser ? currentUser.family_name : undefined}
                      >
                        <Input/>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item
                          label={'Permission'}
                          name={'permissions'}
                          style={{ width: '100%' }}
                      >
                        <Select
                            mode="multiple"
                            allowClear
                            style={{ width: '100%' }}
                            placeholder="Please select"
                            // defaultValue={['a10', 'c12']}
                            // onChange={handlePermissions}
                            options={permissionsList.map((permission) => ({ label: permission, value: permission }))}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  {
                    !operatorDisable || !customerDisable || !routeDisable
                      ? <Collapse defaultActiveKey="1">
                          {
                            !operatorDisable
                              ? <Panel header="Operators" key="1">
                                  <Form.Item
                                      name={'operator_id'}
                                      style={{ width: '100%' }}
                                  >
                                    <Transfer
                                        style={{ width: '100%', margin: '10px' }}
                                        dataSource={dataCommon.listTransferOperator}
                                        titles={['Source', 'Target']}
                                        targetKeys={currentListTransferOperator}
                                        selectedKeys={selectedTransferOperator}
                                        showSearch
                                        listStyle={listStyleTransfer}
                                        onChange={handleChangeTransfer}
                                        onSelectChange={handleSelectChangeTransfer}
                                        render={(item) => item.title}
                                        oneWay
                                        disabled={operatorDisable}
                                    />
                                  </Form.Item>
                                </Panel>
                              : null
                          }
                          {
                            !customerDisable
                              ? <Panel header="Customers" key="2">
                                  <Form.Item
                                      name={'customer_id'}
                                      style={{ width: '100%' }}
                                  >
                                    <Transfer
                                        style={{ width: '100%', margin: '10px' }}
                                        dataSource={currentListTransferOperator.length ? customerListByRegions
                                          .filter((customer) => regionListByOperator.find((region) => region.region_id === customer.region_id))
                                          .map((customer) => (
                                            {
                                              key: `${customer.customer_id}`,
                                              title: customer.customer_name,
                                            }
                                          )) : []}
                                        titles={['Source', 'Target']}
                                        targetKeys={currentListTransferCustomer}
                                        selectedKeys={selectedTransferCustomer}
                                        showSearch
                                        listStyle={listStyleTransfer}
                                        onChange={handleChangeTransferCustomer}
                                        onSelectChange={handleSelectChangeTransferCustomer}
                                        render={(item) => item.title}
                                        oneWay
                                        disabled={customerDisable}
                                    />
                                  </Form.Item>
                                </Panel>
                              : null
                          }
                          {
                            !routeDisable
                              ? <Panel header="Routes" key="3">
                                  <Form.Item
                                      name={'route_id'}
                                      style={{ width: '100%' }}
                                  >
                                    <Transfer
                                        style={{ width: '100%', margin: '10px' }}
                                        dataSource={currentListTransferOperator.length ? routeList
                                          .map((route) => (
                                            {
                                              key: `${route.route_id}`,
                                              title: route.route_name,
                                            }
                                          )) : []}
                                        titles={['Source', 'Target']}
                                        targetKeys={currentListTransferRoute}
                                        selectedKeys={selectedTransferRoute}
                                        showSearch
                                        listStyle={listStyleTransfer}
                                        onChange={handleChangeTransferRoute}
                                        onSelectChange={handleSelectChangeRoute}
                                        render={(item) => item.title}
                                        oneWay
                                        disabled={routeDisable}
                                    />
                                  </Form.Item>
                                </Panel>
                              : null
                          }
                        </Collapse>
                      : null
                  }
                </Col>
              </Row>
            </Form>
          </Spin>
        </Modal>
      </Space>
      <Spin spinning={spin} tip={'please wait ...'}>
        <Table<UserTableType>
          columns={columns}
          className={'click_row'}
          dataSource={dataTable}
          pagination={paginationTable}
          onChange={handleTableChange}
          loading={loadingTable}
          onRow={(record, rowIndex) => ({
            onClick: (event: any) => {
              console.log(event, record, rowIndex);
              form.setFieldsValue({
                email: record.email,
                name: record.name,
                family_name: record.family_name,
                role: record.role,
                permissions: record.permissions ? record.permissions.split(',') : [],
                admin: record.is_verified,
                operator_id: '',
                customer_id: '',
                route_id: '',
              });

              setCurrentListTransferOperator(splitDataRecord(dataCommon.listOperator, record.operator));
              setCurrentListTransferCustomer(splitDataRecord(customerListByRegions, record.customer));
              setCurrentListTransferRoute(splitDataRecord(routeList, record.route));
              setCurrentUser(record);
              setRole(record.role);
            },
          })}
        />
      </Spin>
    </>
  );
};

import * as React from 'react';
import { Tooltip } from 'antd';
import { SIGNAL } from '../constsSIGNAL';

type SignalProps = {
  rssi?: number,
  isUnitWifi?: boolean,
  isClop?: boolean
};

export const Signal: React.FC<SignalProps> = (props) => {
  if (!props.rssi || props.rssi < -1000) return <span> </span>;

  const rssi = -props.rssi;

  const iconName = () => {
    let icon = SIGNAL.ICON_POOR;

    if (rssi <= SIGNAL.SIGNAL_80) {
      icon = SIGNAL.ICON_EXCELLENT;
    } else if (rssi > SIGNAL.SIGNAL_80 && rssi <= SIGNAL.SIGNAL_90) {
      icon = SIGNAL.ICON_GOOD;
    } else if (rssi > SIGNAL.SIGNAL_90 && rssi <= SIGNAL.SIGNAL_100) {
      icon = SIGNAL.ICON_POOR;
    } else if (rssi > SIGNAL.SIGNAL_100) {
      icon = SIGNAL.ICON_FAIR;
    } else {
      icon = SIGNAL.ICON_ZERO;
    }
    return icon;
  };

  const getTitle = () => {
    let title = props.isUnitWifi ? 'wifi rssi' : 'cell rssi';

    if (props.isClop) {
      title += `: ${props.rssi}`;
    }
    return title;
  };

  return <Tooltip title={props.rssi}>
    <span title={getTitle()} className={`signalStrengthIcon ${iconName()}`}> </span>
  </Tooltip>;
};

import React from 'react';
import {
  Button, Form, Input, Modal, Select, Spin,
} from 'antd';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { LocationType } from '../../../../../common/entityTypes';

const { Option } = Select;

export const RouteModal = (props: any) => {
  const [form] = Form.useForm();
  const {
    spinner,
    isRouteModalVisible,
    handleCancelRouteModal,
    handleRouteCreate,
    selectedWarehouse,
    handleWarehouse,
    warehouseList,
  } = props;

  const locationList = useSelector<RootState, LocationType[]>((store) => store.location.locationList);

  return (
    <Modal
      title="Add route"
      visible={isRouteModalVisible}
      onCancel={handleCancelRouteModal}
      footer={[
        <Button key="cancel" onClick={handleCancelRouteModal}>
          Cancel
        </Button>,
        <Button form="addRoute" key="submit" type="primary" htmlType="submit">
          Save
        </Button>,
      ]}>
      <Spin spinning={spinner} tip={'please wait ...'}>
        <Form
          form={form}
          name="addRoute"
          layout="vertical"
          onFinish={handleRouteCreate}
          autoComplete="nope"
        >
          <Form.Item
            label="Route name"
            name={'route_name'}
            rules={[
              { required: true, message: 'Please input route name!' },
              {
                type: 'string',
                message: 'The input is not valid name!',
              },
            ]}
            initialValue={form.getFieldValue('route_name')}
          >
            <Input/>
          </Form.Item>
          <Form.Item
            label="Warehouse"
            name={'warehouse_id'}
            rules={[
              { required: true, message: 'Please select warehouse!' },
              {
                type: 'number',
                message: 'Select warehouse for route',
              },
            ]}
            initialValue={selectedWarehouse}
          >
            <Select
              onChange={handleWarehouse}
              allowClear
              placeholder={'Select warehouse'}
              value={selectedWarehouse}
            >
              {warehouseList.map((warehouse: any) => (
                <Option
                  key={warehouse.warehouse_id}
                  value={warehouse.warehouse_id!}
                >
                  {warehouse.warehouse_name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          {!!locationList.length && (
            <Form.Item
              label="Locations"
              name={'location_ids'}
              rules={[
                { required: true, message: 'Please select locations!' },
              ]}
              initialValue={locationList.map((location) => location.location_id)}
            >
              <Select
                mode="tags"
                size={'middle'}
                placeholder="Select location for this route"
                options={locationList.map((location) => ({
                  label: location.location_name!,
                  value: location.location_id!,
                }))}
              />
            </Form.Item>
          )}
        </Form>
      </Spin>
    </Modal>
  );
};

import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import {
  Button, Col, Form, Input, InputNumber, message, Modal, Row, Space, Spin, Transfer, Typography,
  Checkbox,
} from 'antd';
import { Translate } from 'i18n-next-tools';
import axios from 'axios';
import { TransferDirection } from 'antd/lib/transfer';
import { FileUpload } from '../components/FileUpload';
import { FloorplanSvgEditor } from '../components/svg/FloorplanSvgEditor';
import {
  FloorplanType, FootprintDeviceType, FootprintType, LocationType,
} from '../../../common/entityTypes';
import { API } from '../constsAPI';
import { SimpleSelect } from '../components/SimpleSelect';
import { ResponseUploadFileType } from '../../../common/types';
import { DirectionType, TransferType } from '../../../common/typesEditor';
import { deleteFile } from '../uploadFile';
import { FootprintList } from '../components/FootprintList';

const { Title } = Typography;

const DIR_NAME_UPLOAD = 'floorplan';

const getDataFootprint = async (location_id: string) => {
  if (!location_id) return [];

  const dataDB = (await axios.get<FootprintDeviceType[]>(`${API.URL_AURORA_DB}/footprint_list?location=${location_id}`)).data;

  return (dataDB).map((elem) => {
    const modifyElem = elem;
    modifyElem.key = elem.footprint_id;
    modifyElem.data_marker = elem.data_marker;
    return modifyElem;
  });
};

const getDataLocation = async () => (await axios.get<LocationType[]>(`${API.URL_AURORA_DB}/location_list`)).data;
const getFloorplan = async () => (await axios.get<FloorplanType[]>(`${API.URL_AURORA_DB}/floorplan_list`)).data;

export const FloorplanPage: React.FC = () => {
  const [form] = Form.useForm();
  const [spin, setSpin] = useState(false);
  const [floorplan, setFloorplan] = useState<FloorplanType>();
  const [footprint, setFootprint] = useState<FootprintType>();
  const [listFloorplan, setListFloorplan] = useState<FloorplanType[]>([]);
  const [currentDataUploadFile, setCurrentDataUploadFile] = useState<ResponseUploadFileType>();
  const [listFootprint, setListFootprint] = useState<FootprintDeviceType[]>([]);
  const [listTransferFootprint, setListTransferFootprint] = useState<TransferType[]>([]);
  const [listLocation, setListLocation] = useState<LocationType[]>([]);
  const [currentLocation, setCurrentLocation] = useState<LocationType>();
  const [followChosenFootprint, setActivePositionFootprint] = useState(false);
  const [highlightFootprint, setHighlightFootprint] = useState(true);
  const [currentListFootprint, setCurrentListFootprint] = useState<FootprintDeviceType[]>([]);
  const inputPictureBlobName = useRef<Input>(null);

  const [currentListTransferFootprint, setCurrentListTransferFootprint] = useState<string[]>([]);
  const [selectedTransferFootprint, setSelectedTransferFootprint] = useState<string[]>([]);
  const [keyDisabledSave, setKeyDisabledSave] = useState(true);
  const [isMoveMarkers, setIsMoveMarkers] = useState(false);

  useEffect(() => {
    (async () => {
      setSpin(true);
      if (currentLocation) {
        const newListFootprint = await getDataFootprint(currentLocation.location_id as any as string);
        setListFootprint(newListFootprint);
        setCurrentListFootprint([]);
      }
      setSpin(false);
    })();
  }, [currentLocation]);

  useEffect(() => {
    setSpin(true);
    // setSearchTextFootprint(undefined);
    if (floorplan && floorplan.floorplan_id && listFootprint) {
      const installListFootprint = listFootprint.filter((item) => item.floorplan_id === floorplan.floorplan_id);

      setCurrentListFootprint(installListFootprint);
    }
    setSpin(false);
  }, [floorplan]);

  useEffect(() => {
    (async () => {
      setSpin(true);
      setListLocation(await getDataLocation());
      setListFloorplan((await getFloorplan()).sort((a, b) => (a.name > b.name ? 1 : -1)));
      setSpin(false);
    })();
  }, []);

  const checkForm = useCallback((resultObject: FloorplanType) => {
    let result = true;
    if (!resultObject.list_footprint || !resultObject.list_footprint.length) {
      message.error('You need select one or any item from list footprint!');
      result = false;
    }

    if (!resultObject.floor_number) {
      message.error('Field "Floor number" can not be empty!');
      result = false;
    }

    if (!resultObject.compass && resultObject.compass !== 0) {
      message.error('Field "Compass" can not be empty!');
      result = false;
    }

    if (!resultObject.scale) {
      message.error('Field "Scale" can not be empty!');
      result = false;
    }

    if (!resultObject.name) {
      message.error('Field "Name" can not be empty!');
      result = false;
    }

    if (resultObject.picture_blob_name === '') {
      message.error('Field "Pict name" can not be empty! Please, select pict for floor plan');
      result = false;
    }

    return result;
  }, []);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const closeModal = useCallback(() => {
    setIsModalVisible(false);
  }, []);

  const onClickMoveMarkers = useCallback(async () => {
    setIsMoveMarkers(true);
  }, []);

  const onClickCancel = useCallback(async () => {
    console.log('cancel', currentLocation);
    setSpin(true);

    const newListFootprint = await getDataFootprint(currentLocation!.location_id as any as string);
    setListFootprint(newListFootprint);
    setCurrentListFootprint([]);

    const installListFootprint = newListFootprint.filter((item) => item.floorplan_id === floorplan!.floorplan_id);
    setCurrentListFootprint(installListFootprint);

    setKeyDisabledSave(true);
    setSpin(false);
  }, [currentLocation, floorplan]);

  const onClickSave = useCallback(async () => {
    const floorplanForSave: FloorplanType = {
      list_footprint: currentListFootprint,
      location_id: currentLocation!.location_id!,
      name: form.getFieldValue('name'),
      floor_number: form.getFieldValue('floor_number'),
      compass: form.getFieldValue('compass'),
      scale: form.getFieldValue('scale'),
      picture_blob_name: inputPictureBlobName.current!.input.value,
      floorplan_id: floorplan ? floorplan.floorplan_id : undefined,
    };

    if (checkForm(floorplanForSave)) {
      setSpin(true);

      try {
        floorplanForSave.floorplan_id = (await axios.post<FloorplanType>(`${API.URL_AURORA_DB}/set_floorplan`, floorplanForSave)).data.floorplan_id;
        currentListFootprint.map((unitFootprint) => {
          const newUnitFootprint = unitFootprint;
          newUnitFootprint.floorplan_id = floorplanForSave.floorplan_id; return unitFootprint;
        });
        setFloorplan(floorplanForSave);

        const filterFloorplan = listFloorplan.filter((floorplane) => floorplane.floorplan_id !== floorplanForSave.floorplan_id);
        setListFloorplan(filterFloorplan.concat(floorplanForSave).sort((a, b) => (a.name > b.name ? 1 : -1)));

        closeModal();
        setKeyDisabledSave(true);
      } catch (e) {
        message.error('Cannot save same problem on server!');
        console.log(e);
      }

      setSpin(false);
    }
  }, [floorplan, currentLocation, currentListFootprint]);

  useEffect(() => {
    if (currentDataUploadFile) {
      const namePictureBlob = currentDataUploadFile.key.replace(`${DIR_NAME_UPLOAD}/`, '');
      inputPictureBlobName.current!.setValue(namePictureBlob);
    }
  }, [currentDataUploadFile]);

  const clearData = useCallback(() => {
    setSpin(true);
    setKeyDisabledSave(true);
    setCurrentLocation(undefined);
    setFloorplan(undefined);
    setCurrentDataUploadFile(undefined);
    setCurrentListFootprint([]);
    form.resetFields();
    setSpin(false);
  }, []);

  useEffect(() => {
    if (!floorplan) {
      clearData();
      return;
    }

    const newLocation = listLocation.filter((location) => location.location_id === floorplan.location_id)[0];
    setCurrentLocation(newLocation);

    form.setFieldsValue({
      location_id: floorplan.location_id, name: floorplan.name, floor_number: floorplan.floor_number, compass: floorplan.compass, scale: floorplan.scale,
    });

    setCurrentDataUploadFile({
      key: `${DIR_NAME_UPLOAD}/${floorplan.picture_blob_name}`,
      location: `${process.env.REACT_APP_S3_STORAGE_URL}/${DIR_NAME_UPLOAD}/${floorplan.picture_blob_name}`,
    });
  }, [floorplan]);

  const t = Translate('FloorplanPage');

  useEffect(() => {
    (async () => {
      console.log('>>>>>>>>>');
    })();
  }, [floorplan]);

  const editFloorplan = useCallback(() => {
    setIsModalVisible(true);
  }, []);

  const deleteFloorplan = useCallback(async (floorplanDelete: FloorplanType) => {
    setSpin(true);

    try {
      const resultDelete = (await axios.post<{ floorplan_id: number }>(`${API.URL_AURORA_DB}/delete_floorplan`, { ...floorplanDelete })).data;
      if (resultDelete.floorplan_id === floorplanDelete.floorplan_id) {
        setFloorplan(undefined);
        setTimeout(() => {
          setCurrentLocation(listLocation.filter((elem) => elem.location_id === floorplanDelete.location_id)[0]);
        });

        const linkSave = `${process.env.REACT_APP_S3_STORAGE_URL}/${DIR_NAME_UPLOAD}/${floorplanDelete.picture_blob_name}`;
        await deleteFile(linkSave);
        console.log('resultDelete', resultDelete);
      }
    } catch (e) {
      console.log('resultDelete', e);
    }

    setSpin(false);
  }, []);

  const addNewFloorplan = useCallback((locationId: number) => {
    setFloorplan(undefined);

    setTimeout(() => {
      setCurrentLocation(listLocation.filter((elem) => elem.location_id === locationId)[0]);
    });

    setIsModalVisible(true);
  }, [listLocation]);

  const handleOk = () => {
    setIsModalVisible(false);
  };
    //= ====================
  //= ===========transfer

  useEffect(() => {
    const newListFootprintTransfer = listFootprint.map((unitFootprint) => ({
      key: unitFootprint.footprint_id!.toString(),
      title: `${unitFootprint.container_footprint_name}`,
      description: `Footprint ${unitFootprint.customer_name}`,
      disabled: unitFootprint.floorplan_id as any as boolean && !currentListFootprint.filter((elem) => elem.footprint_id === unitFootprint.footprint_id).length as any as boolean,
    }));
    setListTransferFootprint(newListFootprintTransfer);

    const newCurrentListFootprintTransfer = currentListFootprint.map((unitListFootprint) => unitListFootprint.footprint_id!.toString());
    setCurrentListTransferFootprint(newCurrentListFootprintTransfer);
  }, [listFootprint, currentListFootprint]);

  const handleChangeTransfer = (nextTargetKeys: string[], direction: DirectionType, moveKeys: string[]) => {
    setCurrentListTransferFootprint(nextTargetKeys);
    setCurrentListFootprint(listFootprint.filter((elem) => nextTargetKeys.includes(elem.footprint_id!.toString())));

    listFootprint.map((elem) => {
      const newElem = elem;
      if (direction === 'left' && moveKeys.includes(elem.footprint_id!.toString())) {
        newElem.floorplan_id = undefined;
        newElem.pixel_x = undefined;
        newElem.pixel_y = undefined;
      }

      return newElem;
    });
  };

  const filterOption = (inputValue: string, option: TransferType) => option.title.toLowerCase().indexOf(inputValue.toLowerCase()) > -1;

  const handleSearch = (dir: TransferDirection, value: string) => {
    console.log('search:', dir, value);
  };

  const handleSelectChangeTransfer = (sourceSelectedKeys: string[], targetSelectedKeys: any[]) => {
    setSelectedTransferFootprint([...sourceSelectedKeys, ...targetSelectedKeys]);
  };

  const onChangeActivePositionFootprint = useCallback((e: any) => {
    setActivePositionFootprint(e.target.checked);
  }, []);

  const onChangeHighlightFootprint = useCallback((e: any) => {
    setHighlightFootprint(e.target.checked);
  }, [highlightFootprint]);

  //= ====================
  return (
      <>
        <Space direction={'horizontal'} align={'center'} style={{ justifyContent: 'space-between', width: '100%' }}>
          <Title level={3}>{t.k1('Floorplan')}</Title>
        </Space>
        <Spin spinning={spin} tip={'please wait ...'}>
            <Form
                form={form}
                layout={'vertical'}
                name="addFloorplanItem"
                initialValues={{ floor_number: 1, compass: 0, scale: 1 }}
                autoComplete="nope"
            >
                <Row gutter={16}>
                    <Col className="gutter-row" span={5}>
                        <Form.Item
                            label="Location"
                            name={'location_id'}
                        >
                            <SimpleSelect
                                list={listLocation}
                                setCallback={setCurrentLocation}
                                filedId={'location_id'}
                                filedCaption={'location_name'}
                                placeholder={'Select a location'}
                                width={'100%'}
                                currentValue={currentLocation ? currentLocation.location_id as unknown as string : undefined}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Floorplan"
                            name={'floorplan_id'}
                        >
                            <SimpleSelect
                                list={listFloorplan.filter((elem) => (currentLocation ? elem.location_id === currentLocation.location_id : false))}
                                setCallback={setFloorplan}
                                filedId={'floorplan_id'}
                                filedCaption={['name', 'floor_number']}
                                templateCaption={'# (Fl #)'}
                                placeholder={'Select a floorplan'}
                                width={'100%'}
                                currentValue={floorplan ? floorplan.floorplan_id as unknown as string : undefined}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Footprints"
                            name={'list_footprint'}
                        >
                            <FootprintList listFootprint={currentListFootprint} setFootprint={setFootprint} />
                        </Form.Item>
                        <Form.Item
                            name={'active_position_footprint'}
                        >
                            <Checkbox
                                checked={highlightFootprint}
                                onChange={onChangeHighlightFootprint}
                            >
                                highlight footprints
                            </Checkbox>
                        </Form.Item>
                        <Form.Item
                            name={'active_position_footprint'}
                        >
                            <Checkbox
                                checked={followChosenFootprint}
                                onChange={onChangeActivePositionFootprint}
                            >
                                follow the chosen footprint
                            </Checkbox>
                        </Form.Item>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <Button type="primary"
                                    onClick={() => {
                                      addNewFloorplan(currentLocation ? currentLocation.location_id! : 0);
                                    }}
                                    disabled={!currentLocation}
                                    style={{ width: '31%' }}
                            >Add</Button>
                            <Button type="primary"
                                    onClick={() => {
                                      editFloorplan();
                                    }}
                                    disabled={!floorplan}
                                    style={{ width: '31%' }}
                            >Edit</Button>
                            <Button type="primary"
                                    onClick={() => {
                                      deleteFloorplan(floorplan!);
                                    }}
                                    disabled={!floorplan}
                                    style={{ width: '31%' }}
                            >Delete</Button>
                        </div>
                        <Modal title={`${floorplan ? 'Edit' : 'Add'} Floorplan`}
                               visible={isModalVisible}
                               onOk={handleOk}
                               onCancel={handleOk}
                               getContainer={false}
                               forceRender={true}
                               footer={[
                                   <Button key="back" onClick={closeModal}>
                                       Cancel
                                   </Button>,
                                   <Button key="submit" type="primary" onClick={onClickSave}>
                                       Save
                                   </Button>,
                               ]}
                        >
                            <Row gutter={16}>
                                <Col className="gutter-row" span={12}>
                                    <Form.Item
                                        label="Location"
                                        name={'location_id'}
                                    >
                                        <SimpleSelect
                                            list={listLocation}
                                            setCallback={setCurrentLocation}
                                            filedId={'location_id'}
                                            filedCaption={'location_name'}
                                            placeholder={'Select a location'}
                                            width={'100%'}
                                            currentValue={currentLocation ? currentLocation.location_id as unknown as string : undefined}
                                            disabled={true}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col className="gutter-row" span={12}>
                                </Col>
                            </Row>
                            <div style={{ display: (currentLocation ? 'contents' : 'none') }}>
                                <Row gutter={16}>
                                    <Col className="gutter-row" span={18}>
                                        <Form.Item
                                            label="Name"
                                            name={'name'}
                                        >
                                            <Input placeholder="name ..." autoComplete={'nope'}/>
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" span={6}>
                                        <Form.Item
                                            label="Floor number"
                                            name={'floor_number'}
                                        >
                                            <InputNumber min={1} max={100} style={{ width: '100%' }} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col className="gutter-row" span={12}>
                                        <Form.Item
                                            label="Compass"
                                            name={'compass'}
                                        >
                                            <InputNumber min={0} max={359} style={{ width: '100%' }} />
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" span={12}>
                                        <Form.Item
                                            label="Scale"
                                            name={'scale'}
                                        >
                                            <InputNumber min={0.001} max={10} step="0.001" style={{ width: '100%' }} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col className="gutter-row" span={12}>
                                        <Form.Item
                                            label="Image Floorplan"
                                            name={'srcImg'}
                                        >
                                            <FileUpload
                                                dirName={DIR_NAME_UPLOAD}
                                                setSpin={setSpin}
                                                setDataFile={setCurrentDataUploadFile}/>
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" span={12}>
                                        <Form.Item
                                            label="Pict name"
                                            name={'picture_blob_name'}
                                        >
                                            <Input ref={inputPictureBlobName} placeholder="..." disabled={true} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Form.Item
                                    label="Footprint"
                                    name={'footprint_id'}
                                >
                                    <Transfer
                                        dataSource={listTransferFootprint}
                                        titles={['Source', 'Target']}
                                        targetKeys={currentListTransferFootprint}
                                        selectedKeys={selectedTransferFootprint}
                                        showSearch
                                        listStyle={{
                                          width: 250,
                                          height: 250,
                                        }}
                                        onChange={handleChangeTransfer}
                                        onSelectChange={handleSelectChangeTransfer}
                                        render={(item) => item.title}
                                        onSearch={handleSearch}
                                        filterOption={filterOption}
                                        oneWay
                                    />
                                </Form.Item>
                            </div>
                        </Modal>
                    </Col>
                    <Col className="gutter-row" span={19}>
                        <div style={{
                          display: ((currentLocation && currentDataUploadFile) ? 'block' : 'none'),
                          border: '1px solid #d9d9d9',
                        }}>
                            <div style={{ position: 'absolute', top: '-37px' }}>
                                <Form.Item style={{ margin: 'auto' }}>
                                    {
                                        isMoveMarkers
                                          ? <>
                                                <Button type="primary"
                                                        onClick={onClickSave}
                                                        disabled={keyDisabledSave}
                                                        style={{ left: -1 }}
                                                >Save</Button>
                                                <Button type="primary"
                                                        onClick={onClickCancel}
                                                        disabled={keyDisabledSave}
                                                        style={{ marginLeft: '10px' }}
                                                >Cancel</Button>
                                            </>
                                          : <Button type="primary"
                                                    onClick={onClickMoveMarkers}
                                                    style={{ left: -1 }}
                                            >Move markers</Button>
                                    }
                                </Form.Item>
                            </div>
                            {
                                currentDataUploadFile
                                  ? <FloorplanSvgEditor
                                        linkUrl={currentDataUploadFile.location}
                                        listFootprint={currentListFootprint}
                                        selectedFootprint={footprint}
                                        setKeyDisabledSave={setKeyDisabledSave}
                                        followChosenFootprint={followChosenFootprint}
                                        highlightFootprint={highlightFootprint}
                                        isMoveMarkers={isMoveMarkers}
                                        listVisitSchedule={[]}
                                    />
                                  : null
                            }
                        </div>
                    </Col>
                </Row>
            </Form>
        </Spin>
      </>
  );
};

import { configureStore } from '@reduxjs/toolkit';
import customerReducer from './slices/customerSlice';
import locationReducer from './slices/locationSlice';
import regionReducer from './slices/regionSlice';
import scheduleReducer from './slices/scheduleSlice';
import cycleReducer from './slices/cycleSlice';
import operatorReducer from './slices/operatorSlice';
import deviceReducer from './slices/deviceSlice';
import footprintReducer from './slices/footprintSlice';
import warehouseReducer from './slices/warehouseSlice';
import shipReducer from './slices/shipSlice';
import spinnerReducer from './slices/spinnerSlice';
import routeReducer from './slices/routeSlice';
import driverReducer from './slices/driverSlice';

export const store = configureStore({
  reducer: {
    customer: customerReducer,
    location: locationReducer,
    region: regionReducer,
    schedule: scheduleReducer,
    cycle: cycleReducer,
    operator: operatorReducer,
    device: deviceReducer,
    footprint: footprintReducer,
    warehouse: warehouseReducer,
    ship: shipReducer,
    spinner: spinnerReducer,
    route: routeReducer,
    driver: driverReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

import {
  Button, Collapse, DatePicker, Input, Select, Typography,
} from 'antd';
import { DateTime } from 'luxon';
import React, { FC } from 'react';
import { IExceptionPeriod, IExceptionPeriodOptions } from './LocationFormModal';

const { Panel } = Collapse;
const { RangePicker } = DatePicker;
const { Option } = Select;
const { Text } = Typography;

interface ILocationExceptionPeriod {
  listExceptionPeriod: IExceptionPeriod[],
  exceptionsPeriodOptions: IExceptionPeriodOptions[],
  handleExceptionPeriod: (value: any) => void,
  handleExceptionPeriodForecast: (value: any) => void,
  handleExceptionPeriodNotes: (value: any) => void,
  handleAddExceptionPeriod: (value: any) => void
}

export const LocationExceptionPeriod: FC<ILocationExceptionPeriod> = (
  {
    handleExceptionPeriod,
    handleExceptionPeriodForecast,
    exceptionsPeriodOptions,
    handleExceptionPeriodNotes,
    listExceptionPeriod,
    handleAddExceptionPeriod,
  },
) => {
  console.log('listExceptionPeriod', listExceptionPeriod);
  return (
    <Collapse defaultActiveKey={['1']}>
      <Panel header="Exception period" key="1">
        <div className="location-exception__box">
          <Text>Period</Text> <RangePicker onChange={handleExceptionPeriod}/>
        </div>
        <div className="location-exception__box">
          <Text>Forecast</Text>
          <Select
            allowClear
            onChange={handleExceptionPeriodForecast}
          >
            {exceptionsPeriodOptions.map((period) => (
              <Option
                key={period.value}
                value={period.value}
              >
                {period.label}
              </Option>
            ))}
          </Select>
        </div>
        <div className="location-exception__box">
          <Text>Notes</Text> <Input onChange={handleExceptionPeriodNotes}/>
        </div>
        <div className="location-exception__box">
          <Button onClick={handleAddExceptionPeriod}>Add</Button>
        </div>
        {!!listExceptionPeriod?.length && (
          <div>
            {listExceptionPeriod.map((period: any, index: number) => {
              const periodFromOption = exceptionsPeriodOptions.find((periodOption) => periodOption.value === period.exceptionPeriodForecast);
              const dateForm = typeof period.exceptionPeriod[0] !== 'string' ? DateTime.fromJSDate(period.exceptionPeriod[0].toDate()).toFormat('y-LL-dd') : DateTime.fromISO(period.exceptionPeriod[0]).toFormat('y-LL-dd');
              const dateTo = typeof period.exceptionPeriod[1] !== 'string' ? DateTime.fromJSDate(period.exceptionPeriod[1].toDate()).toFormat('y-LL-dd') : DateTime.fromISO(period.exceptionPeriod[1]).toFormat('y-LL-dd');

              return (
                <div key={period.exceptionPeriodId} className="location-exception__list">
                  <Text>{index + 1}. {periodFromOption?.label} {dateForm} - {dateTo}</Text>
                  <Text>{period.exceptionPeriodNotes}</Text>
                </div>
              );
            })}
          </div>
        )}
      </Panel>
    </Collapse>
  );
};

import * as React from 'react';
import styled from 'styled-components';

type LogoTypeType = 'full' | 'short';

type LogoStyleType = {
  type?: LogoTypeType
};
type LogoProps = {
  type?: LogoTypeType,
  margin?: string
};

const LogoStyle = styled.a<LogoStyleType>`
  display: block;
  background: transparent url(${(props) => (props.type === 'full' ? '/cantaloupe-logo.svg' : '/cantaloupe-logo-short.svg')}) no-repeat 0 0;
  height: 64px;
  text-indent: -9999px;
  margin: 0 auto;
  width: ${(props) => (props.type === 'full' ? '288px' : '64px')};
`;

export const Logo: React.FC<LogoProps> = (props) => (
  <div style={{ margin: props.margin, textAlign: 'center' }}>
    <LogoStyle href={'/'} type={props.type}>Cantaloupe, Inc.</LogoStyle>
  </div>
);

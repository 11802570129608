import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { ERequestRoute, ERequestUrl } from '../config';
import { DeviceType } from '../../../../common/entityTypes';

export interface IDeviceState{
  deviceList: DeviceType[];
  deviceListByOperator: DeviceType[];
  deviceIssueMessage: {
    message: string
    error: boolean
  };
}

const initialState: IDeviceState = {
  deviceList: [],
  deviceListByOperator: [],
  deviceIssueMessage: {
    message: '',
    error: false,
  },
};

export const fetchDeviceListByOperatorWithFootprints = createAsyncThunk('DeviceListByOperatorWithFootprints/fetch', async (operatorId: number) => {
  const response = await axios.get<DeviceType[]>(`${ERequestUrl.BASE}/${ERequestRoute.DEVICE_FOOTPRINT}?operator_id=${operatorId}`);
  return response.data;
});

export const fetchDeviceListByOperator = createAsyncThunk('deviceListByOperator/fetch', async (operatorId: number) => {
  const response = await axios.get<DeviceType[]>(`${ERequestUrl.BASE_CRUD}/${ERequestRoute.DEVICE}?eager=true&operator_id=${operatorId}`);
  return response.data;
});

export const moveDevicesBetweenOperators = createAsyncThunk('moveDevicesBetweenOperators/post', async (payload: { devicesId: number[], operatorFrom: number, operatorTo: number, warehouseId: number, effectiveDate: string, comment: string, brackets: any }, { rejectWithValue }) => {
  const response = await axios.post(`${ERequestUrl.BASE}/${ERequestRoute.SHIP_DEVICE}`, JSON.stringify(payload));
  rejectWithValue(response);
  return response.data;
});

export const moveDevicesBetweenFootprints = createAsyncThunk('moveDevicesBetweenFootprints/post', async (payload: { device_id: number, footprint_id?: number, effective_datetime: string, warehouse_id: number }, { rejectWithValue }) => {
  try {
    const response = await axios.post(`${ERequestUrl.BASE}/${ERequestRoute.MOVE_DEVICE}`, JSON.stringify(payload));
    return response.data;
  } catch (e: any) {
    return rejectWithValue(e.response);
  }
});

export const deviceSlice = createSlice({
  name: 'device',
  initialState,
  reducers: {
    clearDeviceIssueMessage: (state) => {
      state.deviceIssueMessage = {
        message: '',
        error: false,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDeviceListByOperatorWithFootprints.fulfilled, (state, action: PayloadAction<DeviceType[]>) => {
      state.deviceList = action.payload.map((device) => ({ ...device, key: device.device_id }));
    });
    builder.addCase(fetchDeviceListByOperator.fulfilled, (state, action: PayloadAction<DeviceType[]>) => {
      state.deviceListByOperator = action.payload.map((device) => ({ ...device, key: device.device_id }));
    });
    builder.addCase(moveDevicesBetweenOperators.fulfilled, (state) => {
      state.deviceIssueMessage = {
        message: 'Devices shipped successfully',
        error: false,
      };
    });
    builder.addCase(moveDevicesBetweenOperators.rejected, (state) => {
      state.deviceIssueMessage = {
        message: 'Ship device fail, please check your inputs',
        error: true,
      };
    });
    builder.addCase(moveDevicesBetweenFootprints.rejected, (state, error: any) => {
      state.deviceIssueMessage = {
        message: error.payload.data,
        error: true,
      };
    });
  },
});

// Action creators are generated for each case reducer function
export const { clearDeviceIssueMessage } = deviceSlice.actions;

export default deviceSlice.reducer;

import { ColumnsType } from 'antd/es/table';
import { DateTime } from 'luxon';
import {
  DeviceType, OperatorType, ShipType, WarehouseType,
} from '../../../../../common/entityTypes';
import { DEFAULT_VALUE } from '../../../consts';

export const SHIP_DEVICE_MESSAGE = {
  IS_ASSIGNED: 'Is assigned to a footprint',
  IS_NOT_FROM_OPERATOR: 'Is not in From Operator',
  SELECT_OPERATOR_TO: 'Select operator to',
  SELECT_OPERATOR_FROM: 'Select operator from',
  RESOLVE_ISSUES_BEFORE_MOVE: 'Resolve issues before move',
  SELECT_DIFFERENT_OPERATOR: 'Select different operator',
  SELECT_DIFFERENT_WAREHOUSE: 'Select different warehouse',
  NO_ISSUES: 'No issues',
  PASTE_DEVICE: 'Paste device',
  SELECT_WAREHOUSE: 'Select warehouse',
  WRONG_WAREHOUSES: 'Warehouse from and device"s warehouse not equal',
};

export const columns: ColumnsType<DeviceType> = [
  {
    title: 'Device number',
    dataIndex: 'device_number',
  },
  {
    title: 'Issues',
    dataIndex: 'issue',
    defaultFilteredValue: [SHIP_DEVICE_MESSAGE.IS_ASSIGNED, SHIP_DEVICE_MESSAGE.IS_NOT_FROM_OPERATOR],
    onFilter: (value, record) => !!record.isIssue,
  },
];

export const shipColumns = (operatorList: OperatorType[], warehouseList: WarehouseType[]): ColumnsType<ShipType> => [
  {
    title: 'Ship date',
    dataIndex: 'effective_datetime',
    render: (value) => {
      if (value) {
        const local = DateTime.fromISO(value);
        return `${local.toFormat('f')} ${local.toFormat('ZZZZ')}`;
      }

      return '';
    },
  },
  {
    title: 'Added date',
    dataIndex: 'added_datetime',
    render: (value) => {
      if (value) {
        const local = DateTime.fromISO(value);
        return `${local.toFormat('f')} ${local.toFormat('ZZZZ')}`;
      }

      return '';
    },
  },
  {
    title: 'Operator from',
    dataIndex: 'operator_id_from',
    render: (value) => operatorList.find((operator) => operator.operator_id === value)?.operator_name || DEFAULT_VALUE,
  },
  {
    title: 'Operator to',
    dataIndex: 'operator_id_to',
    render: (value) => operatorList.find((operator) => operator.operator_id === value)?.operator_name || DEFAULT_VALUE,
  },
  {
    title: 'warehouse from',
    dataIndex: 'warehouse_id_from',
    render: (value) => warehouseList.find((warehouse) => warehouse.warehouse_id === value)?.warehouse_name || DEFAULT_VALUE,
  },
  {
    title: 'warehouse to',
    dataIndex: 'warehouse_id_to',
    render: (value) => warehouseList.find((warehouse) => warehouse.warehouse_id === value)?.warehouse_name || DEFAULT_VALUE,
  },
  {
    title: 'Comment',
    dataIndex: 'comment',
  },
];

import { Draggable } from 'react-beautiful-dnd';
import React from 'react';
import { LocationItem } from './locationItem';

export const LocationList = (props: any) => {
  const {
    locations, setSelectedFootprints, setIsFootorintChanged, setNewLocationsOrder,
  } = props;

  const handleChangeFootprintDoService = (footprint: any, doService: boolean) => {
    const changedLocations = locations.map((location: any) => {
      if (location.location_id === footprint.location_id) {
        return {
          ...location,
          footprints: location.footprints.map((item: any) => {
            if (item.footprint_id === footprint.footprint_id) {
              return {
                ...item,
                raw_data_visit: {
                  ...item.raw_data_visit,
                  doService,
                },
              };
            }

            return item;
          }),
        };
      }

      return location;
    });

    setNewLocationsOrder(changedLocations);
    setIsFootorintChanged(true);
  };

  return (
    locations.map((location: any, index: number) => (
      <Draggable key={location.location_id} draggableId={location.location_id.toString()} index={index}>
        {(provided) => (
          <div
            className="draggable__item"
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <LocationItem
              setSelectedFootprints={setSelectedFootprints}
              handleChangeFootprintDoService={handleChangeFootprintDoService}
              location={location}
            />
          </div>
        )}
      </Draggable>
    ))
  );
};

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { ERequestRoute, ERequestUrl } from '../config';
import { WarehouseHistoryType, WarehouseType } from '../../../../common/entityTypes';

export interface IWarehouseState{
  warehouseList: WarehouseType[];
  warehouse: WarehouseType[],
  warehouseHistoryList: WarehouseHistoryType[];
}

const initialState: IWarehouseState = {
  warehouseList: [],
  warehouse: [],
  warehouseHistoryList: [],
};

export const fetchWarehouses = createAsyncThunk('warehouseList/fetch', async () => {
  const response = await axios.get<WarehouseType[]>(`${ERequestUrl.BASE_CRUD}/${ERequestRoute.WAREHOUSE}`);
  return response.data;
});

export const fetchWarehouseById = createAsyncThunk('warehouse/fetch', async (warehouseIds: string) => {
  const response = await axios.get<WarehouseType[]>(`${ERequestUrl.BASE_CRUD}/${ERequestRoute.WAREHOUSE}?${warehouseIds}`);
  return response.data;
});

export const fetchWarehouseHistoryByDevice = createAsyncThunk('warehouseHistoryList/fetch', async (devicesIdString: string) => {
  const response = await axios.get<WarehouseHistoryType[]>(`${ERequestUrl.BASE_CRUD}/${ERequestRoute.WAREHOUSE_HISTORY}?${devicesIdString}`);
  return response.data;
});

export const addWarehouse = createAsyncThunk('warehouse/add', async (payload: WarehouseType) => {
  const response = await axios.post(`${ERequestUrl.BASE_CRUD}/${ERequestRoute.WAREHOUSE}`, JSON.stringify(payload));
  return response.data;
});

export const warehouseSlice = createSlice({
  name: 'warehouse',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchWarehouses.fulfilled, (state, action: PayloadAction<WarehouseType[]>) => {
      state.warehouseList = action.payload;
    });
    builder.addCase(fetchWarehouseById.fulfilled, (state, action: PayloadAction<WarehouseType[]>) => {
      state.warehouse = action.payload;
    });
    builder.addCase(fetchWarehouseHistoryByDevice.fulfilled, (state, action: PayloadAction<WarehouseHistoryType[]>) => {
      state.warehouseHistoryList = action.payload;
    });
  },
});

// Action creators are generated for each case reducer function
// export const {} = counterSlice.actions;

export default warehouseSlice.reducer;

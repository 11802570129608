import React, {
  useCallback, useEffect, useState,
} from 'react';
import {
  Select,
  Space, Spin,
  Table, Typography,
} from 'antd';
import axios from 'axios';
import { Translate } from 'i18n-next-tools';
import { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { DateTime } from 'luxon';
import ReactJson from 'react-json-view';
import { API } from '../constsAPI';
import {
  DeviceReportType, DeviceType,
} from '../../../common/entityTypes';
import { DataShadowUpdateType, DropDownType } from '../../../common/types';
import { DEFAULT_VALUE, PAGINATION_TABLE } from '../consts';
import { Signal } from '../components/Signal';
import { handlePageSize } from '../common';
import { Battery } from '../components/Battery';

const { Title } = Typography;

const filterOperatorName: DropDownType[] = [];

const listRowsLimitReports = [100, 200, 500, 1000];

const getColumns: ColumnsType<DeviceType & DeviceReportType> = [
  {
    title: 'Device report ID',
    dataIndex: 'device_report_id',
    key: 'device_report_id',
  },
  {
    title: 'Device ID',
    dataIndex: 'device_id',
    key: 'device_id',
  },
  {
    title: 'Report Datetime',
    dataIndex: 'report_datetime',
    key: 'report_datetime',
    defaultSortOrder: 'descend',
    sorter: (a: any, b: any) => {
      const time1 = a.report_datetime ? DateTime.fromISO(a.report_datetime).toMillis() : 0;
      const time2 = b.report_datetime ? DateTime.fromISO(b.report_datetime).toMillis() : 0;
      return (time1 > time2 ? 1 : -1);
    },

    render: (value: string) => (value ? DateTime.fromISO(value).toUTC().toFormat('y-LL-dd hh:mm:ss a') : DEFAULT_VALUE),
  },
  {
    title: 'Received Datetime',
    dataIndex: 'received_datetime',
    key: 'received_datetime',
    sorter: (a: any, b: any) => {
      const time1 = a.received_datetime ? DateTime.fromISO(a.received_datetime).toMillis() : 0;
      const time2 = b.received_datetime ? DateTime.fromISO(b.received_datetime).toMillis() : 0;
      return (time1 > time2 ? 1 : -1);
    },
    render: (value: string) => (value ? DateTime.fromISO(value).toFormat('y-LL-dd hh:mm:ss a') : DEFAULT_VALUE),
  },
  {
    title: 'Depth',
    dataIndex: 'depth',
    key: 'depth',
  },
  {
    title: 'Battery',
    dataIndex: 'battery',
    key: 'battery',
    render: (value: number) => (value ? <Battery value={value}/> : DEFAULT_VALUE),
  },
  {
    title: 'Signal',
    dataIndex: 'signal_rsrp',
    key: 'signal_rsrp',
    width: 70,
    render: (value: number) => (value ? <Signal rssi={value}/> : DEFAULT_VALUE),
  },
  {
    title: 'Service',
    dataIndex: 'service_button',
    key: 'service_button',
    width: 100,
    render: (value: boolean) => (value ? 'serviced' : ''),
  },
  {
    title: 'Extra Data',
    dataIndex: 'extra_data',
    key: 'extra_data',
    width: 350,
    render: (value: DataShadowUpdateType) => <ReactJson
      collapsed={1}
      indentWidth={2}
      src={value}
    /> || DEFAULT_VALUE,
  },
  {
    title: 'APPV',
    dataIndex: 'appv',
    key: 'appv',
  },
  {
    title: 'modV',
    dataIndex: 'modv',
    key: 'modv',
  },
  {
    title: 'Device number',
    dataIndex: 'device_number',
    key: 'device_number',
  },
  {
    title: 'Device serial number',
    dataIndex: 'device_serial_number',
    key: 'device_serial_number',
  },
  {
    title: 'ICCID',
    dataIndex: 'iccid',
    key: 'iccid',
  },
];

export const ReportListPage: React.FC = () => {
  const [loadingTable] = useState<boolean>(false);
  const [paginationTable, setPaginationTable] = useState<TablePaginationConfig>({
    ...PAGINATION_TABLE,
    pageSize: handlePageSize({ place: 'reports' }),
  });
  const [currentRowLimitReports, setCurrentRowLimitReports] = useState<number>(listRowsLimitReports[0]);

  const columns: ColumnsType<DeviceType & DeviceReportType> = getColumns;

  const [spin, setSpin] = useState(false);
  const [dataTable, setDataTable] = useState<(DeviceType & DeviceReportType)[]>([]);

  const getDataDB = useCallback(async () => {
    const dataDB = (await axios.get<(DeviceType & DeviceReportType)[]>(`${API.URL_AURORA_DB}/report_list?rowlimit=${currentRowLimitReports}`)).data;
    setDataTable((dataDB).map((elem) => {
      if (!filterOperatorName.filter((name) => name.value === elem.operator_name).length) {
        filterOperatorName.push({ text: elem.operator_name!, value: elem.operator_name! });
      }

      const modifyElem = elem;
      modifyElem.key = elem.device_report_id;
      return modifyElem;
    }));
    setSpin(false);
  }, [currentRowLimitReports]);

  useEffect(() => {
    (async () => {
      setSpin(true);
      await getDataDB();
    })();
  }, [currentRowLimitReports]);

  const handleTableChange = useCallback((pagination) => {
    handlePageSize({ place: 'reports', pageSize: pagination.pageSize });
    setPaginationTable(pagination);
  }, []);

  const changeCurrentCounterReports = useCallback((value: string) => {
    setCurrentRowLimitReports(Number(value));
  }, []);

  const t = Translate('DeviceListPage');

  return (
    <>
      <Space direction={'horizontal'} align={'center'} style={{ justifyContent: 'space-between', width: '100%' }}>
        <Title level={3}>{t.k1('Reports')}</Title>
        <Select
          defaultValue={`${currentRowLimitReports} reports`}
          style={{ width: 120 }}
          onChange={changeCurrentCounterReports}
          options={listRowsLimitReports.map((count) => ({
            value: count,
            label: `${count} reports`,
          }))}
        />
      </Space>
      <Spin spinning={spin} tip={'please wait ...'}>
        <Table<DeviceType & DeviceReportType>
          columns={columns}
          className={'click_row'}
          dataSource={dataTable}
          pagination={paginationTable}
          onChange={handleTableChange}
          loading={loadingTable}
          scroll={{ x: 2300 }}
        />
      </Spin>
    </>
  );
};

import { Button, Result } from 'antd';
import React from 'react';

export const NotFoundPage = () => {
  console.log('not found');
  return (
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
      extra={<Button type="primary">Back Home</Button>}
    />
  );
};

import * as React from 'react';
import { Redirect, Route, useHistory } from 'react-router';
import { useEffect } from 'react';
import {
  customerRoleAccessPages,
  operatorRoleAccessPages,
  publicPages,
  roles,
  decodeToken,
  isTokenValid,
  paths, manageUserPermissionAccessPages,
} from '../common';

type RouteAuthProps = {
  path: string,
};

export const RouteAuth: React.FC<RouteAuthProps> = (props) => {
  const history = useHistory();

  useEffect(() => {
    (async () => {
      if (!await isTokenValid()) {
        history.push('/login');
      }
    })();
  }, []);

  const checkPageAccessByRole = (path: string, accessPages: { [key: string]: string }) => Object.values(accessPages).find((value) => path.includes(value));

  const checkPageAccessByPermission = (path: string, accessPages: { [key: string]: string }) => Object.values(accessPages).find((value) => path.includes(value));

  const checkPageAccess = () => {
    if (Object.values(publicPages).find((value) => value === props.path)) {
      return true;
    }
    const token = localStorage.getItem('IdToken') || '';
    const decodedToken = decodeToken(token);

    if (decodedToken['custom:Role'] === roles.OPERATOR) {
      return checkPageAccessByRole(props.path, operatorRoleAccessPages)
          || checkPageAccessByPermission(props.path, manageUserPermissionAccessPages);
    }

    if (decodedToken['custom:Role'] === roles.CUSTOMER) {
      return checkPageAccessByRole(props.path, customerRoleAccessPages)
          || checkPageAccessByPermission(props.path, manageUserPermissionAccessPages);
    }

    return decodedToken['custom:Role'] === roles.ADMIN;
  };

  if (checkPageAccess()) {
    return (
      <Route path={props.path}>
        {props.children}
      </Route>
    );
  }

  return <Redirect to={paths.MAIN}/>;
};

import {
  Button, Form, Input, Select,
} from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addCustomer } from '../../../store/slices/customerSlice';
import { AppDispatch, RootState } from '../../../store';
import { RegionType } from '../../../../../common/entityTypes';
import { fetchRegions } from '../../../store/slices/regionSlice';

const { Option } = Select;

export const AddCustomer = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch<AppDispatch>();
  const regionList = useSelector<RootState, RegionType[]>((store) => store.region.regionList);

  useEffect(() => {
    dispatch(fetchRegions());
  }, []);
  const onFinish = (values: any) => {
    dispatch(addCustomer({
      ...values,
      deleted: false,
    }));
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Form
      name="addCustomer"
      labelCol={{ span: 5 }}
      labelAlign={'left'}
      wrapperCol={{ span: 8 }}
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Form.Item
        label="Region"
        name="region_id"
        rules={[{ required: true, message: 'Please input region!' }]}
      >
        <Select
          allowClear
          placeholder={'Select region'}
          value={form.getFieldValue('region_id')}
        >
          {regionList.map((region) => (
            <Option
              key={region.region_id}
              value={region.region_id!}
            >
              {region.region_name}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label="Name"
        name="customer_name"
        rules={[{ required: true, message: 'Please input customer name!' }]}
      >
        <Input/>
      </Form.Item>
      <Form.Item
        label="Address 1"
        name="address_1"
        rules={[{ required: true, message: 'Please input customer Address 1!' }]}
      >
        <Input/>
      </Form.Item>
      <Form.Item
        label="Address 2"
        name="address_2"
      >
        <Input/>
      </Form.Item>
      <Form.Item
        label="City"
        name="city"
        rules={[{ required: true, message: 'Please input customer city!' }]}
      >
        <Input/>
      </Form.Item>
      <Form.Item
        label="State Province"
        name="state_province"
        rules={[{ required: true, message: 'Please input customer State Province!' }]}
      >
        <Input/>
      </Form.Item>
      <Form.Item
        label="Zip"
        name="Zip"
        rules={[{ required: true, message: 'Please input customer Zip!' }]}
      >
        <Input/>
      </Form.Item>
      <Form.Item
        label="Country"
        name="country"
        rules={[{ required: true, message: 'Please input customer Country!' }]}
      >
        <Input/>
      </Form.Item>
      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

import * as React from 'react';
import {
  Dropdown, Menu,
} from 'antd';
import { useCallback, useState } from 'react';
import FlagIconFactory from 'react-flag-icon-css';
import { useDataCommon } from '../DataCommonProvider';

const FlagIcon = FlagIconFactory(React, { useCssModules: false });

const languages = ['ua', 'en', 'fr'];

export const LanguageDropdownMenu: React.FC = () => {
  const dataCommon = useDataCommon();
  const [language, setLanguage] = useState(dataCommon.language);

  const onClick = useCallback((e: any) => {
    dataCommon.setLanguage!(e.key);
    setLanguage(e.key);
  }, []);

  const menu = useCallback(() => (
      <Menu>
          {
              languages.map((elem) => <Menu.Item key={elem} icon={<FlagIcon code={elem === 'en' ? 'us' : elem}/>} onClick={onClick}>
                      <span>{elem.toUpperCase()}</span>
                  </Menu.Item>)
          }
      </Menu>
  ), []);

  return (
      <Dropdown overlay={menu} trigger={['click']}>
        <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
            <FlagIcon code={language === 'en' ? 'us' : language}/><span style={{ margin: '0 20px 0 10px' }}>{language.toUpperCase()}</span>
        </a>
      </Dropdown>
  );
};

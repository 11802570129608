import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { ERequestRoute, ERequestUrl } from '../config';
import { CycleType } from '../../../../common/entityTypes';

export interface ICycleState {
  cycleList: CycleType[]
}

const initialState: ICycleState = {
  cycleList: [],
};

export const fetchCycle = createAsyncThunk('cycleList/fetch', async () => {
  const response = await axios.get<CycleType[]>(`${ERequestUrl.BASE_CRUD}/${ERequestRoute.CYCLE}`);
  return response.data;
});

export const cycleSlice = createSlice({
  name: 'cycle',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCycle.fulfilled, (state, action: PayloadAction<CycleType[]>) => {
      state.cycleList = action.payload;
    });
  },
});

// Action creators are generated for each case reducer function
// export const {} = counterSlice.actions;

export default cycleSlice.reducer;

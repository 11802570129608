import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { ERequestRoute, ERequestUrl } from '../config';
import { CustomerType } from '../../../../common/entityTypes';

const initialCustomer: CustomerType = {
  customer_id: 0,
  region_id: 0,
  deleted: false,
  partner_customer_id: '',
  customer_name: '',
  address1: '',
  address2: '',
  city: '',
  state_province: '',
  zip: '',
  country: '',
};

export interface ICustomerState {
  customerList: CustomerType[],
  customer: CustomerType,
  customerLoader: boolean
}

const initialState: ICustomerState = {
  customerList: [],
  customer: initialCustomer,
  customerLoader: false,
};

export const fetchCustomersByRegions = createAsyncThunk('customerList/fetch', async (regionsIds: string) => {
  const response = await axios.get(`${ERequestUrl.BASE_CRUD}/${ERequestRoute.CUSTOMER}?${regionsIds}`);
  return response.data;
});

export const fetchCustomersByIds = createAsyncThunk('customerListByIds/fetch', async (customersIds: string) => {
  const response = await axios.get(`${ERequestUrl.BASE_CRUD}/${ERequestRoute.CUSTOMER}?${customersIds}`);
  return response.data;
});

export const fetchCustomer = createAsyncThunk('customer/fetch', async (id: string) => {
  const response = await axios.get(`${ERequestUrl.BASE_CRUD}/${ERequestRoute.CUSTOMER}/${id}`);
  return response.data;
});

export const addCustomer = createAsyncThunk('customer/add', async (values: CustomerType) => {
  const response = await axios.post(`${ERequestUrl.BASE_CRUD}/${ERequestRoute.CUSTOMER}`, JSON.stringify(values));
  return response.data;
});

export const customerSlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCustomersByRegions.pending, (state) => {
      state.customerLoader = true;
    });
    builder.addCase(fetchCustomersByRegions.fulfilled, (state, action: PayloadAction<CustomerType[]>) => {
      state.customerList = action.payload;
      state.customerLoader = false;
    });
    builder.addCase(fetchCustomersByIds.fulfilled, (state, action: PayloadAction<CustomerType[]>) => {
      state.customerList = action.payload;
    });
    builder.addCase(fetchCustomer.fulfilled, (state, action: PayloadAction<CustomerType>) => {
      state.customer = action.payload;
    });
  },
});

// Action creators are generated for each case reducer function
// export const {} = counterSlice.actions;

export default customerSlice.reducer;
